import createStoreAdapter from "../../../store/createStoreAdapter"
import { AssetClassModel } from "../models/AssetClassModel"

const STORE_NAME = 'assetClasses'

const adapter = createStoreAdapter<AssetClassModel>({
  classType: AssetClassModel,
  storeName: STORE_NAME,
  apiParams: { name: 'assetsApi', 'baseUrl': '/asset-classes' },
  selectIdFn: (entity: AssetClassModel) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const AssetClassesReducer = adapter.reducer
export const AssetClassesSelectors = adapter.selectors
export const AssetClassesThunks = adapter.thunks
export const AssetClassesActions = adapter.actions

export default adapter
