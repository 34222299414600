import { EmailConfirmationSchema } from '@loadsys-cmms/app-sdk'
import { Auth } from 'aws-amplify'
import React from 'react'
import { useAppPubSub } from '../hooks'
import { useSnackbarMessages } from '../hooks/useSnackbarMessages'
import { EmailConfirmationForm } from './EmailConfirmationForm'

interface AmplifyEmailConfirmationComponentProps {
  onConfirm?: (data: EmailConfirmationSchema) => void
}

export const AmplifyEmailConfirmationComponent: React.FC<
  AmplifyEmailConfirmationComponentProps
> = ({ onConfirm = () => {} }) => {
  const appPubSub = useAppPubSub()
  const messages = useSnackbarMessages()

  const handleOnSubmit = React.useCallback((data: EmailConfirmationSchema) => {
    if (!data) {
      return
    }
    if (data?.validate()) {
      console.log('logging in')
      Auth.confirmSignUp(data.email, data.confirmationCode)
        .then(() => {
          messages.addSuccessMessage('Account confirmed successful.')
          onConfirm(data)
        })
        .catch((e: Error) => {
          console.log('err', e)
          messages.addErrorMessage('Confirmation Failed - ' + e)
        })
    } else {
      console.log('Validation errors', data.getErrors())
    }
  }, [])

  return <EmailConfirmationForm onSubmit={handleOnSubmit} />
}
