import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'

export interface AppMenuItemProps {
  title: string
  icon: ReactNode
  route: string
  matchRoute?: string
}

export const AppMenuItem: React.FC<AppMenuItemProps> = ({
  title,
  icon = null,
  route,
  matchRoute
}) => {
  const navigate = useNavigate()
  const resolvePath = useResolvedPath(matchRoute)
  const matched = useMatch(resolvePath.pathname)

  const isSelected = useMemo(() => {
    return matched?.pathname ? true : false
  }, [matched])

  const handleOnClick = useCallback(() => {
    navigate(route)
  }, [route])

  return (
    <ListItemButton selected={isSelected} onClick={handleOnClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  )
}
