import { EventTriggerFrequency } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo } from 'react'

const OPTIONS: InputOptionProps[] = [
  {
    label: 'Day',
    value: EventTriggerFrequency.Day
  },
  {
    label: 'Month',
    value: EventTriggerFrequency.Month
  },
  {
    label: 'Year',
    value: EventTriggerFrequency.Year
  }
]

export interface AssetClassEventTriggerFrequencyPickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const AssetClassEventTriggerFrequencyPicker: React.FC<
  AssetClassEventTriggerFrequencyPickerProps
> = ({
  label = 'Interval',
  placeholder = 'Interval',
  onChange = () => {},
  ...props
}) => {
  const filteredOptions = useMemo(() => {
    return _.map(OPTIONS)
  }, [OPTIONS])

  const options: InputOptionProps[] = useMemo(() => {
    return OPTIONS
  }, [filteredOptions])

  return (
    <>
      <AutocompleteInput
        options={options}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </>
  )
}
