import { AmplifyEmailConfirmationComponent } from '@loadsys-cmms/ui-components'
import { Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const EmailConfirmationPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Container maxWidth={'xs'}>
      <AmplifyEmailConfirmationComponent onConfirm={() => navigate('/login')} />
    </Container>
  )
}
