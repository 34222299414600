import { Paper, Toolbar, useTheme } from '@mui/material'
import React from 'react'

export const AppToolbar: React.FC = ({ children }) => {
  const theme = useTheme()
  return (
    <Paper sx={{ marginBottom: theme.spacing(1), padding: theme.spacing(1) }}>
      <Toolbar variant='dense'>{children}</Toolbar>
    </Paper>
  )
}
