import React, { useCallback, useEffect } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { EventTypeSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { EventTypeForm } from './forms/EventTypeForm'
import { useNavigate } from 'react-router-dom'
import { useUpdateEventTypeQuery } from '../hooks/useUpdateEventTypeQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { useAppSelector } from '../../../store/useAppSelector'
import { EventTypesStore } from '../store/store-config'

interface EditEventTypeDrawerProps {
  open: boolean
  onClose: (schema?: EventTypeSchema) => void
  defaultData?: Partial<EventTypeSchema>
}

export const EditEventTypeDrawer: React.FC<EditEventTypeDrawerProps> = ({
  open,
  onClose,
  defaultData
}) => {
  const { updateEventType, processing, errorMessage } =
    useUpdateEventTypeQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<EventTypeSchema>()

  const { entityId } = useEntityParams()

  const currentSchema = useAppSelector((state) =>
    EventTypesStore.selectors.selectById(state, entityId)
  )

  useEffect(() => {
    if (open && currentSchema?.id && currentSchema instanceof EventTypeSchema) {
      setSchema(currentSchema.clone())
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateEventType(schema)
        .then((result) => {
          setSchema(new EventTypeSchema(defaultData))
          addSuccessMessage('EventTypeMode created.')
          onClose()
          navigate(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to update the EventType. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    if (entityId) {
      navigate(entityId)
    } else {
      navigate('/event-management/types')
    }
    // onClose()
  }, [entityId])

  const handleOnReset = useCallback(() => {
    setSchema(currentSchema)
  }, [currentSchema])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Edit EventType
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <ContentContainer>
            <EventTypeForm schema={schema} onChange={handleOnSchemaChanged} />
          </ContentContainer>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
