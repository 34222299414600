import { Expose, Type } from "class-transformer";
import { IsDate, IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength, ValidateIf } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../BaseSchema";

export class AssetPropertyValueSchema extends BaseSchema {
  @Expose()
  id: string;

  @Expose()
  @IsOptional()
  assetPropertyId: string;

  @Expose()
  @IsOptional()
  @MaxLength(512)
  alias: string;

  @Expose()
  @IsNotEmpty()
  @ValidateIf(obj => _.isUndefined(obj.numberValue))
  @IsString()
  @Type(() => String)
  stringValue: string;

  @Expose()
  @IsNotEmpty()
  @ValidateIf(obj => _.isEmpty(obj.stringValue))
  @IsNumber()
  @Type(() => Number)
  numberValue: number;

  @Expose()
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  timestamp: Date;

  @Expose()
  @IsOptional()
  @MaxLength(256)
  source: string;

  @Expose()
  @IsOptional()
  @MaxLength(256)
  identityId: string;
}