import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { v4 as uuid } from 'uuid'

import TextField from '@mui/material/TextField'
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason
} from '@mui/material/Autocomplete'
import _ from 'lodash'
import { Box, ListItem, ListItemText, Typography } from '@mui/material'
import { InputOptionProps } from './types'
import { Add } from '@mui/icons-material'
import { EntityAvatar } from '../common/EntityAvatar'

export interface AutocompleteInputProps {
  value?: string
  onChange: (value: string | number) => void
  label?: string
  className?: any
  style?: any
  placeholder?: string
  variant?: string | 'standard' | 'filled' | 'outlined'
  error?: boolean
  helperText?: string
  filter?: Function
  disabled?: boolean
  freeSolo?: boolean
  options: InputOptionProps[]
  required?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  onBlur?: (text: string) => void
  enableCreate?: boolean
  showAvatar?: boolean
  margin?: string | 'dense' | 'normal' | 'none'
}

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  style,
  className,
  value,
  onChange,
  label,
  placeholder = 'Enter Text',
  variant,
  error,
  helperText,
  filter,
  disabled,
  freeSolo,
  options,
  required,
  noOptionsText,
  clearOnBlur,
  onCreateNew,
  enableCreate,
  showAvatar = false,
  onBlur = () => {},
  margin = 'dense'
}) => {
  let selected = null
  if (value) {
    selected = _.find(options, (option) => {
      return option.value === value
    })
  }

  const [text, setText] = useState('')
  const [selectedValue, setSelectedValue] = useState<InputOptionProps>(selected)
  const [highlightedValue, setHighlightedValue] = useState<InputOptionProps>()

  useEffect(() => {
    if (value) {
      const index = _.findIndex(options, (option) => {
        return option.value === value
      })
      if (index >= 0) {
        setSelectedValue(options[index])
      }
    } else {
      setSelectedValue(null)
    }
  }, [options, value])

  const filterOptions = useCallback(() => {
    if (filter) {
      return _.filter(options, (option) => {
        return Boolean(filter(option))
      })
    } else {
      return options
    }
  }, [filter, options])

  const handleOnHighligted = useCallback((value: InputOptionProps) => {
    setHighlightedValue(value)
  }, [])

  const handleOnChange = useCallback(
    (value: string, reason: string) => {
      if (!value && text && onCreateNew && reason !== 'clear') {
        onCreateNew(text)
      }
      onChange(value)
    },
    [onChange, onCreateNew, text]
  )

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (highlightedValue && _.includes(['Tab'], event?.key)) {
        setSelectedValue(highlightedValue)
        handleOnChange(highlightedValue?.value, '')
      }
    },
    [highlightedValue, handleOnChange]
  )

  return (
    <Autocomplete
      clearOnBlur={enableCreate || clearOnBlur}
      noOptionsText={noOptionsText}
      disabled={disabled}
      freeSolo={enableCreate || freeSolo}
      style={style}
      className={className}
      options={filterOptions()}
      value={selectedValue}
      getOptionLabel={(option: InputOptionProps) => option.label}
      onBlur={(_event: React.FocusEvent<HTMLDivElement>) => {
        onBlur(text)
      }}
      renderOption={(props, option: InputOptionProps, _state) => (
        <ListItem {...props} key={option.value}>
          <ListItemText
            primary={
              <Fragment>
                {enableCreate && !option.value && (
                  <Box display={'flex'} alignItems={'center'}>
                    <Add fontSize={'inherit'} color={'primary'} />
                    <Typography color={'primary'}>New Record:</Typography>
                  </Box>
                )}
                <Typography>{option.label}</Typography>
              </Fragment>
            }
            secondary={option.subtitle}
          />
        </ListItem>
      )}
      filterOptions={(options: InputOptionProps[], state: any) => {
        const filtered = _.filter(options, (option) => {
          const name = option.label.toLocaleLowerCase()
          return !(name.indexOf(state.inputValue.toLowerCase()) === -1)
        })
        if (_.isEmpty(filtered) && enableCreate && state.inputValue !== '') {
          setText(state.inputValue)
          const option: InputOptionProps = {
            label: state.inputValue,
            value: null
          }
          filtered.push(option)
        }
        return filtered
      }}
      onChange={(_event, value: any, reason: AutocompleteChangeReason) => {
        setSelectedValue(value || null)
        handleOnChange(value ? value.value : null, reason)
      }}
      onInputChange={(
        _event: React.ChangeEvent<{}>,
        label: string,
        _reason: AutocompleteInputChangeReason
      ) => {
        const selected = _.find(options, (option) => {
          return option.label === label
        })
        if (!selected) {
          setSelectedValue(null)
          onChange(null)
        }
      }}
      onHighlightChange={(_event, option, _reason) => {
        handleOnHighligted(option)
      }}
      onKeyDown={(event) => {
        handleOnKeyDown(event)
      }}
      autoHighlight={true}
      renderInput={(params: any) => {
        return (
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            {showAvatar && <EntityAvatar text={selectedValue?.label} />}
            <TextField
              {...params}
              margin={margin}
              label={label}
              placeholder={placeholder}
              error={Boolean(error)}
              helperText={helperText}
              fullWidth
              variant={variant}
              required={required}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // disable autocomplete and autofill
              }}
            />
          </Box>
        )
      }}
    />
  )
}
