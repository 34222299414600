import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import { MaintenanceActionSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { useNavigate } from 'react-router-dom'
import { useUpdateMaintenanceActionQuery } from '../hooks/useUpdateMaintenanceActionQuery'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { useAppSelector } from '../../../store/useAppSelector'
import { MaintenanceActionsSelectors } from '../store/store-config'
import { MaintenanceActionForm } from './forms/MaintenanceActionForm'

interface EditMaintenanceActionDrawerProps {
  open: boolean
  onClose: (schema?: MaintenanceActionSchema) => void
  defaultData?: Partial<MaintenanceActionSchema>
}

export const EditMaintenanceActionDrawer: React.FC<
  EditMaintenanceActionDrawerProps
> = ({ open, onClose, defaultData }) => {
  const { updateMaintenanceAction, processing, errorMessage } =
    useUpdateMaintenanceActionQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<MaintenanceActionSchema>()

  const { entityId } = useEntityParams()

  const currentSchema = useAppSelector((state) =>
    MaintenanceActionsSelectors.selectById(state, entityId)
  )

  useEffect(() => {
    if (
      open &&
      currentSchema?.id &&
      currentSchema instanceof MaintenanceActionSchema
    ) {
      setSchema(currentSchema.clone())
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateMaintenanceAction(schema)
        .then((result) => {
          setSchema(new MaintenanceActionSchema(defaultData))
          addSuccessMessage('Maintenance Action created.')
          onClose()
          navigate(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to update the Maintenance Action. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/maintenance-actions')
    onClose()
  }, [])

  const handleOnReset = useCallback(() => {
    setSchema(currentSchema)
  }, [currentSchema])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Edit Failure Mode
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <>
            <ContentContainer>
              <MaintenanceActionForm
                schema={schema}
                onChange={handleOnSchemaChanged}
              />
            </ContentContainer>
          </>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
