import { EventTypeSchema } from "@loadsys-cmms/app-sdk"
import createStoreAdapter from "../../../store/createStoreAdapter"

const STORE_NAME = 'eventTypes'

const adapter = createStoreAdapter<EventTypeSchema>({
  classType: EventTypeSchema,
  storeName: STORE_NAME,
  apiParams: { name: 'eventsApi', 'baseUrl': '/event-types' },
  selectIdFn: (entity: EventTypeSchema) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const EventTypesStore = {
  reducer: adapter.reducer,
  selectors: adapter.selectors,
  thunks: adapter.thunks,
  actions: adapter.actions,
}

export default adapter
