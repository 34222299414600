import { PubSub } from "aws-amplify"
import { useCallback } from "react"
import { usePubsubTopicBuilder } from "./usePubsubTopicBuilder"

export const usePubsubPublish = () => {
  const { buildTopic } = usePubsubTopicBuilder()


  const publish = useCallback((topic: string, msg: any) => {
    const prefixedTopic = buildTopic(topic)
    return PubSub.publish(prefixedTopic, msg)
  }, [buildTopic])

  return {
    publish
  }
}