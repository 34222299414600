import { Type, Expose } from 'class-transformer'

export class ApiResponseMetadata {
  @Expose()
  @Type(() => Number)
  itemCount: number

  @Expose()
  @Type(() => Number)
  pageCount: number

  @Expose()
  @Type(() => Number)
  pageItemCount: number

  @Expose()
  @Type(() => Number)
  pageItemLimit: number

  @Expose()
  @Type(() => Number)
  pageNumber: number
}