import React from 'react'
import { Container } from '@mui/material'
import { AppTabProps, AppTabs } from '@loadsys-cmms/ui-components'

interface AppTabsContainerProps {
  tabs: AppTabProps[]
  selected?: string
}

export const AppTabsContainer: React.FC<AppTabsContainerProps> = ({
  tabs,
  selected,
  children
}) => {
  return (
    <Container maxWidth={false}>
      <AppTabs tabs={tabs} selected={selected}>
        {children}
      </AppTabs>
    </Container>
  )
}
