// import { ExpressionVariable } from "@aws-sdk/client-iotsitewise";
import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsString, ValidateNested } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../../BaseSchema";
import { VariableValueSchema } from "./VariableValueSchema";

export class ExpressionVariableSchema extends BaseSchema<ExpressionVariableSchema> /* implements ExpressionVariable */ {
  constructor(data?: Partial<ExpressionVariableSchema>) {
    super(data)
    if (data) {
      _.assign(this, data)
    }
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  name: string;

  @Expose()
  @Type(() => VariableValueSchema)
  @IsNotEmpty()
  @ValidateNested()
  value: VariableValueSchema;
}