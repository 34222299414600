import { IsString, IsNotEmpty, IsOptional, IsObject } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import _ from 'lodash';

export class AssetFailureModeCauseSchema extends BaseSchema {
  @Expose()
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsOptional()
  @IsString()
  assetFailureModeId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  assetFailureCauseId: string;

  @Expose()
  @IsOptional()
  @IsString()
  localEffect: string;

  @Expose()
  @IsOptional()
  @IsString()
  systemEffect: string;

  @Expose()
  @IsOptional()
  @IsObject()
  @Type(() => Object)
  correctiveActions: object;

  @Expose()
  @IsOptional()
  @IsObject()
  @Type(() => Object)
  preventiveActions: object;
}
