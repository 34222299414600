//import { ComputeLocation } from "@aws-sdk/client-iotsitewise";
import { Expose } from "class-transformer";
import { IsEnum } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../../BaseSchema";
import { Assets } from '../types'

export class MetricProcessingConfigSchema extends BaseSchema<MetricProcessingConfigSchema> {
  /**
   * Initialize
   *
   * @param data
   */
  constructor(data?: MetricProcessingConfigSchema) {
    super()

    this.computeLocation = Assets.ComputeLocation.CLOUD

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsEnum(Assets.ComputeLocation)
  computeLocation: Assets.ComputeLocation;
}