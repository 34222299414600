import React, { useCallback } from 'react'
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  AppTabProps,
  AppTabs,
  AppTabPanel,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetClassesSelectors } from '../store/store-config'
import { useEntityParams } from '../../../hooks/useEntityParams'
/*import { SystemAssetModelAttributes } from '../../system-asset-models/components/SystemAssetModelAttributes'
import { SystemAssetModelMeasurements } from '../../system-asset-models/components/SystemAssetModelMeasurements'
import { SystemAssetModelTransforms } from '../../system-asset-models/components/SystemAssetModelTransforms'
import { SystemAssetModelMetrics } from '../../system-asset-models/components/SystemAssetModelMetrics'*/
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetClassHierarchies } from './AssetClassHierarchies'
import { AWSAssetModelAttributes } from '../../aws-asset-models/components/AWSAssetModelAttributes'
import { AWSAssetModelMeasurements } from '../../aws-asset-models/components/AWSAssetModelMeasurements'
import { AWSAssetModelTransforms } from '../../aws-asset-models/components/AWSAssetModelTransforms'
import { AWSAssetModelMetrics } from '../../aws-asset-models/components/AWSAssetModelMetrics'
import { AssetClassMetrics } from './AssetClassMetrics'
import { AssetClassAttributes } from './AssetClassAttributes'
import { AssetClassEventTriggers } from '../../asset-class-event-triggers/components/AssetClassEventTriggers'
import { AssetClassTransforms } from './AssetClassTransforms'
import { AssetClassFailureModes } from '../../asset-class-failure-modes/components/AssetClassFailureModes'

const tabs: AppTabProps[] = [
  { value: 'details', label: 'Details' },
  { value: 'attributes', label: 'Attributes' },
  //{ value: 'measurements', label: 'Measurements' },
  { value: 'transforms', label: 'Transforms' },
  { value: 'metrics', label: 'Metrics' },
  { value: 'fmea', label: 'FMEA' },
  { value: 'events', label: 'Events' }
  // { value: 'items', route: `${routePrefix}/items`, label: 'Shipment Items' },
]

interface ViewAssetClassDrawerProps {
  open: boolean
  onClose: () => void
}

export const ViewAssetClassDrawer: React.FC<ViewAssetClassDrawerProps> = ({
  open,
  onClose
}) => {
  const { entityId } = useEntityParams()

  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()

  const schema = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, entityId)
  )

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/classes')
    onClose()
  }, [])

  return (
    <AppDrawer
      minWidth={400}
      width={'60vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          {schema?.name}
        </Typography>
        <Button onClick={() => navigate(`${entityId}/edit`)}>Edit</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <>
            <AppTabs tabs={tabs} selected={'details'} hidden={true}>
              <AppTabPanel value={'details'}>
                <ContentContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant={'body2'} fontWeight={'bold'}>
                            Code
                          </Typography>
                        </TableCell>
                        <TableCell>{schema.code}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant={'body2'} fontWeight={'bold'}>
                            Type
                          </Typography>
                        </TableCell>
                        <TableCell>{schema.type}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant={'body2'} fontWeight={'bold'}>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>{schema.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant={'body2'} fontWeight={'bold'}>
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell>{schema.description}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ContentContainer>
                <Typography variant={'subtitle2'}>Hierarchy</Typography>
                <ContentContainer>
                  <AssetClassHierarchies schema={schema} />
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'fmea'}>
                <Paper
                  sx={{
                    paddingTop: theme.spacing(1),
                    marginBottom: theme.spacing(1)
                  }}
                >
                  <Container>
                    <AssetClassFailureModes
                      offset={200}
                      schema={schema}
                      enableEdit
                    />
                  </Container>
                </Paper>
              </AppTabPanel>
              <AppTabPanel value={'attributes'}>
                <ContentContainer>
                  <AssetClassAttributes offset={200} schema={schema} />
                  {/*<AWSAssetModelAttributes schema={schema.modelSchema} />*/}
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'measurements'}>
                <ContentContainer>
                  <AWSAssetModelMeasurements schema={schema.modelSchema} />
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'transforms'}>
                <ContentContainer>
                  <AssetClassTransforms offset={200} schema={schema} />
                  {/*<AWSAssetModelTransforms schema={schema.modelSchema} />*/}
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'metrics'}>
                <ContentContainer>
                  <AssetClassMetrics offset={200} schema={schema} />
                  {/*<AWSAssetModelMetrics schema={schema.modelSchema} />*/}
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'events'}>
                <ContentContainer>
                  <AssetClassEventTriggers
                    offset={200}
                    schema={schema}
                    enableEdit
                  />
                </ContentContainer>
              </AppTabPanel>
            </AppTabs>
          </>
        )}
      </AppDrawerContent>
    </AppDrawer>
  )
}
