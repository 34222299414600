import { AssetPropertyValueSchema, AssetSchema, AssetState } from '@loadsys-cmms/app-sdk'
import { useMounted } from '@loadsys-cmms/ui-components'
import { useCallback, useState } from 'react'
import { useAppDispatch } from '../../../store/useAppDispatch'
import { API } from 'aws-amplify'
import { plainToClass } from 'class-transformer'
import { AssetsActions } from '../store/store-config'


export const useGetAssetPropertyValues = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState<
    Record<string, string> | Record<string, string>[]
  >(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const getAssetPropertyValues = useCallback(
    async (assetId: string): Promise<AssetPropertyValueSchema[]> => {
      setProcessing(true)
      return API.get('assetsApi', '/assets/' + assetId + '/property-values', {}).then((properties) => {
        return plainToClass(AssetPropertyValueSchema, properties)
      })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setErrorMessage(err?.response?.data?.errorMessage)
            setErrors(err?.response?.data?.errors)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    errors,
    errorMessage,
    getAssetPropertyValues,
  }
}
