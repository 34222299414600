import { IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator'
import _ from 'lodash'
import { BaseSchema } from '../BaseSchema'

import { IsPassword } from '../../validators/decorators/IsPasswordDecorator'

export enum UserRole {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Employee = 'EMPLOYEE'
}

export class LoginSchema extends BaseSchema {
  /**
   * Email
   */
  @IsNotEmpty()
  @IsEmail()
  email: string = null

  /**
   * Password
   */
  @IsNotEmpty()
  @IsPassword({ message: 'Invalid Password.' })
  password: string = null

  /**
   * Recatcha
   */
  @IsString()
  @IsOptional()
  challenge: string = null


  /**
   * Construct
   * @param data
   */
  constructor(data?: Partial<LoginSchema>) {
    super()
    _.assign(this, data)
  }
}