import { useCallback } from "react"
import config from "../../../Config"
import { useAmplifyPubsub } from "./useAmplifyPubsub"

export const usePubsubTopicBuilder = () => {
  const { clientId } = useAmplifyPubsub()

  const buildTopic = useCallback(
    (topic: string) => {
      return `${config.appName}/${config.appStage}/pubsub/${clientId}/${topic}`
    },
    [clientId, config.appName, config.appStage]
  )

  return {
    buildTopic
  }
}