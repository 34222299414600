import { EventSchema } from '@loadsys-cmms/app-sdk'
import { useSnackbarMessages } from '@loadsys-cmms/ui-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetEventsQuery } from '../hooks/useGetEventsQuery'

export interface EventsContextProps {
  loading: boolean
  events: EventSchema[]
  page: number
  limit: number
  total: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

export const EventsContext = React.createContext<EventsContextProps>({
  loading: false,
  events: [],
  page: 1,
  limit: 1,
  total: 0,
  onPageChange: () => {},
  onPageSizeChange: () => {}
})

export interface EventsProviderProps {
  onInit?: (params: { reload: () => void }) => void
}

export const EventsProvider: React.FC<EventsProviderProps> = ({
  children,
  onInit = () => {}
}) => {
  const { addErrorMessage } = useSnackbarMessages()
  const { getEvents, processing: loading } = useGetEventsQuery()

  const [events, setEvents] = useState<EventSchema[]>([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(50)
  const [total, setTotal] = useState(0)

  const queryParams = useMemo(() => {
    return { page: page + 1, limit }
  }, [page, limit])

  const loadEvents = useCallback(() => {
    getEvents(queryParams)
      .then((response) => {
        setEvents(response.data)
        setTotal(response.metadata.itemCount)
      })
      .catch(() => {
        addErrorMessage('Failed to load the Events.')
      })
  }, [queryParams])

  useEffect(() => {
    loadEvents()
    onInit({ reload: loadEvents })
  }, [loadEvents])

  const contextValue = useMemo(() => {
    return {
      loading,
      page,
      limit,
      total,
      events,
      onPageChange: setPage,
      onPageSizeChange: setLimit
    }
  }, [page, limit, events, total, loading])

  return (
    <EventsContext.Provider value={contextValue}>
      {children}
    </EventsContext.Provider>
  )
}
