import { IsObject, IsOptional, ValidateNested } from "class-validator";
import { BaseSchema } from "../../BaseSchema";
/*import { Measurement } from '@aws-sdk/client-iotsitewise'*/
import { Expose, Type } from "class-transformer";
import { MeasurementProcessingConfigSchema } from "./MeasurementProcessingConfigSchema";
import _ from "lodash";

export class AssetModelPropertyTypeMeasurementSchema extends BaseSchema /*implements Measurement*/ {
  /**
   * Initialize Measurement
   *
   * @param data AssetModelPropertyTypeMeasurementSchema
   */
  constructor(data?: AssetModelPropertyTypeMeasurementSchema) {
    super()

    this.processingConfig = new MeasurementProcessingConfigSchema()

    if (data) {
      _.assign(data)
    }
  }

  /**
   * Measurement Schema
   */
  @Expose()
  @Type(() => MeasurementProcessingConfigSchema)
  @IsOptional()
  @IsObject()
  @ValidateNested()
  processingConfig: MeasurementProcessingConfigSchema;
}