import { AssetModelSchema } from '@loadsys-cmms/app-sdk'
import { AppDataGrid } from '@loadsys-cmms/ui-components'
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { getAssetClassSelector } from '../../asset-classes/selectors/selectors'

interface AssetModelsDataGridProps {
  schemas: AssetModelSchema[]
}

export const AssetModelsDataGrid: React.FC<AssetModelsDataGridProps> = ({
  schemas
}) => {
  const getAssetClass = useAppSelector(getAssetClassSelector)
  const navigate = useNavigate()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection
    }
  ])

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        width: 200
      },
      {
        field: 'code',
        headerName: 'Code',
        width: 150
      },
      {
        field: 'assetClass',
        headerName: 'Class',
        width: 200,
        valueGetter: (params) => {
          return getAssetClass(params.row.assetClassId)?.name || '-'
        }
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 200
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 300
      }
    ] as GridColDef[]
  }, [])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    navigate(params.row.id)
  }

  return (
    <AppDataGrid
      density={'compact'}
      getRowId={(model: AssetModelSchema) => model.id}
      sortModel={sortModel}
      columns={columns}
      rows={schemas}
      onCellClick={handleOnCellClick}
    />
  )
}
