import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { EventTypePicker, EventTypePickerProps } from './EventTypePicker'

export interface SchemaEventTypePickerProps
  extends Omit<EventTypePickerProps, 'onChange' | 'value'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaEventTypePicker: React.FC<SchemaEventTypePickerProps> = ({
  schema,
  field,
  onChange,
  helperText,
  ...props
}) => {
  return (
    <EventTypePicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: string) => onChange(value, field)}
      {...props}
    />
  )
}
