import { Button, Grid, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { EmailConfirmationSchema } from '@loadsys-cmms/app-sdk'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import React from 'react'
import { useState } from 'react'
import { SchemaTextInput } from '../inputs/schema/SchemaTextInput'
import { Avatar, Box } from '@mui/material'

interface EmailConfirmationFormProps {
  onSubmit: (data: EmailConfirmationSchema) => void
  data?: EmailConfirmationSchema
}

export const EmailConfirmationForm: React.FC<EmailConfirmationFormProps> = ({
  onSubmit = () => {},
  data: dataParam
}) => {
  const [data] = useState<EmailConfirmationSchema>(
    dataParam?.clone() || new EmailConfirmationSchema()
  )
  const [hash, setHash] = useState('')

  const handleOnChange = React.useCallback(
    (value: string, field: string) => {
      data.setValue(field, value)
      //trigger change on data change
      setHash(data.generateHash())
    },
    [data]
  )

  const handleOnSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      console.log('submit')
      if (data?.validate()) {
        setHash(data.generateHash())
        onSubmit(data)
      } else {
        console.log('errors', data.getErrors())
        setHash(data.generateHash())
      }
    },
    []
  )

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Confirm Email
      </Typography>

      <Box component='form' onSubmit={handleOnSubmit} noValidate sx={{ mt: 1 }}>
        <SchemaTextInput
          schema={data}
          field={'email'}
          label={'Email'}
          onChange={handleOnChange}
        />
        <SchemaTextInput
          schema={data}
          field={'confirmationCode'}
          label={'Confirmation Code'}
          onChange={handleOnChange}
          type={'password'}
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Confirm
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              component={RouterLink}
              to='/resend-confirnation'
              variant='body2'
            >
              Resend Confirmation?
            </Link>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to='/login' variant='body2'>
              {'Already confirmed? Login.'}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
