import { AppDataGrid } from '@loadsys-cmms/ui-components'
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AssetClassModel } from '../models/AssetClassModel'

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 200
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 300
  }
]

interface AssetClassesDataGridProps {
  schemas: AssetClassModel[]
}

export const AssetClassesDataGrid: React.FC<AssetClassesDataGridProps> = ({
  schemas
}) => {
  const navigate = useNavigate()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection
    }
  ])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    navigate(`/asset-management/classes/${params.row.id}`)
  }

  return (
    <AppDataGrid
      density={'compact'}
      getRowId={(model: AssetClassModel) => model.id}
      sortModel={sortModel}
      columns={columns}
      rows={schemas}
      onCellClick={handleOnCellClick}
    />
  )
}
