import { AssetModelSchema } from "@loadsys-cmms/app-sdk"
import createStoreAdapter from "../../../store/createStoreAdapter"

const STORE_NAME = 'assetModels'

const adapter = createStoreAdapter<AssetModelSchema>({
  classType: AssetModelSchema,
  storeName: STORE_NAME,
  apiParams: { name: 'assetsApi', 'baseUrl': '/models' },
  selectIdFn: (entity: AssetModelSchema) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const AssetModelsReducer = adapter.reducer
export const AssetModelsSelectors = adapter.selectors
export const AssetModelsThunks = adapter.thunks
export const AssetModelsActions = adapter.actions

export default adapter
