//import { Metric } from "@aws-sdk/client-iotsitewise";
import { Expose, Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsOptional, IsString, ValidateNested } from "class-validator";
import { BaseSchema } from "../../BaseSchema";
import { ExpressionVariableSchema } from "./ExpressionVariableSchema";
import { MetricProcessingConfigSchema } from "./MetricProcessingConfigSchema";
import { MetricWindowSchema } from "./MetricWindowSchema";

export class AssetModelPropertyTypeMetricSchema extends BaseSchema /*implements Metric */ {

  /**
   *
   */
  @Expose()
  @IsString()
  @IsNotEmpty()
  expression: string;

  /**
   * <p>The list of variables used in the expression.</p>
   */
  @Expose()
  @Type(() => ExpressionVariableSchema)
  @IsOptional()
  @IsArray()
  @ValidateNested()
  variables: ExpressionVariableSchema[] = [];

  /**
   * <p>The window (time interval) over which IoT SiteWise computes the metric's aggregation expression.
   *       IoT SiteWise computes one data point per <code>window</code>.</p>
   */
  @Expose()
  window: MetricWindowSchema = new MetricWindowSchema();

  /**
   * <p>The processing configuration for the given metric property.
   *       You can configure metrics to be computed at the edge or in the Amazon Web Services Cloud.
   *       By default, metrics are forwarded to the cloud.</p>
   */
  @Expose()
  @Type(() => MetricProcessingConfigSchema)
  @IsOptional()
  @ValidateNested()
  processingConfig?: MetricProcessingConfigSchema = new MetricProcessingConfigSchema();
}