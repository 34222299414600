import { AssetClassEventTriggerSchema } from '@loadsys-cmms/app-sdk'
import { AppDataGrid, useRefreshComponent } from '@loadsys-cmms/ui-components'
import {
  GridColDef,
  GridActionsCellItem,
  GridRowParams
} from '@mui/x-data-grid-pro'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import hashObject from 'hash-object'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { AddAssetClassEventTriggerButton } from './AddAssetClassEventTriggerButton'
import { EditAssetClassEventTriggerDrawer } from './EditAssetClassEventTriggerDrawer'
import { useAppSelector } from '../../../store/useAppSelector'
import { getEventTypeSelector } from '../../event-types/selectors/selectors'
import { getClassPropertySelector } from '../../asset-classes/selectors/selectors'

interface AssetClassEventTriggersProps {
  schema: AssetClassModel
  enableEdit?: boolean
  offset?: number
}

export const AssetClassEventTriggers: React.FC<
  AssetClassEventTriggersProps
> = ({ schema, enableEdit, offset = 0 }) => {
  const getEventType = useAppSelector(getEventTypeSelector)
  const getClassProperty = useAppSelector(getClassPropertySelector)

  const [formHeight, setFormHeight] = useState<number>(37)
  const [editEventTrigger, setEditEventTrigger] =
    useState<AssetClassEventTriggerSchema>(null)
  const { refreshComponent } = useRefreshComponent()

  const handleOnFieldChange = () => {
    refreshComponent()
  }

  const handleOnDelete = useCallback(
    (eventTrigger: AssetClassEventTriggerSchema) => {
      /*const index = _.findIndex(schema.eventTriggers, (a) => a === eventTrigger)
      if (
        index >= 0 &&
        schema.eventTriggers &&
        schema.eventTriggers.length > index
      ) {
        schema.eventTriggers.splice(index, 1)
        onChange(schema)
      }*/
    },
    [schema?.eventTriggers]
  )

  const handleOnEdit = useCallback(
    (eventTrigger: AssetClassEventTriggerSchema) => {
      setEditEventTrigger(eventTrigger)
    },
    [schema?.eventTriggers]
  )

  const handleOnChange = useCallback(() => {
    refreshComponent()
  }, [schema])

  const handleOnEditClose = useCallback(
    (eventTrigger?: AssetClassEventTriggerSchema) => {
      if (schema && eventTrigger) {
        const index = _.findIndex(
          schema.eventTriggers,
          (a) => a.id === eventTrigger.id
        )
        if (index >= 0) {
          schema?.eventTriggers.splice(index, 1, eventTrigger)
          refreshComponent()
        }
      }
      setEditEventTrigger(null)
    },
    [schema]
  )

  const eventTriggers = useMemo(() => {
    return _.map(schema?.eventTriggers, (eventTrigger) => eventTrigger)
  }, [hashObject(schema?.eventTriggers)])

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          width: 150
        },
        {
          field: 'code',
          headerName: 'Code',
          width: 150
        },
        {
          field: 'property',
          headerName: 'Property',
          width: 150,
          valueGetter: (params) => {
            return (
              getClassProperty(
                params.row.assetClassId,
                params.row.assetClassMetricId
              )?.name || '-'
            )
          }
        },
        {
          field: 'event',
          headerName: 'Event',
          width: 150,
          valueGetter: (params) => {
            return getEventType(params.row.eventTypeId)?.name || '-'
          }
        },
        {
          field: 'severity',
          headerName: 'Severity',
          width: 150,
          valueGetter: (params) => {
            return getEventType(params.row.eventTypeId)?.severity || '-'
          }
        },
        {
          width: 80,
          field: 'actions',
          type: 'actions',
          sortable: false,
          editable: false,
          filterable: false,
          hideable: false,
          hide: !enableEdit,
          getActions: (params: GridRowParams) => [
            <GridActionsCellItem
              key={'edit'}
              icon={<EditIcon />}
              label='Edit'
              onClick={() => handleOnEdit(params.row)}
            />,
            <GridActionsCellItem
              key={'delete'}
              icon={<DeleteIcon />}
              label='Delete'
              onClick={() => handleOnDelete(params.row)}
            />
          ]
        }
      ] as GridColDef[],
    [handleOnDelete, enableEdit, schema]
  )

  const heightCalc = useMemo(() => {
    return `calc(100vh - ${(formHeight || 0) + offset}px)`
  }, [formHeight, offset])

  if (!schema) {
    return null
  }

  return (
    <>
      {enableEdit && (
        <Box>
          <AddAssetClassEventTriggerButton
            schema={schema}
            onChange={handleOnChange}
          />
        </Box>
      )}
      <Box sx={{ height: heightCalc, flexGrow: 1 }}>
        <AppDataGrid
          density={'compact'}
          columns={columns}
          rows={eventTriggers}
          getRowId={(row) => row.code}
          pinnedColumns={{ right: ['actions'] }}
        />
      </Box>
      <EditAssetClassEventTriggerDrawer
        assetClass={schema}
        open={!!editEventTrigger}
        onClose={handleOnEditClose}
        eventTrigger={editEventTrigger}
      />
    </>
  )
}
