import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Popover,
  Typography,
  useTheme
} from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import { ArrowDropDown } from '@mui/icons-material'
import _ from 'lodash'
import React, { ReactNode, useCallback, useEffect } from 'react'

interface PopoverPickerProps {
  value?: ReactNode | string
  placeholder?: string
  disabled?: boolean
  processing?: boolean
}

export const PopoverPicker: React.FC<PopoverPickerProps> = ({
  value,
  placeholder,
  children,
  disabled,
  processing
}) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    if (processing) {
      setAnchorEl(null)
    }
  }, [processing])

  useEffect(() => {
    if (disabled) {
      setAnchorEl(null)
    }
  }, [disabled])

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const renderValue = useCallback(() => {
    if (!value) {
      return null
    }
    if (_.isString(value)) {
      return <Typography>{value}</Typography>
    } else {
      return value
    }
  }, [value])

  return (
    <>
      <Button
        disabled={disabled || processing}
        onClick={handlePopoverOpen}
        fullWidth={false}
        startIcon={processing ? <CircularProgress size={'1em'} /> : null}
        endIcon={<ArrowDropDown />}
      >
        <Box display={'flex'} flex={1}>
          {value && <Box /*className={styles.label}*/>{renderValue()}</Box>}
          {!value && (
            <Box display={'flex'} alignItems={'center'} flex={1}>
              <Typography style={{ flex: 1 }}>{placeholder}</Typography>
            </Box>
          )}
        </Box>
      </Button>
      {anchorEl && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
        >
          {children}
          <Divider />
          <Button onClick={handlePopoverClose}>Close</Button>
        </Popover>
      )}
    </>
  )
}
