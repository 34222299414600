import { TextField } from '@mui/material'
import React, { useCallback } from 'react'
import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export interface SchemaTextInputProps {
  inputClassName?: any
  className?: any
  schema: BaseSchema
  label?: String
  field: string
  helperText?: string
  onChange?: Function
  onBlur?: Function
  disabled?: boolean
  multiline?: boolean
  required?: boolean
  type?: string
  variant?: 'standard' | 'filled' | 'outlined'
  rowsMax?: number
  placeholder?: string
  rows?: number
  autoFocus?: boolean
  inputProps?: object
  margin?: 'dense' | 'normal' | 'none'
}

export const SchemaTextInput: React.FC<SchemaTextInputProps> = ({
  className,
  inputClassName,
  schema,
  field,
  label,
  helperText,
  onChange = () => {},
  disabled,
  multiline,
  required,
  type,
  variant,
  rowsMax,
  placeholder,
  rows,
  autoFocus,
  inputProps = {},
  margin = 'dense'
}) => {
  const handleOnChange = useCallback(
    (value: string, field: string) => {
      schema.setValue(field, value)
      onChange(value, field)
    },
    [schema, onChange]
  )
  return (
    <TextField
      margin={margin}
      autoFocus={autoFocus}
      inputProps={_.merge(
        {
          className: inputClassName,
          autoComplete: 'off'
        },
        inputProps
      )}
      className={className}
      disabled={disabled}
      label={label}
      value={String(schema.getValue(field) || '')}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(event) => handleOnChange(event.target.value, field)}
      multiline={multiline}
      required={required}
      fullWidth
      type={type}
      variant={variant}
      maxRows={rowsMax}
      placeholder={placeholder}
      rows={rows}
    />
  )
}
