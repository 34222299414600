import { Tab, Tabs, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface AppTabProps {
  value: string
  route?: string
  label: string
}

export interface AppTabsContextProps {
  selected?: string
  select: (value: string) => void
  hidden?: boolean
}

export const AppTabsContext = React.createContext<AppTabsContextProps>({
  selected: null,
  select: () => {},
  hidden: true
})

interface AppTabsProps {
  tabs: AppTabProps[]
  selected?: string
  hidden?: boolean
}

export const AppTabs: React.FC<AppTabsProps> = ({
  tabs,
  selected,
  hidden,
  children
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [selectedTab, selectTab] = useState(selected)

  const context: AppTabsContextProps = {
    selected: selectedTab,
    select: selectTab,
    hidden
  }

  useEffect(() => {
    selectTab(selected)
  }, [selected])

  const handleOnClick = (tab: AppTabProps) => {
    if (tab.route) {
      navigate(tab.route)
    } else {
      selectTab(tab.value)
    }
  }

  return (
    <AppTabsContext.Provider value={context}>
      <Tabs
        sx={{ marginBottom: theme.spacing(1) }}
        scrollButtons='auto'
        variant='scrollable'
        indicatorColor='primary'
        textColor='primary'
        value={selectedTab}
        onChange={(_event: any, value: string) => selectTab(value)}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            onClick={() => handleOnClick(tab)}
          />
        ))}
      </Tabs>
      {children}
    </AppTabsContext.Provider>
  )
}
