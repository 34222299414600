import { AppTabProps } from '@loadsys-cmms/ui-components'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { AppTabsContainer } from '../../components/AppTabsContainer'
import { LocationsThunks } from '../../features/locations/store/store-config'
import { useAppDispatch } from '../../store/useAppDispatch'

const routePrefix = '/location-management'

const tabs: AppTabProps[] = [
  { value: 'locations', route: `${routePrefix}/locations`, label: 'Locations' }
]

interface LocationManagementProps {}

export const LocationManagement: React.FC<LocationManagementProps> = ({
  children
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      LocationsThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
  }, [])

  return (
    <AppTabsContainer tabs={tabs} selected={'locations'}>
      {children}
      <Outlet />
    </AppTabsContainer>
  )
}
