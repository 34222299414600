import {
  AssetFailureModeCauseSchema,
  AssetFailureModeSchema
} from '@loadsys-cmms/app-sdk'
import { useRefreshComponent } from '@loadsys-cmms/ui-components'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import _ from 'lodash'
import { AssetFailureCauseNameValue } from '../../asset-failure-causes/components/AssetFailureCauseNameValue'
import { AddAssetFailureModeCause } from './AddAssetFailureModeCause'

interface AssetFailureModeCausesProps {
  schema: AssetFailureModeSchema
  onChange?: (schema: AssetFailureModeSchema) => void
  enableEdit?: boolean
}

export const AssetFailureModeCauses: React.FC<AssetFailureModeCausesProps> = ({
  schema,
  onChange = () => {},
  enableEdit
}) => {
  const { refreshComponent } = useRefreshComponent()
  const handleOnFieldChange = () => {
    refreshComponent()
    onChange(schema)
  }

  const handleOnDelete = (child: AssetFailureModeCauseSchema) => {
    if (schema.failureCauses) {
      const index = _.findIndex(schema.failureCauses, (c) => c === child)
      if (index >= 0) {
        schema.failureCauses.splice(index, 1)
        refreshComponent()
        onChange(schema)
      }
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      {enableEdit && (
        <AddAssetFailureModeCause
          failureCauses={schema.failureCauses}
          onChange={handleOnFieldChange}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Cause</TableCell>
            <TableCell>Local Effect</TableCell>
            <TableCell>System Effect</TableCell>
            {enableEdit && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(schema?.failureCauses, (cause, index) => (
            <TableRow key={cause.id ?? index}>
              <TableCell>
                <AssetFailureCauseNameValue
                  assetFailureCauseId={cause.assetFailureCauseId}
                />
              </TableCell>
              <TableCell>{cause.localEffect}</TableCell>
              <TableCell>{cause.systemEffect}</TableCell>
              {enableEdit && (
                <TableCell>
                  <Button onClick={() => handleOnDelete(cause)}>delete</Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
