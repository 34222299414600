import { AssetSchema } from '@loadsys-cmms/app-sdk'
import {
  SchemaDateTimeInput,
  SchemaTextInput
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { SchemaAssetClassPicker } from '../../../asset-classes/pickers/SchemaAssetModelPicker'
import { SchemaAssetModelPicker } from '../../../asset-models/components/pickers/SchemaAssetModelPicker'
import { SchemaLocationPicker } from '../../../locations/components/pickers/SchemaLocationPicker'

interface AssetFormProps {
  schema: AssetSchema
  onChange: (schema: AssetSchema) => void
}

export const AssetForm: React.FC<AssetFormProps> = ({
  schema,
  onChange = () => {}
}) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnLeaveName = () => {
    if (_.isEmpty(schema?.code) && !_.isEmpty(schema?.name)) {
      schema.code = _.camelCase(schema.name)
      onChange(schema)
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaLocationPicker
        label={'Location'}
        placeholder={'Location'}
        schema={schema}
        field={'locationId'}
        onChange={handleOnFieldChange}
      />
      <SchemaAssetModelPicker
        label={'Model'}
        placeholder={'Asset Model'}
        schema={schema}
        field={'assetModelId'}
        onChange={handleOnFieldChange}
      />
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
        inputProps={{
          onBlur: handleOnLeaveName
        }}
      />
      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
      />
      <SchemaTextInput
        label={'Description'}
        placeholder={'Description'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'description'}
      />
      <SchemaTextInput
        label={'Make'}
        placeholder={'Make'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'make'}
      />
      <SchemaTextInput
        label={'Model'}
        placeholder={'Model'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'model'}
      />
      <SchemaTextInput
        label={'Serial #'}
        placeholder={'Serial #'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'serial'}
      />
      <SchemaDateTimeInput
        type={'date'}
        label={'Installation Date'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'installationDate'}
      />
      <SchemaDateTimeInput
        type={'date'}
        label={'Warranty Expiration Date'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'warrantyExpirationDate'}
      />
    </>
  )
}
