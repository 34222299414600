import { AssetFailureModeSchema } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import { AssetFailureModesSelectors } from '../../store/store-config'

export interface AssetFailureModePickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const AssetFailureModePicker: React.FC<AssetFailureModePickerProps> = ({
  onChange,
  label = 'Failure Mode',
  placeholder = 'Failure Mode',
  ...props
}) => {
  //const { checkPermissions } = useAppPermissions()

  const [openAdd, setOpenAdd] = useState(false)
  const [text, setText] = useState<string>('')

  const entities = useAppSelector(AssetFailureModesSelectors.selectAll)

  const options: InputOptionProps[] = useMemo(() => {
    return _.sortBy(
      _.map(entities, (c) => c.getPickerInputOption()),
      ['label'],
      ['asc']
    )
  }, [entities])

  const handleOnCreate = (name?: string) => {
    setOpenAdd(true)
    setText(name)
  }

  const handleOnCloseAdd = (schema?: AssetFailureModeSchema) => {
    if (schema) {
      onChange(schema.getIdentifier())
    }
    setOpenAdd(false)
  }

  return (
    <>
      <AutocompleteInput
        {...props}
        label={label}
        placeholder={placeholder}
        options={options}
        onChange={(value) => onChange(value as string)}
      />
      {/*openAdd && <AssetFailureModePickerAddDrawer
      open={openAdd}
      onClose={handleOnCloseAdd}
      defaultData={{
        name: text,
      }}
    />*/}
    </>
  )
}
