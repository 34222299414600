import { Expose, Type } from "class-transformer";
import { IsOptional, IsString, IsNotEmpty, IsAlphanumeric, MaxLength } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../BaseSchema";

export class LocationSchema extends BaseSchema {
  /**
   * Initialize
   *
   * @param data LocationSchema
   */
  constructor(data?: Partial<LocationSchema>) {
    super()

    this.isEnabled = true

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  name: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @IsAlphanumeric()
  @MaxLength(255)
  code: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  description: string;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  isEnabled: boolean = true;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  created: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  updated: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  deleted: Date;

}