import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import { AssetFailureCauseSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { AssetFailureCauseForm } from './forms/AssetFailureCauseForm'
import { useNavigate } from 'react-router-dom'
import { useAddAssetFailureCauseQuery } from '../hooks/useAddAssetFailureCauseQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'

interface AddAssetFailureCauseDrawerProps {
  open: boolean
  onClose: (schema?: AssetFailureCauseSchema) => void
  defaultData?: Partial<AssetFailureCauseSchema>
  navigateOnClose?: boolean
}

export const AddAssetFailureCauseDrawer: React.FC<
  AddAssetFailureCauseDrawerProps
> = ({ open, onClose, defaultData, navigateOnClose }) => {
  const { addAssetFailureCause, processing } = useAddAssetFailureCauseQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetFailureCauseSchema>(
    new AssetFailureCauseSchema(defaultData)
  )

  const navigateTo = useCallback(
    (to: string) => {
      if (navigateOnClose) {
        navigate(to)
      }
    },
    [navigateOnClose]
  )

  useEffect(() => {
    if (open) {
      setSchema(new AssetFailureCauseSchema(defaultData))
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addAssetFailureCause(schema)
        .then((result) => {
          setSchema(new AssetFailureCauseSchema(defaultData))
          addSuccessMessage('Failure Cause created.')
          onClose(result)
          navigateTo(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to create the Failure Cause. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigateTo('/asset-management/failure-causes')
    onClose()
  }, [navigateTo, onClose])

  const handleOnReset = useCallback(() => {
    setSchema(new AssetFailureCauseSchema(defaultData))
  }, [defaultData])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Add Failure Cause
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <ContentContainer>
            <AssetFailureCauseForm
              schema={schema}
              onChange={handleOnSchemaChanged}
            />
          </ContentContainer>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
