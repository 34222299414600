import { IsString, IsNotEmpty, IsOptional } from 'class-validator';
import { Type, Expose } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash';

export class AssetClassHierarchySchema extends BaseSchema {
  constructor(data?: Partial<AssetClassHierarchySchema>) {
    super()

    this.id = uuidv4()
    if (data) {
      _.assign(this, data)
    }
  }

  @Expose()
  @IsNotEmpty()
  @IsString()
  id: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  assetClassId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  childAssetClassId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  name: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  code: string;
}
