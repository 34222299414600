import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { memoize } from 'lodash'
import { AppRootState } from '../../../store/AppStore'
import { getAssetFailureModeSelector } from '../../asset-failure-modes/selectors/selectors'
import { AssetFailureCausesSelectors } from '../store/store-config'

export const getAssetFailureCauseSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return AssetFailureCausesSelectors.selectById(state, id)
    }),
)

export const assetFailureCauseSelector = createSelector(
  getAssetFailureCauseSelector,
  (getFailureCause) =>
    memoize((id: string) => {
      return getFailureCause(id)
    }),
)

export const getAssetFailureCausesForFailureModeSelector = createSelector(
  (state: AppRootState) => state,
  getAssetFailureModeSelector,
  (state, getFailureMode) =>
    memoize((failureModeId: string) => {
      const failureMode = getFailureMode(failureModeId)
      return _.map(failureMode?.failureCauses, cause => AssetFailureCausesSelectors.selectById(state, cause.assetFailureCauseId))
    }),
)

export const assetFailureCausesForFailureModeSelector = createSelector(
  getAssetFailureCausesForFailureModeSelector,
  (_state: AppRootState, assetFailureModeId: string) => assetFailureModeId,
  (getAssetFailureCausesForFailureMode, assetFailureModeId) =>
    getAssetFailureCausesForFailureMode(assetFailureModeId)
)
