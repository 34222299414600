import { BaseSchema } from "../../BaseSchema";
import { Expose, Transform, Type } from "class-transformer";
import { IsBoolean, IsNotEmpty, IsNumber, IsString, ValidateIf } from "class-validator";
import { Assets } from "../types";
import _ from "lodash";

export class AssetPropertyValueVariantSchema extends BaseSchema implements Assets.Variant {
  @Expose()
  @IsString()
  @ValidateIf(obj => !obj.integerValue && !obj.doubleValue && !obj.booleanValue)
  stringValue?: string;

  @Expose()
  @IsNotEmpty()
  @Type(() => Number)
  @ValidateIf(obj => !obj.stringValue && !obj.doubleValue && !obj.booleanValue)
  @Transform(({ value }) => _.isString(value) ? new Number(value) : value, { toPlainOnly: true })
  @IsNumber()
  integerValue?: number;

  @Expose()
  @Type(() => Number)
  @IsNotEmpty()
  @ValidateIf(obj => !obj.stringValue && obj.integerValue && obj.booleanValue)
  @Transform(({ value }) => _.isString(value) ? new Number(value) : value, { toPlainOnly: true })
  @IsNumber()
  doubleValue?: number;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  @ValidateIf(obj => !obj.stringValue && !obj.integerValue && !obj.doubleValue)
  @IsBoolean()
  booleanValue?: boolean;
}