import { validateSync } from 'class-validator'
import { classToClass, Exclude, plainToClass } from 'class-transformer'
import { Class } from 'type-fest'

import _ from 'lodash'
import hashObject from 'hash-object'

export class BaseSchema<T = any> {
  ctor: Class<this>

  constructor(data?: Partial<T>) {
    _.assign(this, data)
  }

  id: string

  /**
   * Errors generated by class validator
   */
  @Exclude()
  _errors: Record<string, string> = {}

  /**
   * Validate the class
   * @param groups
   * @returns
   */
  public validate (groups: string[] = undefined) {
    const instance: any = this

    this._errors = {}

    const errors = validateSync(instance, {
      groups: groups,
      forbidUnknownValues: true,
      validationError: {
        target: false
      }
    })

    this._errors = this._generateValidationErrors(errors)

    this._applyErrorsNested()

    return _.isEmpty(this._errors)
  }

  /**
   * Check if field is valid
   */
  public isValid (): boolean {
    return _.isEmpty(this._errors)
  }

  /**
   * Check if property has failed during the validation
   * @param propertyName Class property name
   */
  public isPropertyInvalid (propertyName: string): boolean {
    const error = this.getPropertyError(propertyName)

    return !_.isEmpty(error)
  }

  /**
   * Check if property failed during the last validation
   * @param propertyName Class property name
   */
  public getPropertyError (propertyName: string): any {
    return _.get(this._errors, propertyName)
  }

  /**
   * Transform errors to key=error pairs
   * @param validationErrors
   * @returns
   */
  public _generateValidationErrors (validationErrors: any) {
    const errors: any = {}
    _.forEach(validationErrors, (error: any) => {
      if (!_.isEmpty(error.children)) {
        errors[error.property] = this._generateValidationErrors(error.children)
      } else {
        let message = ''
        _.forEach(error.constraints, (msg: any) => {
          message = message + (!_.isEmpty(message) ? ', ' : '') + msg
        })
        errors[error.property] = message
      }
    })
    return errors
  }

  /**
   * Get object validation errors
   * @returns errors
   */
  public getErrors () {
    return this._errors
  }

  /**
   * Set Object validator errors
   * @param errors errors
   */
  public setErrors (errors: any) {
    this._errors = errors

    this._applyErrorsNested()
  }

  /**
   * Processed nested errors
   */
  public _applyErrorsNested () {
    const properties = _.keys(this)

    _.forEach(properties, (property) => {
      const propertyObj = _.get(this, property)
      const propertyErrors = _.get(this._errors, property)
      if (propertyObj instanceof BaseSchema) {
        propertyObj.setErrors(propertyErrors)
      } else if (_.isArray(propertyObj)) {
        _.forEach(propertyObj, (arrObj, arrayKey) => {
          if (arrObj instanceof BaseSchema) {
            const arrayItemErrors = _.get(propertyErrors, arrayKey)
            arrObj.setErrors(arrayItemErrors)
          }
        })
      }
    })
  }

  /**
   * Generate object hash
   * @returns string Hash
   */
  public generateHash () {
    return hashObject(this, { algorithm: 'md5' })
  }

  /**
   * Get property value
   * @param name
   * @returns
   */
  public getValue (name: string) {
    return _.get(this, name)
  }

  /**
   * Set property value
   * @param name
   * @param value
   * @returns
   */
  public setValue (name: string, value: any) {
    _.set(this, name, value)
  }

  /**
   * Get validation error count
   * @returns number count
   */
  public getErrorCount () {
    if (_.isEmpty(this._errors)) {
      return 0
    }

    const keys = _.keys(this._errors)
    return keys.length
  }

  /**
   * Clone class to a new object
   * @returns
   */
  public clone () {
    return plainToClass(this.ctor, classToClass(this))
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    let labelProp = 'id'
    if (_.has(this, 'name')) {
      labelProp = 'name'
    } else if (_.has(this, 'title')) {
      labelProp = 'title'
    }
    return _.get(this, labelProp)
  }

  /**
   * Get unique identifier for an item
   * @returns
   */
  getIdentifier () {
    return this.getValue('id')
  }

  /**
   * Get label for an item
   * @returns
   */
  getLabel () {
    return this.getValue('name')
  }

  /**
   * Get an object for the picker input
   * @returns
   */
  getPickerInputOption () {
    return {
      value: this.getIdentifier(),
      label: this.getLabel()
    }
  }
}
