import { IsString, IsNotEmpty, IsOptional } from 'class-validator';
import { Expose } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import _ from 'lodash';

export class AssetModelHierarchySchema extends BaseSchema {
  @Expose()
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsOptional()
  @IsString()
  assetModelId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  childAssetModelId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  assetClassHierarchyId: string;
}
