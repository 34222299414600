import { AssetFailureModeSchema } from "@loadsys-cmms/app-sdk"
import createStoreAdapter from "../../../store/createStoreAdapter"

const STORE_NAME = 'assetFailureModes'

const adapter = createStoreAdapter<AssetFailureModeSchema>({
  classType: AssetFailureModeSchema,
  storeName: STORE_NAME,
  apiParams: { name: 'assetsApi', 'baseUrl': '/failure-modes' },
  selectIdFn: (entity: AssetFailureModeSchema) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const AssetFailureModesReducer = adapter.reducer
export const AssetFailureModesSelectors = adapter.selectors
export const AssetFailureModesThunks = adapter.thunks
export const AssetFailureModesActions = adapter.actions

export default adapter
