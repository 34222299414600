import { LocationSchema } from '@loadsys-cmms/app-sdk'
import { AppToolbar, MeasuredContainer } from '@loadsys-cmms/ui-components'
import { Box, Button, Paper } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { LocationsSelectors } from '../../features/locations/store/store-config'
import { useEntityParams } from '../../hooks/useEntityParams'
import { useAppSelector } from '../../store/useAppSelector'
import { LocationsDataGrid } from '../../features/locations/components/LocationsDataGrid'
import { ViewLocationDrawer } from '../../features/locations/components/ViewLocationDrawer'
import { EditLocationDrawer } from '../../features/locations/components/EditLocationDrawer'
import { AddLocationDrawer } from '../../features/locations/components/AddLocationDrawer'

export const LocationsPage: React.FC = () => {
  const { entityId, addAction, viewAction, editAction, action } =
    useEntityParams()
  const [openAdd, setOpenAdd] = useState(addAction)
  const [openView, setOpenView] = useState(viewAction)
  const [openEdit, setOpenEdit] = useState(editAction)
  const [height, setHeight] = useState(null)

  const locations: LocationSchema[] = useAppSelector(
    LocationsSelectors.selectAll
  )

  useEffect(() => {
    setOpenAdd(addAction)
  }, [addAction])

  useEffect(() => {
    setOpenView(viewAction)
  }, [viewAction])

  useEffect(() => {
    setOpenEdit(editAction)
  }, [editAction])

  const heightCalc = useMemo(() => {
    if (!height) {
      return null
    }
    return `calc(100vh - ${height + 145}px)`
  }, [height])

  return (
    <React.Fragment>
      <MeasuredContainer onMeasured={setHeight}>
        <AppToolbar>
          <Box flex={1} />
          <Button onClick={() => setOpenAdd(true)}>Add Location</Button>
          <AddLocationDrawer open={openAdd} onClose={() => setOpenAdd(false)} />
        </AppToolbar>
      </MeasuredContainer>
      <Box height={heightCalc}>
        <Paper sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
          <LocationsDataGrid schemas={locations} />
        </Paper>
      </Box>
      <ViewLocationDrawer open={openView} onClose={() => setOpenView(false)} />
      <EditLocationDrawer open={openEdit} onClose={() => setOpenEdit(false)} />
    </React.Fragment>
  )
  //return
}
