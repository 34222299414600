import { AssetModelSchema } from '@loadsys-cmms/app-sdk'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import _ from 'lodash'
import { useAppSelector } from '../../../store/useAppSelector'

import { AssetModelName } from './AssetModelName'
import { AssetModelTypeValue } from './AssetModelTypeValue'
import { AssetModelParentsSelector } from '../selectors/selectors'

interface AssetModelParentsProps {
  schema: AssetModelSchema
  onChange?: (schema: AssetModelSchema) => void
  enableEdit?: boolean
}

export const AssetModelParents: React.FC<AssetModelParentsProps> = ({
  schema
}) => {
  const parents = useAppSelector((state) =>
    AssetModelParentsSelector(state, schema?.id)
  )
  if (!schema) {
    return null
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(parents, (model, index) => (
            <TableRow key={model.id ?? index}>
              <TableCell>{model.name}</TableCell>
              <TableCell>
                <AssetModelName assetModelId={model.id} linkToModel />
              </TableCell>
              <TableCell>
                <AssetModelTypeValue assetModelId={model.id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
