import { AssetFailureCauseSchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import _ from 'lodash'

interface AssetFailureCauseFormProps {
  schema: AssetFailureCauseSchema
  onChange: (schema: AssetFailureCauseSchema) => void
}

export const AssetFailureCauseForm: React.FC<AssetFailureCauseFormProps> = ({
  schema,
  onChange = () => {}
}) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnLeaveName = () => {
    if (!_.isEmpty(schema.name) && _.isEmpty(schema.code)) {
      schema.code = _.camelCase(schema.name)
      onChange(schema)
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
        inputProps={{
          onBlur: handleOnLeaveName
        }}
      />
      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
      />
      <SchemaTextInput
        label={'Description'}
        placeholder={'Description'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'description'}
      />
    </>
  )
}
