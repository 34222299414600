import React from 'react'

import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { SwitchInput } from '../SwitchInput'

interface SchemaSwitchInputProps {
  className?: any
  schema: BaseSchema
  label?: String
  field: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
}

export const SchemaSwitchInput: React.FC<SchemaSwitchInputProps> = ({
  className,
  schema,
  field,
  label,
  helperText,
  onChange,
  disabled,
  required
}) => {
  return (
    <SwitchInput
      className={className}
      disabled={disabled}
      label={label}
      value={Boolean(schema.getValue(field))}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: boolean) => onChange(value, field)}
      required={required}
    />
  )
}
