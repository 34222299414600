//import { useAppLayout } from '@loadsys-cmms/ui-components'
import { PubSub, API } from 'aws-amplify'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '@loadsys-cmms/ui-components'
import { Typography } from '@mui/material'
import { usePubsubPublish } from '../../features/pubsub/hooks/usePubsubPublish'

export const DashboardPage: React.FC = () => {
  //const layout = useAppLayout()
  const { publish } = usePubsubPublish()
  const auth = useAuth()

  const [ready, setReady] = useState(false)
  const [subscribed, setSubscribed] = useState(false)

  /*
  useEffect(() => {
    Auth.currentCredentials().then((info) => {
      PubSub.addPluggable(
        new AWSIoTProvider({
          aws_pubsub_region: Config.cognito.REGION,
          aws_pubsub_endpoint:
            'wss://avj4op4rjk31g-ats.iot.' +
            Config.cognito.REGION +
            '.amazonaws.com/mqtt'
        })
      )
        .then((response) => {
          console.log('addPluggable:response', response)
          setReady(true)
        })
        .catch((err) => {
          //console.log('addPluggable: error', err)
        })
    })
  }, [])
  */

  useEffect(() => {
    publish('cmd', {
      type: 'echo',
      message: 'from the app'
    })
      .then((res) => {
        console.log('published', res)
      })
      .catch((err) => {
        console.log('error publish', err)
      })
    /*console.log('render pubsub')
    const sub = PubSub.subscribe('cmms/dev/pubsub/client1/#').subscribe({
      next: (data) => {
        console.log('PubSub', 'next', data)
      },
      error: (error) => {
        console.log('PubSub', 'error', error)
      },
      complete: () => {
        console.log('PubSub', 'complete')
      },
      start: (sub) => {
        console.log('PubSub', 'start', sub)
      }
    })

    PubSub.publish('cmms/dev/pubsub/client1/cmd', {
      message: 'from the app'
    })
      .then((res) => {
        console.log('published', res)
      })
      .catch((err) => {
        console.log('error publish', err)
      })

    //console.log('sub', sub)
    return () => {
      sub.unsubscribe()
    }*/
  }, [])

  /*
  useEffect(() => {
    API.get('assetsApi', '/models', {})
      .then((result) => {
        console.log({ result })
      })
      .catch((err) => {
        console.log('err', err)
      })
  })
  */
  /*useEffect(() => {
    API.post('assetsApi', '/assets', {
      body: {
        assetName: 'Test1',
        assetModelId: '0c977504-6a46-4a9e-9b1e-6fe4b75deba8'
      }
    })
      .then((result) => {
        console.log({ result })
      })
      .catch((err) => {
        console.log('err', err)
      })
  })
  */

  useMemo(() => {
    //layout?.setBarTitle('Dashboard')
  }, [])

  return <Typography>Dashboard</Typography>
}
