import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetClassModel } from '../models/AssetClassModel'
import { AssetClassesSelectors } from '../store/store-config'

export interface AssetClassPickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const AssetClassPicker: React.FC<AssetClassPickerProps> = ({
  label = 'Class',
  placeholder = 'Class',
  onChange = () => {},
  ...props
}) => {
  //const { checkPermissions } = useAppPermissions()

  const [openAdd, setOpenAdd] = useState(false)
  const [text, setText] = useState<string>('')

  const entities = useAppSelector(AssetClassesSelectors.selectAll)

  const filteredEntities = useMemo(() => {
    return _.map(entities)
  }, [entities])

  const options: InputOptionProps[] = useMemo(() => {
    return _.map(filteredEntities, (c) => c.getPickerInputOption())
  }, [filteredEntities])

  const handleOnCreate = (name?: string) => {
    setOpenAdd(true)
    setText(name)
  }

  const handleOnCloseAdd = (schema?: AssetClassModel) => {
    if (schema) {
      onChange(schema.id)
    }
    setOpenAdd(false)
  }
  return (
    <>
      <AutocompleteInput
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        {...props}
      />
      {/*openAdd && <AssetClassPickerAddDrawer
      open={openAdd}
      onClose={handleOnCloseAdd}
      defaultData={{
        name: text,
      }}
    />*/}
    </>
  )
}
