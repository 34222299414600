import {
  AppBar,
  AppLayoutProvider,
  AppMenuDrawer,
  AppMenu,
  AppMenuItemProps
} from '@loadsys-cmms/ui-components'
import { Box, Button, Toolbar, Typography } from '@mui/material'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { DashboardPage } from '../../pages/dashboard/DashboardPage'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AssetIcon from '@mui/icons-material/EmojiTransportationRounded'
import BusinessIcon from '@mui/icons-material/Business'
import EventsIcon from '@mui/icons-material/Campaign'
import { AssetManagement } from '../../pages/assets/AssetManagement'
import { NotFoundPage } from '../../pages/NotFoundPage'
import { useCallback } from 'react'
import { Auth } from 'aws-amplify'
import { AssetClassesPage } from '../../pages/assets/AssetClassesPage'
import { AssetFailureModesPage } from '../../pages/assets/AssetFailureModesPage'
import { AssetFailureCausesPage } from '../../pages/assets/AssetFailureCausesPage'
import { LocationManagement } from '../../pages/locations/LocationManagement'
import { LocationsPage } from '../../pages/locations/LocationsPage'
import { AppDataPreloader } from './AppDataPreloader'
import { AssetsPage } from '../../pages/assets/AssetsPage'
import { AssetModelsPage } from '../../pages/assets/AssetModelsPage'
import { EventManagement } from '../../pages/event-types/EventManagement'
import { EventTypesPage } from '../../pages/event-types/EventTypesPage'
import { EventsPage } from '../../pages/event-types/EventsPage'
import { MaintenanceActionsPage } from '../../pages/assets/MaintenanceActionsPage'

const MENU_ITEMS: AppMenuItemProps[] = [
  {
    title: 'Dashboard',
    route: '/',
    matchRoute: '/',
    icon: <DashboardIcon />
  },
  {
    title: 'Assets',
    route: '/asset-management/assets',
    matchRoute: '/asset-management/*',
    icon: <AssetIcon />
  },
  {
    title: 'Events',
    route: '/event-management/events',
    matchRoute: '/event-management/*',
    icon: <EventsIcon />
  },
  {
    title: 'Locations',
    route: '/location-management/locations',
    matchRoute: '/location-management/*',
    icon: <BusinessIcon />
  }
]

export const AppRoutes: React.FC = () => {
  const navigate = useNavigate()
  const handleLogout = useCallback(() => {
    Auth.signOut()
      .then(() => {
        navigate('/login')
      })
      .catch(() => {})
  }, [])
  return (
    <AppLayoutProvider>
      <Box sx={{ display: 'flex' }}>
        <AppBar>
          <Button variant={'contained'} onClick={handleLogout}>
            Logout
          </Button>
        </AppBar>
        <AppMenuDrawer>
          <AppMenu items={MENU_ITEMS} />
        </AppMenuDrawer>
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
          }}
        >
          <Toolbar />
          <AppDataPreloader></AppDataPreloader>
          <Routes>
            <Route path='/' element={<DashboardPage />} />
            <Route path='/login' element={<Navigate replace to='/' />} />
            <Route path='/asset-management' element={<AssetManagement />}>
              <Route path='assets' element={<AssetsPage />}>
                <Route path=':entityId/:action' element={<AssetsPage />} />
                <Route path=':entityId' element={<AssetsPage />} />
              </Route>
              <Route path='models' element={<AssetModelsPage />}>
                <Route path=':entityId/:action' element={<AssetModelsPage />} />
                <Route path=':entityId' element={<AssetModelsPage />} />
              </Route>
              <Route path='classes' element={<AssetClassesPage />}>
                <Route
                  path=':entityId/:action'
                  element={<AssetClassesPage />}
                />
                <Route path=':entityId' element={<AssetClassesPage />} />
              </Route>
              <Route path='failure-modes' element={<AssetFailureModesPage />}>
                <Route
                  path=':entityId/:action'
                  element={<AssetFailureModesPage />}
                />
                <Route path=':entityId' element={<AssetFailureModesPage />} />
              </Route>
              <Route path='failure-causes' element={<AssetFailureCausesPage />}>
                <Route
                  path=':entityId/:action'
                  element={<AssetFailureCausesPage />}
                />
                <Route path=':entityId' element={<AssetFailureCausesPage />} />
              </Route>
              <Route
                path='maintenance-actions'
                element={<MaintenanceActionsPage />}
              >
                <Route
                  path=':entityId/:action'
                  element={<MaintenanceActionsPage />}
                />
                <Route path=':entityId' element={<MaintenanceActionsPage />} />
              </Route>
            </Route>
            <Route path='/location-management' element={<LocationManagement />}>
              <Route path='locations' element={<LocationsPage />}>
                <Route path=':entityId/:action' element={<LocationsPage />} />
                <Route path=':entityId' element={<LocationsPage />} />
              </Route>
            </Route>
            <Route path='/event-management' element={<EventManagement />}>
              <Route path='types' element={<EventTypesPage />}>
                <Route path=':entityId/:action' element={<EventTypesPage />} />
                <Route path=':entityId' element={<EventTypesPage />} />
              </Route>
              <Route path='events' element={<EventsPage />}></Route>
            </Route>
            <Route element={<NotFoundPage />} />
          </Routes>
        </Box>
      </Box>
    </AppLayoutProvider>
  )
}
