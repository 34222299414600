import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import { AssetFailureModeSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { AssetFailureModeForm } from './forms/AssetFailureModeForm'
import { useNavigate } from 'react-router-dom'
import { useAddAssetFailureModeQuery } from '../hooks/useAddAssetFailureModeQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'

interface AddAssetFailureModeDrawerProps {
  open: boolean
  onClose: (schema?: AssetFailureModeSchema) => void
  defaultData?: Partial<AssetFailureModeSchema>
}

export const AddAssetFailureModeDrawer: React.FC<
  AddAssetFailureModeDrawerProps
> = ({ open, onClose, defaultData }) => {
  const { addAssetFailureMode, processing } = useAddAssetFailureModeQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetFailureModeSchema>(
    new AssetFailureModeSchema()
  )

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addAssetFailureMode(schema)
        .then((result) => {
          setSchema(new AssetFailureModeSchema(defaultData))
          addSuccessMessage('Asset FailureMode created.')
          onClose()
          navigate(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to update the Failure Mode. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/failure-modes')
    onClose()
  }, [])

  const handleOnReset = useCallback(() => {
    setSchema(new AssetFailureModeSchema(defaultData))
  }, [defaultData])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Add Failure Mode
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <ContentContainer>
            <AssetFailureModeForm
              schema={schema}
              onChange={handleOnSchemaChanged}
            />
          </ContentContainer>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
