import { Expose, Type } from "class-transformer";
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsPositive, Max, Min } from "class-validator";
import { BaseSchema } from "../BaseSchema";
import { MaintenanceActionWord } from "../maintenance/types";

export class MaintenanceActionSchema extends BaseSchema {
  @Expose()
  id: string;

  @Expose()
  @IsNotEmpty()
  action: string;

  @Expose()
  @IsNotEmpty()
  code: string;

  @Expose()
  @IsEnum(MaintenanceActionWord)
  @IsNotEmpty()
  word: MaintenanceActionWord;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Min(1)
  @Max(6)
  @Type(() => Number)
  pfIndex: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  @IsPositive()
  baseFrequency: number;

  /**
   * Override for option list
   * @returns
   */
  getLabel () {
    return this.action
  }
}