export namespace Assets {
  export enum ComputeLocation {
    CLOUD = "CLOUD",
    EDGE = "EDGE",
  }

  export enum PropertyDataType {
    BOOLEAN = "BOOLEAN",
    DOUBLE = "DOUBLE",
    INTEGER = "INTEGER",
    STRING = "STRING",
  }

  export enum ForwardingConfigState {
    DISABLED = "DISABLED",
    ENABLED = "ENABLED",
  }

  export interface TimeInNanos {
    /**
     * <p>The timestamp date, in seconds, in the Unix epoch format. Fractional nanosecond data is
     *       provided by <code>offsetInNanos</code>.</p>
     */
    timeInSeconds: number | undefined;

    /**
     * <p>The nanosecond offset from <code>timeInSeconds</code>.</p>
     */
    offsetInNanos?: number;
  }

  export interface Variant {
    /**
     * <p>Asset property data of type string (sequence of characters).</p>
     */
    stringValue?: string;

    /**
     * <p>Asset property data of type integer (whole number).</p>
     */
    integerValue?: number;

    /**
     * <p>Asset property data of type double (floating point number).</p>
     */
    doubleValue?: number;

    /**
     * <p>Asset property data of type Boolean (true or false).</p>
     */
    booleanValue?: boolean;
  }

  export enum Quality {
    BAD = "BAD",
    GOOD = "GOOD",
    UNCERTAIN = "UNCERTAIN",
  }

  export interface AssetPropertyValue {
    /**
     * <p>The value of the asset property (see <code>Variant</code>).</p>
     */
    value: Variant | undefined;

    /**
     * <p>The timestamp of the asset property value.</p>
     */
    timestamp: TimeInNanos;

    /**
     * <p>The quality of the asset property value.</p>
     */
    quality?: Quality;
  }

  export enum AssetPropertyValueErrorCode {
    AccessDeniedException = "AccessDeniedException",
    ConflictingOperationException = "ConflictingOperationException",
    InternalFailureException = "InternalFailureException",
    InvalidRequestException = "InvalidRequestException",
    LimitExceededException = "LimitExceededException",
    ResourceNotFoundException = "ResourceNotFoundException",
    ServiceUnavailableException = "ServiceUnavailableException",
    ThrottlingException = "ThrottlingException",
    TimestampOutOfRangeException = "TimestampOutOfRangeException",
  }

  export interface AssetPropertyError {
    /**
     * <p>The error code.</p>
     */
    errorCode: AssetPropertyValueErrorCode | string;

    /**
     * <p>The associated error message.</p>
     */
    errorMessage: string;

    /**
     * <p>A list of timestamps for each  error, if any.</p>
     */
    timestamps?: TimeInNanos[];
  }

  export interface AssetPropertyErrorEntry {
    /**
     * <p>The ID of the failed entry.</p>
     */
    entryId: string | undefined;

    /**
     * <p>The list of update property value errors.</p>
     */
    errors: AssetPropertyError[] | undefined;
  }
}

export enum AssetModelType {
  System = 'SYSTEM',
  Asset = 'ASSET',
  Component = 'COMPONENT',
}

export enum AWSResourceSyncStatus {
  Pending = 'PENDING',
  Preparing = 'PREPARING',
  Syncing = 'SYNCING',
  Complete = 'COMPLETE',
  Error = 'ERROR',
}

export enum AssetPropertyDataType {
  String = 'STRING',
  Number = 'NUMBER',
}

export enum AssetMetricAggregationStrategy {
  Average = 'AVG',
  Min = 'MIN',
  Max = 'MAX',
  Last = 'LAST',
  First = 'FIRST',
}

export enum AssetMetricAggregationPeriod {
  OneMinute = '1m',
  FiveMinutes = '5m',
  FifteenMinutes = '15m',
  OneHour = '1h',
  OneDay = '1d',
  OneWeek = '1w',
}

export enum AssetClassPropertyType {
  Measurement = 'MEASUREMENT',
  Transform = 'TRANSFORM',
  Attribute = 'ATTRIBUTE',
  Metric = 'METRIC',
}

export enum NumberComparisonOperator {
  '=' = '=',
  '<=' = '<=',
  '<' = '<',
  '>=' = '>=',
  '>' = '>',
}