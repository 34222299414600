import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import {
  AssetClassEventTriggerSchema,
  AssetPropertyDataType
} from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { AssetClassEventTriggerForm } from './forms/AssetClassEventTriggerForm'
import { useNavigate } from 'react-router-dom'
import { useUpdateAssetClassEventTriggerQuery } from '../hooks/useUpdateAssetClassEventTriggerQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { useAppSelector } from '../../../store/useAppSelector'
import hash_object from 'hash-object'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'

interface EditAssetClassEventTriggerDrawerProps {
  assetClass: AssetClassModel
  eventTrigger: AssetClassEventTriggerSchema
  open: boolean
  onClose: (schema?: AssetClassEventTriggerSchema) => void
}

export const EditAssetClassEventTriggerDrawer: React.FC<
  EditAssetClassEventTriggerDrawerProps
> = ({ open, onClose, eventTrigger, assetClass }) => {
  const { updateAssetClassEventTrigger, processing, errorMessage } =
    useUpdateAssetClassEventTriggerQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetClassEventTriggerSchema>()

  const numericPropertyOptions = useMemo(() => {
    return assetClass?.getMetricInputOptions([AssetPropertyDataType.Number])
  }, [hash_object(assetClass?.properties)])

  useEffect(() => {
    if (
      open &&
      eventTrigger?.id &&
      eventTrigger instanceof AssetClassEventTriggerSchema
    ) {
      setSchema(eventTrigger.clone())
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateAssetClassEventTrigger(schema)
        .then((result) => {
          addSuccessMessage('Event Trigger udpated.')
          onClose(result)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to update the Event Trigger. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    onClose()
  }, [])

  const handleOnReset = useCallback(() => {
    setSchema(eventTrigger)
  }, [eventTrigger])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Edit Event Trigger
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <ContentContainer>
            <AssetClassEventTriggerForm
              schema={schema}
              onChange={handleOnSchemaChanged}
              numericPropertyOptions={numericPropertyOptions}
            />
          </ContentContainer>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
