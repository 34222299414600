import { BaseSchema } from '@loadsys-cmms/app-sdk'
import {
  EventSeverityPicker,
  EventSeverityPickerProps
} from './EventSeverityPicker'

export interface SchemaEventSeverityPickerProps
  extends Omit<EventSeverityPickerProps, 'onChange' | 'value'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaEventSeverityPicker: React.FC<
  SchemaEventSeverityPickerProps
> = ({ schema, field, onChange, helperText, ...props }) => {
  return (
    <EventSeverityPicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: string) => onChange(value, field)}
      {...props}
    />
  )
}
