import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  AppTabProps,
  AppTabs,
  AppTabPanel,
  useSnackbarMessages,
  useRefreshComponent,
  AppDrawerActions
} from '@loadsys-cmms/ui-components'
import {
  AssetPropertyValueSchema,
  formatLocalDate
} from '@loadsys-cmms/app-sdk'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { AssetsSelectors } from '../store/store-config'
import { LocationName } from '../../locations/components/LocationName'
import { AssetClassName } from '../../asset-classes/components/AssetClassName'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetStateUpdater } from './AssetStateUpdater'
import { AssetModelsSelectors } from '../../asset-models/store/store-config'
import { AssetProperties } from './AssetProperties'
import _ from 'lodash'
import { AssetPropertiesForm } from './forms/AssetPropertiesForm'
import { usePutAssetPropertiesQuery } from '../hooks/usePutAssetPropertiesQuery'
import { AssetClassesSelectors } from '../../asset-classes/store/store-config'

interface PutAssetPropertiesDrawerProps {
  open: boolean
  onClose: () => void
}

export const PutAssetPropertiesDrawer: React.FC<
  PutAssetPropertiesDrawerProps
> = ({ open, onClose }) => {
  const { refreshComponent } = useRefreshComponent()
  const { putAssetProperties, processing } = usePutAssetPropertiesQuery()
  const { entityId } = useEntityParams()

  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const [propertyValues, setPropertyValues] = useState<
    AssetPropertyValueSchema[]
  >([])

  const schema = useAppSelector((state) =>
    AssetsSelectors.selectById(state, entityId)
  )

  const assetModel = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, schema?.assetModelId)
  )
  const assetClass = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, assetModel?.assetClassId)
  )

  const attributes = useMemo(() => {
    return assetClass?.getAttribubes()
  }, [assetClass?.id])

  const metrics = useMemo(() => {
    return assetClass?.getMetrics()
  }, [assetClass?.id])

  const handleOnChange = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnClose = useCallback(() => {
    navigate(entityId)
  }, [entityId])

  const handleOnSubmit = useCallback(() => {
    const hasValues = _.filter(propertyValues, (propertyValue) => {
      return (
        (!_.isEmpty(propertyValue.stringValue) &&
          propertyValue.stringValue !== '') ||
        !_.isUndefined(propertyValue.numberValue)
      )
    })

    for (const value of hasValues) {
      if (!value.validate()) {
        addErrorMessage('Please correct the errors and try again.')
        return
      }
    }
    if (hasValues.length) {
      putAssetProperties(schema.id, hasValues)
        .then(() => {
          addSuccessMessage('Properties updated')
          setPropertyValues([])
          navigate(entityId)
        })
        .catch(() => {
          addErrorMessage('Failed to post the asset properties.')
        })
    }
    refreshComponent()
  }, [schema, propertyValues])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          {schema?.name} - Properties
        </Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <>
            {!_.isEmpty(attributes) && (
              <>
                <Typography variant={'subtitle2'}>Attributes</Typography>
                <ContentContainer>
                  <AssetPropertiesForm
                    type={'attributes'}
                    schema={schema}
                    propertyValues={propertyValues}
                    onChange={handleOnChange}
                  />
                </ContentContainer>
              </>
            )}
            {!_.isEmpty(metrics) && (
              <>
                <Typography variant={'subtitle2'}>Metrics</Typography>
                <ContentContainer>
                  <AssetPropertiesForm
                    type={'metrics'}
                    schema={schema}
                    propertyValues={propertyValues}
                    onChange={handleOnChange}
                  />
                </ContentContainer>
              </>
            )}
          </>
        )}
      </AppDrawerContent>
      {schema && (
        <AppDrawerActions>
          <Button
            disabled={processing}
            color={'primary'}
            onClick={handleOnSubmit}
          >
            Save
          </Button>
        </AppDrawerActions>
      )}
    </AppDrawer>
  )
}
