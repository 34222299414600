import { AssetClassFailureModeSchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import { Grid } from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import { getAssetFailureCauseSelector } from '../../../asset-failure-causes/selectors/selectors'
import { SchemaAssetFailureModeAndCausePicker } from '../../../asset-failure-modes/components/pickers/SchemaAssetFailureModeAndCausePicker'
import {
  getAssetFailureModeCauseSelector,
  getAssetFailureModeSelector
} from '../../../asset-failure-modes/selectors/selectors'
import { SchemaMaintenancePicker } from '../../../maintenance-actions/components/pickers/SchemaMaintenanceActionPicker'

interface AssetClassFailureModeFormProps {
  schema: AssetClassFailureModeSchema
  onChange: (schema: AssetClassFailureModeSchema) => void
}

export const AssetClassFailureModeForm: React.FC<
  AssetClassFailureModeFormProps
> = ({ schema, onChange = () => {} }) => {
  const getAssetFailureMode = useAppSelector(getAssetFailureModeSelector)
  const getAssetFailureCause = useAppSelector(getAssetFailureCauseSelector)
  const getAssetFailureModeCause = useAppSelector(
    getAssetFailureModeCauseSelector
  )
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnModeCauseBlur = useCallback(() => {
    if (schema?.assetFailureModeId && schema?.assetFailureCauseId) {
      const mode = getAssetFailureMode(schema?.assetFailureModeId)
      const cause = getAssetFailureCause(schema?.assetFailureCauseId)
      const failureModeCause = getAssetFailureModeCause(
        schema?.assetFailureModeId,
        schema?.assetFailureCauseId
      )
      if (mode && cause && _.isEmpty(schema.code)) {
        schema.code = `${mode?.code}-${cause?.code}`
        onChange(schema)
      }
      /*if (failureModeCause) {
        if (_.isEmpty(schema.localEffect)) {
          schema.localEffect = failureModeCause.localEffect
        }
        if (_.isEmpty(schema.systemEffect)) {
          schema.systemEffect = failureModeCause.systemEffect
        }
        onChange(schema)
      }*/
    }
  }, [getAssetFailureModeCause, schema, onChange])

  if (!schema) {
    return null
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaAssetFailureModeAndCausePicker
          schema={schema}
          onFailureCauseChange={handleOnFieldChange}
          onFailureModeChange={handleOnFieldChange}
          onBlur={handleOnModeCauseBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          margin='none'
          label={'Code'}
          placeholder={'Code'}
          onChange={handleOnFieldChange}
          schema={schema}
          field={'code'}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          margin='none'
          label={'Description'}
          placeholder={'Description'}
          onChange={handleOnFieldChange}
          schema={schema}
          field={'description'}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaMaintenancePicker
          margin='none'
          label={'Maintenance Action'}
          placeholder={'Maintenance Action'}
          onChange={handleOnFieldChange}
          schema={schema}
          field={'maintenanceActionId'}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          margin='none'
          label={'Maintenance Task'}
          placeholder={'Maintenance Task'}
          onChange={handleOnFieldChange}
          schema={schema}
          field={'maintenanceTask'}
        />
      </Grid>
    </Grid>
  )
}
