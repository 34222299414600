import { AssetModelType } from '@loadsys-cmms/app-sdk'
import { AppTabProps, AppTabs, AppTabPanel } from '@loadsys-cmms/ui-components'
import { Alert, Typography } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AWSAssetModelMeasurements } from '../../aws-asset-models/components/AWSAssetModelMeasurements'
import { AssetClassModel } from '../models/AssetClassModel'
import { AssetClassAttributes } from './AssetClassAttributes'
import { AssetClassHierarchies } from './AssetClassHierarchies'
import { AssetClassMetrics } from './AssetClassMetrics'
import { AssetClassForm } from './forms/AssetClassForm'
import { AssetClassTransforms } from './AssetClassTransforms'

const tabs: AppTabProps[] = [
  { value: 'details', label: 'Details' },
  //{ value: 'fmea', label: 'FMEA' },
  { value: 'attributes', label: 'Attributes' },
  //{ value: 'measurements', label: 'Measurements' },
  { value: 'transforms', label: 'Transforms' },
  { value: 'metrics', label: 'Metrics' }
  //{ value: 'events', label: 'Events' }
  // { value: 'items', route: `${routePrefix}/items`, label: 'Shipment Items' },
]

const tabsByType: Record<AssetModelType, string[]> = {
  [AssetModelType.System]: ['details', /*'fmea',*/ 'attributes', 'metrics'],
  [AssetModelType.Asset]: [
    'details',
    //'fmea',
    'attributes',
    'measurements',
    'transforms',
    'metrics'
  ],
  [AssetModelType.Component]: [
    'details',
    //'fmea',
    'attributes',
    'measurements',
    'transforms',
    'metrics'
  ]
}

export interface EditAssetClassProps {
  schema: AssetClassModel
  onChange: (schema: AssetClassModel) => void
}

export const EditAssetClass: React.FC<EditAssetClassProps> = ({
  schema,
  onChange
}) => {
  const handleOnSchemaChanged = useCallback(() => {
    onChange(schema)
  }, [onChange, schema])

  const typeTabs = useMemo(() => {
    return _.filter(tabs, (tab) =>
      _.includes(tabsByType[schema?.type] || ['details', 'fmea'], tab.value)
    )
  }, [schema?.type])

  return (
    <AppTabs tabs={typeTabs} selected={'details'} hidden>
      <AppTabPanel value={'details'}>
        <ContentContainer>
          <AssetClassForm schema={schema} onChange={handleOnSchemaChanged} />
        </ContentContainer>
        <Typography variant={'subtitle2'}>
          Subassets and Subcomponents
        </Typography>
        {_.includes(
          [AssetModelType.System, AssetModelType.Asset],
          schema?.type
        ) && (
          <ContentContainer>
            <AssetClassHierarchies
              schema={schema}
              enableEdit
              onChange={handleOnSchemaChanged}
            />
          </ContentContainer>
        )}
        {_.includes([AssetModelType.Component], schema?.type) && (
          <ContentContainer>
            <Alert severity={'info'}>
              Components are the lowest asset organizational unit and cannot
              have child assets or components.
            </Alert>
          </ContentContainer>
        )}
      </AppTabPanel>
      {/*<AppTabPanel value={'fmea'}>
        <ContentContainer>
          <AssetClassFailureModes
            schema={schema}
            onChange={handleOnSchemaChanged}
            enableEdit={true}
            offset={460}
          />
        </ContentContainer>
        </AppTabPanel>*/}
      <AppTabPanel value={'attributes'}>
        <ContentContainer>
          <AssetClassAttributes
            offset={290}
            schema={schema}
            onChange={handleOnSchemaChanged}
            enableEdit
          />
          {/*<AWSAssetModelAttributes
            schema={schema.modelSchema}
            onChange={handleOnSchemaChanged}
            enableEdit
        />*/}
        </ContentContainer>
      </AppTabPanel>
      <AppTabPanel value={'measurements'}>
        <ContentContainer>
          <AWSAssetModelMeasurements
            schema={schema.modelSchema}
            onChange={handleOnSchemaChanged}
            enableEdit
          />
        </ContentContainer>
      </AppTabPanel>
      <AppTabPanel value={'transforms'}>
        <ContentContainer>
          <AssetClassTransforms
            offset={290}
            schema={schema}
            onChange={handleOnSchemaChanged}
            enableEdit
          />
          {/*<AWSAssetModelTransforms
            schema={schema.modelSchema}
            onChange={handleOnSchemaChanged}
            enableEdit
      />*/}
        </ContentContainer>
      </AppTabPanel>
      <AppTabPanel value={'metrics'}>
        <ContentContainer>
          <AssetClassMetrics
            offset={290}
            schema={schema}
            onChange={handleOnSchemaChanged}
            enableEdit
          />
          {/*<AWSAssetModelMetrics
            schema={schema.modelSchema}
            onChange={handleOnSchemaChanged}
            enableEdit
        />*/}
        </ContentContainer>
      </AppTabPanel>
    </AppTabs>
  )
}
