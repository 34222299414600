import { AssetClassFailureModeSchema } from '@loadsys-cmms/app-sdk'
import { Button } from '@mui/material'
import { useState } from 'react'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'
import { AddAssetClassFailureModeDrawer } from './AddAssetClassFailureModeDrawer'

export interface AddAssetClassFailureModeButtonProps {
  schema: AssetClassModel
  onChange: (schema: AssetClassModel) => void
}

export const AddAssetClassFailureModeButton: React.FC<
  AddAssetClassFailureModeButtonProps
> = ({ schema, onChange }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add</Button>
      <AddAssetClassFailureModeDrawer
        open={open}
        assetClass={schema}
        onClose={() => setOpen(false)}
        onChange={onChange}
      />
    </>
  )
}
