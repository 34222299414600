import { IsString, IsNotEmpty, IsOptional, ValidateNested, IsNumber, IsPositive, IsArray } from 'class-validator';
import { Type, Expose, Transform } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import _ from 'lodash';
import { AWSAssetModelSchema } from './AWSAssetModelSchema';
import { AssetModelHierarchySchema } from './AssetModelHierarchySchema';
import { AWSResourceSyncStatus } from './types';


export class AssetModelSchema extends BaseSchema {
  /**
   * Initialize Measurement
   *
   * @param data AssetModelSchema
   */
  constructor(data?: Partial<AssetModelSchema>) {
    super()

    this.awsModelSchema = new AWSAssetModelSchema()
    this.isEnabled = true
    this.hierarchies = []

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsNumber()
  @IsPositive()
  @IsOptional()
  assetClassVersion: number;

  @Expose()
  @IsNotEmpty()
  @IsString()
  assetClassId: string;

  @Expose()
  @IsOptional()
  @IsString()
  parentAssetModelId: string;

  @Expose()
  @IsOptional()
  @IsString()
  awsAssetModelId: string;

  @Expose()
  @IsOptional()
  @Transform(params => {
    if (_.isEmpty(params.value)) {
      return undefined
    }
    return params.value
  })
  awsSyncStatus: AWSResourceSyncStatus;

  @Type(() => AWSAssetModelSchema)
  @Expose()
  @IsOptional()
  @ValidateNested()
  awsModelSchema: AWSAssetModelSchema;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  code: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  name: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  description: string;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  isEnabled: boolean = true;

  @Expose()
  @Type(() => AssetModelHierarchySchema)
  @IsOptional()
  @IsArray()
  @ValidateNested()
  @Transform(params => {
    if (!_.isArray(params.value)) {
      return []
    }
    return params.value
  }, { toClassOnly: true })
  hierarchies: AssetModelHierarchySchema[]

  @Expose()
  @Type(() => Date)
  @IsOptional()
  created: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  updated: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  deleted: Date;

  public getModelSchemaAttributes () {
    return _.filter(this.awsModelSchema?.assetModelProperties, property => !!property.type?.attribute)
  }

  public getModelSchemaTransforms () {
    return _.filter(this.awsModelSchema?.assetModelProperties, property => !!property.type?.transform)
  }

  public getModelSchemaMeasurements () {
    return _.filter(this.awsModelSchema?.assetModelProperties, property => !!property.type?.measurement)
  }

  public getModelSchemaMetrics () {
    return _.filter(this.awsModelSchema?.assetModelProperties, property => !!property.type?.metric)
  }

  public getAssetModelPropertyByName (name: string) {
    return _.find(this.awsModelSchema?.assetModelProperties, property => property.name === name)
  }

}
