import { AssetPropertyDataType } from '@loadsys-cmms/app-sdk'
import {
  SchemaSwitchInput,
  SchemaTextInput,
  SchemaTextInputProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'

export interface SchemaAssetPropertyValueInputProps
  extends Omit<SchemaTextInputProps, 'disabled' | 'inputProps' | 'field'> {
  propertyDataType?: AssetPropertyDataType
  useTypePath?: boolean
}

export const SchemaAssetPropertyValueInput: React.FC<
  SchemaAssetPropertyValueInputProps
> = ({ propertyDataType, onChange, schema, ...props }) => {
  const variantProperty = useMemo(() => {
    switch (propertyDataType) {
      case AssetPropertyDataType.String:
        return 'stringValue'
      case AssetPropertyDataType.Number:
        return 'numberValue'
      default:
        return '_'
    }
  }, [propertyDataType])

  const isBoolean = useMemo(() => {
    return false
  }, [])

  const isNumeric = useMemo(() => {
    return _.includes([AssetPropertyDataType.Number], propertyDataType)
  }, [propertyDataType])

  const fieldPath = useMemo(() => {
    return `${variantProperty}`
  }, [variantProperty])

  const handleOnChange = useCallback(
    (value: string | boolean) => {
      const propertyValue = isNumeric ? Number(value) : value
      _.set(schema, variantProperty, propertyValue)
      onChange(propertyValue, variantProperty)
    },
    [onChange, variantProperty, isNumeric]
  )

  return (
    <>
      {isBoolean ? (
        <SchemaSwitchInput
          schema={schema}
          field={fieldPath}
          disabled={!schema && !propertyDataType}
          onChange={handleOnChange}
          {...props}
        />
      ) : (
        <SchemaTextInput
          inputProps={{
            type: isNumeric ? 'number' : undefined
          }}
          schema={schema}
          field={fieldPath}
          disabled={!schema || !propertyDataType}
          onChange={handleOnChange}
          {...props}
        />
      )}
    </>
  )
}
