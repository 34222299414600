//import { MetricWindow } from "@aws-sdk/client-iotsitewise";
import { Expose, Type } from "class-transformer";
import { IsOptional, ValidateNested } from "class-validator";
import { BaseSchema } from "../../BaseSchema";
import { TumblingWindowSchema } from "./TumblingWindowSchema";

export class MetricWindowSchema extends BaseSchema /* implements MetricWindow */ {
  @Expose()
  @Type(() => TumblingWindowSchema)
  @IsOptional()
  @ValidateNested()
  tumbling?: TumblingWindowSchema = new TumblingWindowSchema();
}