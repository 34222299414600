import { useAppSelector } from '../../../store/useAppSelector'
import { LocationsSelectors } from '../store/store-config'

export interface LocationNameProps {
  LocationId: string
}

export const LocationName: React.FC<LocationNameProps> = ({ LocationId }) => {
  const Location = useAppSelector((state) =>
    LocationsSelectors.selectById(state, LocationId)
  )
  return <>{Location?.name}</>
}
