import { MaintenanceActionSchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { useCallback } from 'react'
import { SchemaMaintenanceActionWordPicker } from '../pickers/SchemaMaintenanceActionWordPicker'

interface MaintenanceActionFormProps {
  schema: MaintenanceActionSchema
  onChange: (schema: MaintenanceActionSchema) => void
}

export const MaintenanceActionForm: React.FC<MaintenanceActionFormProps> = ({
  schema,
  onChange = () => {}
}) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleNumericFieldConversion = useCallback(() => {
    if (!_.isUndefined(schema.pfIndex)) {
      schema.pfIndex = Number(schema.pfIndex)
    }
    if (!_.isUndefined(schema.baseFrequency)) {
      schema.baseFrequency = Number(schema.baseFrequency)
    }
    onChange(schema)
  }, [schema, onChange])

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaTextInput
        label={'Action'}
        placeholder={'Action'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'action'}
        required
      />
      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
        required
      />
      <SchemaMaintenanceActionWordPicker
        label={'Word'}
        placeholder={'Word'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'word'}
        required
      />
      <SchemaTextInput
        type={'number'}
        label={'P-F Index'}
        placeholder={'P-F Index'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'pfIndex'}
        helperText={'Likelihood of occurance. (1-6)'}
        inputProps={{
          onBlur: handleNumericFieldConversion
        }}
      />
      <SchemaTextInput
        type={'number'}
        label={'Base Frequency'}
        placeholder={'Base Frequency'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'baseFrequency'}
        helperText={'Typical period in days between the maintenance actions.'}
        inputProps={{
          onBlur: handleNumericFieldConversion
        }}
      />
    </>
  )
}
