import {
  AssetPropertySchema,
  AssetPropertyValueSchema,
  AssetSchema,
  formatLocalDateTime
} from '@loadsys-cmms/app-sdk'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import _ from 'lodash'
import React, { useCallback, useContext, useMemo } from 'react'
import { useAppSelector } from '../../../store/useAppSelector'
import { secondsToHours } from '../../../utils/conversions'
import { AssetClassesSelectors } from '../../asset-classes/store/store-config'
import { AssetModelsSelectors } from '../../asset-models/store/store-config'
import { AssetPropertyValuesContext } from './AssetPropertyValuesProvider'

const PropertiesInSeconds = [
  'Uptime',
  'Downtime',
  'Maintenance_Time',
  'Failure_Time'
]

export interface AssetPropertiesByTypeProps {
  schema: AssetSchema
  type: 'attributes' | 'measurements' | 'transforms' | 'metrics'
}

export const AssetPropertiesByType: React.FC<AssetPropertiesByTypeProps> = ({
  schema,
  type
}) => {
  const { values: propertyValues } = useContext(AssetPropertyValuesContext)
  const assetModel = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, schema?.assetModelId)
  )

  const assetClass = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, assetModel?.assetClassId)
  )

  const properties = useMemo(() => {
    switch (type) {
      case 'attributes':
        return assetClass?.getAttribubes()
      case 'transforms':
        return assetClass?.getTransforms()
      case 'metrics':
        return assetClass?.getMetrics()
    }
    return []
  }, [type, assetModel])

  const getAssetProperty = useCallback(
    (assetClassPropertyId: string) => {
      return _.find(
        schema?.properties,
        (property) => property.assetClassPropertyId === assetClassPropertyId
      )
    },
    [propertyValues]
  )

  const getPropertyValue = useCallback(
    (propertyId: string) => {
      return _.find(
        propertyValues,
        (value) => value.assetPropertyId === propertyId
      )
    },
    [propertyValues]
  )

  const formatPropertyValue = useCallback(
    (
      assetPropertyValue: AssetPropertyValueSchema,
      assetProperty: AssetPropertySchema
    ) => {
      if (!assetPropertyValue) {
        return null
      }
      const value: string | number = !_.isUndefined(
        assetPropertyValue?.numberValue
      )
        ? Number(assetPropertyValue?.numberValue)
        : assetPropertyValue?.stringValue

      if (_.isString(value)) {
        return value
      }

      if (_.includes(PropertiesInSeconds, assetProperty.code)) {
        return secondsToHours(value)
      }
      return value
    },
    []
  )

  return (
    <Table>
      <TableBody>
        {_.map(properties, (property) => {
          const assetProperty = getAssetProperty(property.id)
          const value = getPropertyValue(assetProperty?.id)

          return (
            <TableRow key={property.id}>
              <TableCell>
                <Typography variant={'body2'} fontWeight={'bold'}>
                  {property.name}
                </Typography>

                <Typography variant={'subtitle2'}>
                  {formatLocalDateTime(value?.timestamp)}
                </Typography>
              </TableCell>
              <TableCell>
                {_.includes(PropertiesInSeconds, property.code) ? (
                  <>
                    {formatPropertyValue(value, assetProperty) || '-'}
                    <Typography variant={'subtitle2'}>Hours</Typography>
                  </>
                ) : (
                  <>{formatPropertyValue(value, assetProperty) || '-'}</>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
