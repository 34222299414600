import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { useCallback } from 'react'
import { AssetModelPicker, AssetModelPickerProps } from './AssetModelPicker'

export interface SchemaAssetModelPickerProps
  extends Omit<AssetModelPickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaAssetModelPicker: React.FC<SchemaAssetModelPickerProps> = ({
  schema,
  field,
  helperText,
  onChange,
  ...props
}) => {
  const handleOnChange = useCallback(
    (value: string, field: string) => {
      if (schema) {
        schema.setValue(field, value)
        onChange(value, field)
      }
    },
    [onChange, schema]
  )
  return (
    <AssetModelPicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value) => handleOnChange(value, field)}
      {...props}
    />
  )
}
