//import { ComputeLocation } from "@aws-sdk/client-iotsitewise";
import { Expose, Type } from "class-transformer";
import { IsEnum, IsObject, IsOptional, ValidateNested } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../../BaseSchema";
import { ForwardingConfigSchema } from "./ForwardingConfigSchema";
import { Assets } from '../types'

export class TransformProcessingConfigSchema extends BaseSchema {
  /**
   * Initialize
   *
   * @param data
   */
  constructor(data?: TransformProcessingConfigSchema) {
    super()

    this.computeLocation = Assets.ComputeLocation.CLOUD
    //this.forwardingConfig = new ForwardingConfigSchema()

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsEnum(Assets.ComputeLocation)
  @IsOptional()
  computeLocation: Assets.ComputeLocation;

  @Expose()
  @Type(() => ForwardingConfigSchema)
  @IsOptional()
  @IsObject()
  @ValidateNested()
  forwardingConfig?: ForwardingConfigSchema;
}