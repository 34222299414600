import React, { useCallback } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { useNavigate } from 'react-router-dom'
import { useAddAssetClassQuery } from '../hooks/useAddAssetClassQuery'
import _ from 'lodash'
import { EditAssetClass } from './EditAssetClass'
import { AssetClassModel } from '../models/AssetClassModel'

interface AddAssetClassDrawerProps {
  open: boolean
  onClose: (schema?: AssetClassModel) => void
  defaultData?: Partial<AssetClassModel>
}

export const AddAssetClassDrawer: React.FC<AddAssetClassDrawerProps> = ({
  open,
  onClose,
  defaultData
}) => {
  const { addAssetClass, processing } = useAddAssetClassQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState(new AssetClassModel(defaultData))

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addAssetClass(schema)
        .then((result) => {
          setSchema(new AssetClassModel(defaultData))
          addSuccessMessage('Asset Class created.')
          onClose()
          navigate(result.id)
        })
        .catch(() => {
          addErrorMessage('Failed to create the Asset Class.')
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/classes')
    onClose()
  }, [])

  const handleOnReset = useCallback(() => {
    setSchema(new AssetClassModel(defaultData))
  }, [defaultData])

  return (
    <AppDrawer
      minWidth={400}
      width={'50vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Add Asset Class
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <EditAssetClass schema={schema} onChange={handleOnSchemaChanged} />
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
