import {
  AssetClassPropertySchema,
  AssetPropertyValueSchema,
  Assets,
  AssetSchema,
  formatLocalDateTime
} from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import { AssetClassesSelectors } from '../../../asset-classes/store/store-config'
import { AssetModelsSelectors } from '../../../asset-models/store/store-config'
import { SchemaAssetPropertyValueInput } from '../inputs/SchemaAssetPropertyValueInput'

export interface AssetPropertiesByTypeProps {
  schema: AssetSchema
  type: 'attributes' | 'metrics'
  propertyValues: AssetPropertyValueSchema[]
  onChange: () => void
}

export const AssetPropertiesForm: React.FC<AssetPropertiesByTypeProps> = ({
  schema,
  type,
  propertyValues,
  onChange = () => {}
}) => {
  const assetClassModel = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, schema?.assetModelId)
  )

  const assetClass = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, assetClassModel?.assetClassId)
  )

  const properties = useMemo(() => {
    switch (type) {
      case 'attributes':
        return _.filter(
          assetClass?.getAttribubes(),
          (property) => property.name !== 'state'
        )
      case 'metrics':
        return _.filter(
          assetClass?.getMetrics(),
          (property) => property.name !== 'state'
        )
      default:
        return []
    }
  }, [type, assetClass?.id])

  const getPropertyValue = useCallback(
    (property: AssetClassPropertySchema) => {
      const alias = `/asset/${schema?.id}/property/${property.code}`
      const assetProperty = _.find(
        schema?.properties,
        (value) => value.assetClassPropertyId === property.id
      )

      if (!assetProperty) {
        return null
      }

      let propertyValue = _.find(
        propertyValues,
        (value) =>
          value.assetPropertyId ===
          assetProperty.id /*|| value.alias === alias*/
      )
      if (propertyValue) {
        return propertyValue
      }

      propertyValue = new AssetPropertyValueSchema()
      propertyValue.assetPropertyId = assetProperty?.id
      propertyValue.alias = alias
      propertyValue.timestamp = new Date()
      propertyValues.push(propertyValue)

      return propertyValue
    },
    [propertyValues, schema]
  )

  return (
    <>
      {_.map(properties, (assetClassProperty) => {
        const value = getPropertyValue(assetClassProperty)
        if (!value) {
          return null
        }
        return (
          <SchemaAssetPropertyValueInput
            key={assetClassProperty.id}
            label={assetClassProperty.name}
            placeholder={assetClassProperty.name}
            propertyDataType={assetClassProperty.dataType}
            schema={value}
            onChange={onChange}
          />
        )
      })}
    </>
  )
}
