import { IsEmail, IsNotEmpty } from 'class-validator'
import _ from 'lodash'

import { BaseSchema } from '../BaseSchema'
import { IsPassword } from '../../validators/decorators/IsPasswordDecorator'

export class SignupSchema extends BaseSchema {
  /**
   * First Name
   */
  @IsNotEmpty()
  firstName: string = null

  /**
   * Last Name
   */
  @IsNotEmpty()
  lastName: string = null

  /**
   * Email
   */
  @IsNotEmpty()
  @IsEmail()
  email: string = null

  /**
   * Password
   */
  @IsNotEmpty()
  @IsPassword()
  password: string = null

  /**
   * Construct
   * @param data
   */
  constructor(data?: Partial<SignupSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Get full name for a user
   * @returns string Full name
   */
  getFullName () {
    return `${this.firstName} ${this.lastName}`
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.lastName}, ${this.firstName}`
  }
}