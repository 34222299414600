import { AssetPropertyValueSchema, AssetSchema } from '@loadsys-cmms/app-sdk'
import { Box, Button, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetPropertiesByType } from './AssetPropertiesByType'
import { AssetPropertyValuesProvider } from './AssetPropertyValuesProvider'

export interface AssetPropertiesProps {
  schema: AssetSchema
}

export const AssetProperties: React.FC<AssetPropertiesProps> = ({ schema }) => {
  const navigate = useNavigate()
  const [propertyValues, setPropertyValues] =
    useState<AssetPropertyValueSchema[]>()

  const [providerInterface, setProviderInterface] = useState<any>()

  const handleOnOpenProperties = useCallback(() => {
    navigate(`${schema.id}/properties`)
  }, [schema?.id])

  const handleOnReload = useCallback(() => {
    providerInterface?.reload()
  }, [providerInterface])

  if (!schema?.id) {
    return null
  }

  return (
    <AssetPropertyValuesProvider
      assetId={schema?.id}
      onInit={setProviderInterface}
    >
      <Box display={'flex'} alignItems={'flex-end'}>
        <Typography variant='subtitle2' flex={1}>
          Attributes
        </Typography>
        <Button
          disabled={!providerInterface?.reload}
          size={'small'}
          onClick={handleOnReload}
        >
          Reload
        </Button>
        <Button size={'small'} onClick={handleOnOpenProperties}>
          Set Properties
        </Button>
      </Box>
      <ContentContainer>
        <AssetPropertiesByType schema={schema} type={'attributes'} />
      </ContentContainer>
      <Typography variant='subtitle2'>Metrics</Typography>
      <ContentContainer>
        <AssetPropertiesByType schema={schema} type={'metrics'} />
      </ContentContainer>
      <Typography variant='subtitle2'>Transforms</Typography>
      <ContentContainer>
        <AssetPropertiesByType schema={schema} type={'transforms'} />
      </ContentContainer>
    </AssetPropertyValuesProvider>
  )
}
