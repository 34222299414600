import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { AssetClassFailureModeSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { AssetClassFailureModeForm } from './forms/AssetClassFailureModeForm'
import { useNavigate } from 'react-router-dom'
import { useUpdateAssetClassFailureModeQuery } from '../hooks/useUpdateAssetClassFailureModeQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { useAppSelector } from '../../../store/useAppSelector'
import hash_object from 'hash-object'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'

interface EditAssetClassFailureModeDrawerProps {
  assetClass: AssetClassModel
  failureMode: AssetClassFailureModeSchema
  open: boolean
  onClose: (schema?: AssetClassFailureModeSchema) => void
}

export const EditAssetClassFailureModeDrawer: React.FC<
  EditAssetClassFailureModeDrawerProps
> = ({ open, onClose, failureMode, assetClass }) => {
  const { updateAssetClassFailureMode, processing, errorMessage } =
    useUpdateAssetClassFailureModeQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetClassFailureModeSchema>()

  useEffect(() => {
    if (
      open &&
      failureMode?.id &&
      failureMode instanceof AssetClassFailureModeSchema
    ) {
      setSchema(failureMode.clone())
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateAssetClassFailureMode(schema)
        .then((result) => {
          addSuccessMessage('Failure Mode udpated.')
          onClose(result)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to update the Failure Mode. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    onClose()
  }, [])

  const handleOnReset = useCallback(() => {
    setSchema(failureMode)
  }, [failureMode])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Edit Event Trigger
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <ContentContainer>
            <AssetClassFailureModeForm
              schema={schema}
              onChange={handleOnSchemaChanged}
            />
          </ContentContainer>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
