import { AssetPropertyDataType } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo } from 'react'

const OPTIONS: InputOptionProps[] = [
  {
    label: 'String',
    value: AssetPropertyDataType.String
  },
  {
    label: 'Number',
    value: AssetPropertyDataType.Number
  }
]

export interface AssetPropertyDataTypePickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const AssetPropertyDataTypePicker: React.FC<
  AssetPropertyDataTypePickerProps
> = ({
  label = 'Data Type',
  placeholder = 'Data Type',
  onChange = () => {},
  ...props
}) => {
  const filteredOptions = useMemo(() => {
    return _.map(OPTIONS)
  }, [OPTIONS])

  const options: InputOptionProps[] = useMemo(() => {
    return OPTIONS
  }, [filteredOptions])

  return (
    <>
      <AutocompleteInput
        options={options}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </>
  )
}
