import { BaseSchema } from '@loadsys-cmms/app-sdk'
import {
  AssetMetricAggregationPeriodPicker,
  AssetMetricAggregationPeriodPickerProps
} from './AssetMetricAggregationPeriodPicker'

export interface SchemaAssetMetricAggregationPeriodPickerProps
  extends Omit<AssetMetricAggregationPeriodPickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaAssetMetricAggregationPeriodPicker: React.FC<
  SchemaAssetMetricAggregationPeriodPickerProps
> = ({ schema, field, helperText, onChange, ...props }) => {
  return (
    <AssetMetricAggregationPeriodPicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: string) => onChange(value, field)}
      {...props}
    />
  )
}
