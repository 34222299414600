import { AppSnackbarProvider, AuthProvider } from '@loadsys-cmms/ui-components'
import { AppRouter } from './AppRouter'

export const AppLayout: React.FC = () => {
  return (
    <AppSnackbarProvider>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </AppSnackbarProvider>
  )
}
