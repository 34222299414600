import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { AssetModelSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  AppTabPanel,
  AppTabProps,
  AppTabs,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { AssetModelForm } from './forms/AssetModelForm'
import { useNavigate } from 'react-router-dom'
import { useUpdateAssetModelQuery } from '../hooks/useUpdateAssetModelQuery'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetModelsSelectors } from '../store/store-config'
import { AssetModelHiearchies } from './AssetModelChildren'

const tabs: AppTabProps[] = [
  { value: 'details', label: 'Details' },
  { value: 'fmea', label: 'FMEA' },
  { value: 'children', label: 'Systems/Components' },
  { value: 'measurements', label: 'Measurements' },
  { value: 'transforms', label: 'Transforms' },
  { value: 'metrics', label: 'Metrics' }
  // { value: 'items', route: `${routePrefix}/items`, label: 'Shipment Items' },
]

interface EditAssetModelDrawerProps {
  open: boolean
  onClose: (schema?: AssetModelSchema) => void
  defaultData?: Partial<AssetModelSchema>
}

export const EditAssetModelDrawer: React.FC<EditAssetModelDrawerProps> = ({
  open,
  onClose,
  defaultData
}) => {
  const { updateAssetModel, processing, errorMessage } =
    useUpdateAssetModelQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetModelSchema>()

  const { entityId } = useEntityParams()

  const currentSchema = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, entityId)
  )

  useEffect(() => {
    if (
      open &&
      currentSchema?.id &&
      currentSchema instanceof AssetModelSchema
    ) {
      setSchema(currentSchema.clone())
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateAssetModel(schema)
        .then((result) => {
          setSchema(new AssetModelSchema(defaultData))
          addSuccessMessage('AssetMode created.')
          onClose()
          navigate(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to update the Asset. ' + err?.response?.data?.errorMessage
          )
          schema.setErrors(err?.response?.data?.errors)
          refreshComponent()
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate(entityId)
    onClose()
  }, [entityId])

  const handleOnReset = useCallback(() => {
    setSchema(currentSchema)
  }, [currentSchema])

  return (
    <AppDrawer
      minWidth={400}
      width={'50vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Edit Model
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <form autoComplete='off'>
            <ContentContainer>
              <AssetModelForm
                schema={schema}
                onChange={handleOnSchemaChanged}
              />
            </ContentContainer>
            <Typography variant={'subtitle2'}>Systems/Components</Typography>
            <ContentContainer>
              <AssetModelHiearchies
                schema={schema}
                onChange={handleOnSchemaChanged}
                enableEdit={true}
              />
            </ContentContainer>
            {/*<AppTabPanel value={'fmea'}>
                <ContentContainer>
                  <AssetClassFailureModes
                    schema={schema}
                    onChange={handleOnSchemaChanged}
                    enableEdit={true}
                    offset={435}
                  />
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'children'}>
                <ContentContainer>
                  <AssetModelChildren
                    schema={schema}
                    onChange={handleOnSchemaChanged}
                    enableEdit={true}
                  />
                </ContentContainer>
              </AppTabPanel>
              {/*<AppTabPanel value={'measurements'}>
                <ContentContainer>
                  <SystemAssetModelMeasurements
                    schema={schema.awsModelSchema}
                    onChange={handleOnSchemaChanged}
                    enableEdit
                  />
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'transforms'}>
                <ContentContainer>
                  <SystemAssetModelTransforms
                    schema={schema.awsModelSchema}
                    onChange={handleOnSchemaChanged}
                    enableEdit
                  />
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'metrics'}>
                <ContentContainer>
                  <SystemAssetModelMetrics
                    schema={schema.awsModelSchema}
                    onChange={handleOnSchemaChanged}
                    enableEdit
                  />
                </ContentContainer>
        </AppTabPanel>*/}
          </form>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
