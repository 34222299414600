import { IsString, IsNotEmpty, IsOptional } from 'class-validator';
import { Type, Expose, Transform } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import { AssetFailureModeCauseSchema } from './AssetFailureModeCauseSchema';
import _ from 'lodash';

export class AssetFailureModeSchema extends BaseSchema {
  @IsOptional()
  @IsString()
  id: string;


  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  code: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  name: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  description: string;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  isEnabled: boolean = true;

  @Type(() => Boolean)
  @IsNotEmpty()
  isTemplate: boolean = false;

  @Expose()
  @Type(() => AssetFailureModeCauseSchema)
  @IsOptional()
  @Transform(params => {
    if (!_.isArray(params.value)) {
      return []
    }
    return params.value
  }, { toClassOnly: true })
  failureCauses: AssetFailureModeCauseSchema[]
}
