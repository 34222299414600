import { createAsyncThunk } from '@reduxjs/toolkit'
import { Container } from 'typescript-ioc'
import { API } from 'aws-amplify'
import { plainToClass } from 'class-transformer'
import { Class } from 'type-fest'

export default function createFetchEntitiesThunk<S> (
  classType: Class<S>,
  storeName: string,
  apiParams: { name: string, baseUrl: string },
  getResult?: (result: any) => any,
): any {
  return createAsyncThunk<S[], void>(`${storeName}/fetchEntities`, async (args: any) => {
    let entities: S[] = []
    try {
      entities = await API.get(apiParams.name, apiParams.baseUrl, args)
      entities = getResult ? getResult(entities) : entities
    } catch (err) {
      console.error(`${storeName}/fetchEntities`, err)
    }
    return plainToClass(classType, entities)
  })
}
