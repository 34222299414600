import { AssetFailureModeCauseSchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import { SchemaAssetFailureCausePicker } from '../../../asset-failure-causes/components/pickers/SchemaAssetFailureCausePicker'

interface AssetFailureModeCauseFormProps {
  schema: AssetFailureModeCauseSchema
  onChange: (schema: AssetFailureModeCauseSchema) => void
}

export const AssetFailureModeCauseForm: React.FC<
  AssetFailureModeCauseFormProps
> = ({ schema, onChange = () => {} }) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaAssetFailureCausePicker
        label={'Failure Cause'}
        placeholder={'Failure Cause'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'assetFailureCauseId'}
      />
      <SchemaTextInput
        label={'Local Effect'}
        placeholder={'Local Effect'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'localEffect'}
      />
      <SchemaTextInput
        label={'System Effect'}
        placeholder={'System Effect'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'systemEffect'}
      />
    </>
  )
}
