import { Expose } from "class-transformer"
import { IsNotEmpty, IsOptional, IsString } from "class-validator"
import { BaseSchema } from "../../BaseSchema"

export class AWSAssetModelHierarchySchema extends BaseSchema {
  @Expose()
  @IsOptional()
  @IsString()
  id: string

  @Expose()
  @IsNotEmpty()
  childAssetModelId: string

  @Expose()
  @IsNotEmpty()
  name: string
}