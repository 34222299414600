import { AssetFailureCauseSchema } from "@loadsys-cmms/app-sdk"
import createStoreAdapter from "../../../store/createStoreAdapter"

const STORE_NAME = 'assetFailureCauses'

const adapter = createStoreAdapter<AssetFailureCauseSchema>({
  classType: AssetFailureCauseSchema,
  storeName: STORE_NAME,
  apiParams: { name: 'assetsApi', 'baseUrl': '/failure-causes' },
  selectIdFn: (entity: AssetFailureCauseSchema) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const AssetFailureCausesReducer = adapter.reducer
export const AssetFailureCausesSelectors = adapter.selectors
export const AssetFailureCausesThunks = adapter.thunks
export const AssetFailureCausesActions = adapter.actions

export default adapter
