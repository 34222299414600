import { AssetClassEventTriggerSchema } from '@loadsys-cmms/app-sdk'
import {
  InputOptionProps,
  SchemaAutocompleteInput,
  SchemaSwitchInput,
  SchemaTextInput
} from '@loadsys-cmms/ui-components'
import { Box } from '@mui/material'
import _ from 'lodash'
import { useCallback } from 'react'
import { SchemaEventTypePicker } from '../../../event-types/components/pickers/SchemaEventTypePicker'
import { SchemaAssetClassEventTriggerFrequencyPicker } from '../pickers/SchemaAssetClassEventTriggerFrequencyPicker'

interface AssetClassEventTriggerFormProps {
  schema: AssetClassEventTriggerSchema
  onChange: (schema: AssetClassEventTriggerSchema) => void
  numericPropertyOptions: InputOptionProps[]
}

export const AssetClassEventTriggerForm: React.FC<
  AssetClassEventTriggerFormProps
> = ({ schema, onChange = () => {}, numericPropertyOptions }) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnLeaveName = useCallback(() => {
    if (!_.isEmpty(schema.name) && _.isEmpty(schema.code)) {
      schema.code = _.camelCase(schema.name)
      onChange(schema)
    }
  }, [schema, onChange])

  const handleOnMetricValue = useCallback(() => {
    schema.timeInterval = Number(schema.timeInterval)
    schema.propertyMin = Number(schema.propertyMin)

    schema.propertyMax = Number(schema.propertyMax)

    schema.propertyCounter = Number(schema.propertyCounter)

    onChange(schema)
  }, [schema, onChange])

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
        inputProps={{
          onBlur: handleOnLeaveName
        }}
        required
      />

      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
        helperText={"* Can't be modified later."}
        required
      />

      <SchemaEventTypePicker
        label={'Event Type'}
        placeholder={'Event Type'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'eventTypeId'}
        required
      />

      <Box></Box>
      <SchemaSwitchInput
        label={'Enable Time Trigger'}
        schema={schema}
        field={'timeTriggerEnabled'}
        onChange={handleOnFieldChange}
      />
      {schema.timeTriggerEnabled && (
        <>
          <SchemaTextInput
            label={'Time Interval'}
            placeholder={'Time Interval'}
            onChange={handleOnFieldChange}
            schema={schema}
            field={'timeInterval'}
            type={'number'}
            required
            inputProps={{
              onBlur: handleOnMetricValue
            }}
          />
          <SchemaAssetClassEventTriggerFrequencyPicker
            label={'Frequency'}
            placeholder={'Frequency'}
            onChange={handleOnFieldChange}
            schema={schema}
            field={'timeFrequency'}
            required
          />
        </>
      )}

      <Box></Box>
      <SchemaSwitchInput
        label={'Enable Boundaries Trigger'}
        schema={schema}
        field={'propertyBoundariesTriggerEnabled'}
        onChange={handleOnFieldChange}
      />
      {schema.propertyBoundariesTriggerEnabled && (
        <>
          <SchemaAutocompleteInput
            options={numericPropertyOptions}
            label={'Property'}
            placeholder={'Property'}
            onChange={handleOnFieldChange}
            schema={schema}
            field={'propertyBoundariesTriggerAssetClassPropertyId'}
            required
          />
          <SchemaTextInput
            label={'Metric Low Boundary'}
            placeholder={'Metric Low Boundary'}
            onChange={handleOnFieldChange}
            schema={schema}
            field={'propertyMin'}
            type={'number'}
            inputProps={{
              onBlur: handleOnMetricValue
            }}
            required
          />
          <SchemaTextInput
            label={'Metric Top Boundary'}
            placeholder={'Metric Top Boundary'}
            onChange={handleOnFieldChange}
            schema={schema}
            field={'propertyMax'}
            type={'number'}
            inputProps={{
              onBlur: handleOnMetricValue
            }}
            required
          />
        </>
      )}

      <Box></Box>
      <SchemaSwitchInput
        label={'Enable Counter Based Trigger'}
        schema={schema}
        field={'propertyCounterTriggerEnabled'}
        onChange={handleOnFieldChange}
      />
      {schema.propertyCounterTriggerEnabled && (
        <>
          <SchemaAutocompleteInput
            options={numericPropertyOptions}
            label={'Property'}
            placeholder={'Property'}
            onChange={handleOnFieldChange}
            schema={schema}
            field={'propertyCounterTriggerAssetClassPropertyId'}
            required
          />
          <SchemaTextInput
            label={`Occurs every ${
              schema.propertyCounter || '...'
            } (Units/Hours)`}
            placeholder={'Occurs every ... (Units/Hours)'}
            onChange={handleOnFieldChange}
            schema={schema}
            field={'propertyCounter'}
            type={'number'}
            inputProps={{
              onBlur: handleOnMetricValue
            }}
            required
          />
        </>
      )}
    </>
  )
}
