import { createSelector } from '@reduxjs/toolkit'
import { memoize } from 'lodash'
import { AppRootState } from '../../../store/AppStore'
import { EventTypesStore } from '../store/store-config'

export const getEventTypeSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return EventTypesStore.selectors.selectById(state, id)
    }),
)

export const EventTypeSelector = createSelector(
  getEventTypeSelector,
  (getEventType) =>
    (id: string) => {
      return getEventType(id)
    },
)
