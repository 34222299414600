import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { AssetFailureModeAndCausePicker } from './AssetFailureModeAndCausePicker'

export interface SchemaAssetFailureModeAndCausePickerProps {
  schema: BaseSchema
  failureModeField?: string
  failureCauseField?: string
  onFailureModeChange?: (value: string, field: string) => void
  onFailureCauseChange?: (value: string, field: string) => void
  onBlur?: () => void
}

export const SchemaAssetFailureModeAndCausePicker: React.FC<
  SchemaAssetFailureModeAndCausePickerProps
> = ({
  schema,
  failureModeField = 'assetFailureModeId',
  failureCauseField = 'assetFailureCauseId',
  onFailureModeChange = () => {},
  onFailureCauseChange = () => {},
  onBlur = () => {},
  ...props
}) => {
  return (
    <AssetFailureModeAndCausePicker
      failureModeProps={{
        value: schema.getValue(failureModeField) || '',
        error: schema.isPropertyInvalid(failureModeField),
        helperText: schema.isPropertyInvalid(failureModeField)
          ? schema.getPropertyError(failureModeField)
          : undefined,
        onChange: (value: string) =>
          onFailureModeChange(value, failureModeField),
        margin: 'none',
        onBlur: onBlur
      }}
      failureCauseProps={{
        assetFailureModeId: schema.getValue(failureModeField),
        value: schema.getValue(failureCauseField) || '',
        error: schema.isPropertyInvalid(failureCauseField),
        helperText: schema.isPropertyInvalid(failureCauseField)
          ? schema.getPropertyError(failureCauseField)
          : undefined,
        onChange: (value: string) =>
          onFailureCauseChange(value, failureCauseField),
        margin: 'none',
        enableCreate: true,
        onBlur: onBlur
      }}
      {...props}
    />
  )
}
