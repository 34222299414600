import { BaseSchema } from "../../BaseSchema";
//import { AssetModelCompositeModelDefinition, CreateAssetModelCommandInput } from '@aws-sdk/client-iotsitewise'
import { Expose } from "class-transformer";
import { ArrayMinSize, IsArray, IsNotEmpty, IsOptional } from "class-validator";
import { AssetModelPropertySchema } from "./AssetModelPropertySchema";

export class CreateAssetModelRequestSchema extends BaseSchema /*implements CreateAssetModelCommandInput */ {
  @Expose()
  @IsNotEmpty()
  assetModelName: string;

  @Expose()
  @IsOptional()
  assetModelDescription?: string;

  /*@Expose()
  @IsOptional()
  assetModelCompositeModels?: AssetModelCompositeModelDefinition[] = [];
  */

  @Expose()
  @IsNotEmpty()
  @IsArray()
  @ArrayMinSize(1)
  assetModelProperties?: AssetModelPropertySchema[] = [];
}