import { CognitoUser } from '@aws-amplify/auth'
import { Auth, Hub } from 'aws-amplify'
import React, { useCallback } from 'react'

const AuthContext = React.createContext<{
  user: CognitoUser
  authenticated: boolean
  setUser: (user: CognitoUser) => void
}>({
  authenticated: false,
  user: null,
  setUser: () => {}
})

export interface AuthProviderProps {
  authenticated?: boolean
  user?: CognitoUser
}

const initialState: AuthProviderProps = {
  authenticated: false
}

export const AuthProvider: React.FC = ({ children }) => {
  const [{ user, authenticated }, setState] = React.useState(initialState)
  const [loading, setLoading] = React.useState(false)

  const isLoading = React.useRef(false)

  const handleSetUser = useCallback((user: CognitoUser) => {
    console.log(user)
    return setState({
      user,
      authenticated: user?.getUsername() ? true : false
    })
  }, [])

  React.useEffect(() => {
    if (isLoading.current || loading) {
      return
    }
    isLoading.current = true
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log('user', user)
        setState({
          user: user,
          authenticated: true
        })
      })
      .catch((err) => {
        console.log('auth error', err)
        setState({
          user: null,
          authenticated: false
        })
      })
      .finally(() => {
        setLoading(false)
        isLoading.current = false
      })
  }, [])

  React.useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('auth', event, data)
      switch (event) {
        case 'signIn':
          handleSetUser(data)
          break
        case 'signOut':
          handleSetUser(null)
          break
      }
    })
  }, [])

  const ctx = {
    user,
    authenticated,
    setUser: handleSetUser
  }
  console.log('authState', ctx)

  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const ctx = React.useContext(AuthContext)
  return ctx
}
