import {
  AppPasswordValidatorService,
} from '../services/AppPasswordValidatorService'

import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
  ValidationOptions,
  registerDecorator,
} from 'class-validator'

import {
  Container,
} from 'typescript-ioc'

/**
 * class-validator constraint for `@IsPassword` decorator.
 */
@ValidatorConstraint({
  name: 'IsPassword',
  async: false,
})
class IsPasswordConstraint implements ValidatorConstraintInterface {
  /**
   * Enforce password validation constraint.
   *
   * @param password Password to validate.
   * @param args Optional class-validator validation arguments.
   * @return boolean
   */
  public validate (password: any, _args?: ValidationArguments): Promise<boolean> | boolean {
    const passwordValidator = Container.get(AppPasswordValidatorService)

    return passwordValidator.validatePassword(password)
  }
}

/**
 * IsPassword decorator.
 *
 * @param validationOptions class-validator validation options.
 * @return PropertyDecorator
 */
export function IsPassword (validationOptions?: ValidationOptions): PropertyDecorator {
  return function (target: Object, propertyKey: string) {
    registerDecorator({
      target: target.constructor,
      propertyName: propertyKey,
      options: validationOptions,
      constraints: [],
      validator: IsPasswordConstraint,
    })
  }
}