import { AssetSchema } from '@loadsys-cmms/app-sdk'
import { useMounted } from '@loadsys-cmms/ui-components'
import { useCallback, useState } from 'react'
import { useAppDispatch } from '../../../store/useAppDispatch'
import { API } from 'aws-amplify'
import { plainToClass } from 'class-transformer'
import { AssetsActions } from '../store/store-config'


export const useAddAssetQuery = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState<
    Record<string, string> | Record<string, string>[]
  >(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const addAsset = useCallback(
    async (schema: AssetSchema): Promise<AssetSchema> => {
      setProcessing(true)
      return API.post('assetsApi', '/assets', {
        body: {
          data: schema,
        }
      }).then((schema) => {
        dispatch(AssetsActions.addOne(plainToClass(AssetSchema, schema)))
        if (mounted.current) {
          setProcessing(false)
          setErrors(null)
          setErrorMessage(null)
        }
        return schema
      })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            //setErrorMessage(err?.response?.data?.errorMessage)
            //setErrors(err?.response?.data?.errors)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    errors,
    errorMessage,
    addAsset,
  }
}
