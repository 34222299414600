import { AssetClassHierarchySchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { useCallback } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import { SchemaAssetClassPicker } from '../../pickers/SchemaAssetModelPicker'
import { getAssetClassSelector } from '../../selectors/selectors'

interface AssetClassHierarchyFormProps {
  schema: AssetClassHierarchySchema
  onChange: (schema: AssetClassHierarchySchema) => void
}

export const AssetClassHierarchyForm: React.FC<
  AssetClassHierarchyFormProps
> = ({ schema, onChange = () => {} }) => {
  const getAssetClass = useAppSelector(getAssetClassSelector)
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnLeaveClassPicker = () => {
    if (_.isEmpty(schema?.name) && !_.isEmpty(schema?.childAssetClassId)) {
      const assetClass = getAssetClass(schema?.childAssetClassId)
      if (assetClass) {
        if (_.isEmpty(schema.name)) {
          schema.name = assetClass.name
        }
        if (_.isEmpty(schema.code)) {
          schema.code = _.camelCase(assetClass.name)
        }
        onChange(schema)
      }
    }
  }

  const handleOnLeaveName = useCallback(() => {
    if (!_.isEmpty(schema.name) && _.isEmpty(schema.code)) {
      schema.code = _.camelCase(schema.name)
      onChange(schema)
    }
  }, [schema, onChange])

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaAssetClassPicker
        label={'Class'}
        placeholder={'Class'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'childAssetClassId'}
        onBlur={handleOnLeaveClassPicker}
      />

      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
        onBlur={handleOnLeaveName}
      />

      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
      />
    </>
  )
}
