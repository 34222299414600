import { AssetPropertyValueSchema, AssetSchema } from '@loadsys-cmms/app-sdk'
import { useMounted } from '@loadsys-cmms/ui-components'
import { useCallback, useState } from 'react'
import { API } from 'aws-amplify'


export const usePutAssetPropertiesQuery = () => {
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState<
    Record<string, string> | Record<string, string>[]
  >(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const putAssetProperties = useCallback(
    async (assetId: string, properties: AssetPropertyValueSchema[]): Promise<AssetSchema> => {
      setProcessing(true)
      return API.put('assetsApi', `/assets/${assetId}/properties`, {
        body: {
          data: properties,
        }
      }).then((schema) => {
        setProcessing(false)
        return schema
      })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            //setErrorMessage(err?.response?.data?.errorMessage)
            //setErrors(err?.response?.data?.errors)
            throw err
          }
          return null
        })
    },
    [mounted],
  )

  return {
    processing,
    errors,
    errorMessage,
    putAssetProperties,
  }
}
