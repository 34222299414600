export enum EventSeverity {
  Info = 'INFO',
  Warning = 'WARNING',
  Alert = 'ALERT',
  Critical = 'CRITICAL'
}

export enum EventStatus {
  Active = 'ACTIVE',
  Dismissed = 'DISMISSED',
}

export enum EventTriggerType {
  Asset = 'ASSET',
  AssetMetric = 'ASSET_METRIC'
}

export enum EventTriggerFrequency {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}