import { AssetClassFailureModeSchema } from '@loadsys-cmms/app-sdk'
import { AppDataGrid, useRefreshComponent } from '@loadsys-cmms/ui-components'
import {
  GridColDef,
  GridActionsCellItem,
  GridRowParams
} from '@mui/x-data-grid-pro'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import hashObject from 'hash-object'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { AddAssetClassFailureModeButton } from './AddAssetClassFailureModeButton'
import { EditAssetClassFailureModeDrawer } from './EditAssetClassFailureModeDrawer'
import { useAppSelector } from '../../../store/useAppSelector'
import { getEventTypeSelector } from '../../event-types/selectors/selectors'
import { getClassPropertySelector } from '../../asset-classes/selectors/selectors'
import { getAssetFailureModeSelector } from '../../asset-failure-modes/selectors/selectors'
import { getAssetFailureCauseSelector } from '../../asset-failure-causes/selectors/selectors'

interface AssetClassFailureModesProps {
  schema: AssetClassModel
  enableEdit?: boolean
  offset?: number
}

export const AssetClassFailureModes: React.FC<AssetClassFailureModesProps> = ({
  schema,
  enableEdit,
  offset = 0
}) => {
  const getAssetFailureMode = useAppSelector(getAssetFailureModeSelector)
  const getAssetFailureCause = useAppSelector(getAssetFailureCauseSelector)

  const [formHeight, setFormHeight] = useState<number>(37)
  const [editEventTrigger, setEditEventTrigger] =
    useState<AssetClassFailureModeSchema>(null)
  const { refreshComponent } = useRefreshComponent()

  const handleOnFieldChange = () => {
    refreshComponent()
  }

  const handleOnDelete = useCallback(
    (failureMode: AssetClassFailureModeSchema) => {
      /*const index = _.findIndex(schema.failureModes, (a) => a === failureMode)
      if (
        index >= 0 &&
        schema.failureModes &&
        schema.failureModes.length > index
      ) {
        schema.failureModes.splice(index, 1)
        onChange(schema)
      }*/
    },
    [schema?.failureModes]
  )

  const handleOnEdit = useCallback(
    (failureMode: AssetClassFailureModeSchema) => {
      setEditEventTrigger(failureMode)
    },
    [schema?.failureModes]
  )

  const handleOnChange = useCallback(() => {
    refreshComponent()
  }, [schema])

  const handleOnEditClose = useCallback(
    (failureMode?: AssetClassFailureModeSchema) => {
      if (schema && failureMode) {
        const index = _.findIndex(
          schema.failureModes,
          (a) => a.id === failureMode.id
        )
        if (index >= 0) {
          schema?.failureModes.splice(index, 1, failureMode)
          refreshComponent()
        }
      }
      setEditEventTrigger(null)
    },
    [schema]
  )

  const failureModes = useMemo(() => {
    return _.map(schema?.failureModes, (failureMode) => failureMode)
  }, [hashObject(schema?.failureModes)])

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'assetFailureMode',
          headerName: 'Failure Mode',
          width: 200,
          valueGetter: (params) => {
            const mode = getAssetFailureMode(params.row.assetFailureModeId)
            return mode?.name
          }
        },
        {
          field: 'assetFailureCause',
          headerName: 'Failure Cause',
          width: 150,
          valueGetter: (params) => {
            const mode = getAssetFailureCause(params.row.assetFailureCauseId)
            return mode?.name
          }
        },
        {
          field: 'code',
          headerName: 'Code',
          width: 150
        },
        {
          field: 'description',
          headerName: 'Description',
          width: 300
        },
        {
          field: 'maintenanceTask',
          headerName: 'Maintenance Task',
          width: 300
        },
        {
          width: 80,
          field: 'actions',
          type: 'actions',
          sortable: false,
          editable: false,
          filterable: false,
          hideable: false,
          hide: !enableEdit,
          getActions: (params: GridRowParams) => [
            <GridActionsCellItem
              key={'edit'}
              icon={<EditIcon />}
              label='Edit'
              onClick={() => handleOnEdit(params.row)}
            />,
            <GridActionsCellItem
              key={'delete'}
              icon={<DeleteIcon />}
              label='Delete'
              onClick={() => handleOnDelete(params.row)}
            />
          ]
        }
      ] as GridColDef[],
    [handleOnDelete, enableEdit, schema]
  )

  const heightCalc = useMemo(() => {
    return `calc(100vh - ${(formHeight || 0) + offset}px)`
  }, [formHeight, offset])

  if (!schema) {
    return null
  }

  return (
    <>
      {enableEdit && (
        <Box>
          <AddAssetClassFailureModeButton
            schema={schema}
            onChange={handleOnChange}
          />
        </Box>
      )}
      <Box sx={{ height: heightCalc, flexGrow: 1 }}>
        <AppDataGrid
          density={'compact'}
          columns={columns}
          rows={failureModes}
          getRowId={(row) => row.code}
          pinnedColumns={{ right: ['actions'] }}
        />
      </Box>
      <EditAssetClassFailureModeDrawer
        assetClass={schema}
        open={!!editEventTrigger}
        onClose={handleOnEditClose}
        failureMode={editEventTrigger}
      />
    </>
  )
}
