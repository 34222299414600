import { useCallback, useEffect } from 'react'
import { useAmplifyPubsub } from '../hooks/useAmplifyPubsub'
import { PubSub } from '@aws-amplify/pubsub'
import config from '../../../Config'

export const AmplifyPubsubUpdatesListener: React.FC = ({ children }) => {
  const { clientId } = useAmplifyPubsub()

  const buildTopic = useCallback(
    (topic: string) => {
      return `${config.appName}/${config.appStage}/pubsub/${clientId}/${topic}`
    },
    [clientId, config.appName, config.appStage]
  )

  useEffect(() => {
    if (!clientId) {
      return
    }
    const subscription = PubSub.subscribe(buildTopic('updates/#')).subscribe({
      next: (data) => {
        console.log('PubSub', 'next', data)
      },
      error: (error) => {
        console.log('PubSub', 'error', error)
      },
      complete: () => {
        console.log('PubSub', 'complete')
      },
      start: (sub) => {
        console.log('PubSub', 'start', sub)
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [clientId, buildTopic])

  return <>{children}</>
}
