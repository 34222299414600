import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

export interface MeasuredContainerProps {
  onMeasured?: (height: number) => void
}

export const MeasuredContainer: React.FC<MeasuredContainerProps> = ({
  onMeasured = () => {},
  children
}) => {
  const [height, setHeight] = useState(0)

  const ref = React.useRef<HTMLElement>()

  useEffect(() => {
    const height = ref?.current?.clientHeight
    setHeight(height)
    onMeasured(height)
  }, [])

  return <Box ref={ref}>{children}</Box>
}
