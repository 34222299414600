import { BaseSchema } from "../../BaseSchema";
import { Expose, Type } from "class-transformer";
import { IsArray, IsNotEmpty, ValidateNested, ArrayMaxSize, ArrayMinSize } from "class-validator";
import { AssetPropertyValueEntrySchema } from "./AssetPropertyValueEntrySchema";

export class AssetPropertyValueEntryRequestSchema extends BaseSchema {
  @Type(() => AssetPropertyValueEntrySchema)
  @Expose()
  @ValidateNested()
  @IsNotEmpty()
  @IsArray()
  @ArrayMinSize(1)
  @ArrayMaxSize(10)
  entries: AssetPropertyValueEntrySchema[];
}