import { LocationSchema } from "@loadsys-cmms/app-sdk"
import createStoreAdapter from "../../../store/createStoreAdapter"

const STORE_NAME = 'locations'

const adapter = createStoreAdapter<LocationSchema>({
  classType: LocationSchema,
  storeName: STORE_NAME,
  apiParams: { name: 'assetsApi', 'baseUrl': '/locations' },
  selectIdFn: (entity: LocationSchema) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const LocationsReducer = adapter.reducer
export const LocationsSelectors = adapter.selectors
export const LocationsThunks = adapter.thunks
export const LocationsActions = adapter.actions

export default adapter
