import { Expose, Type } from "class-transformer";
import { IsOptional, IsString, IsNotEmpty, MaxLength, IsEnum } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../BaseSchema";
import { EventSeverity } from "./types";

export class EventTypeSchema extends BaseSchema {
  /**
   * Initialize
   *
   * @param data EventSchema
   */
  constructor(data?: Partial<EventTypeSchema>) {
    super()

    this.severity = EventSeverity.Info

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  name: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  description: string;

  @Expose()
  @IsNotEmpty()
  @IsEnum(EventSeverity)
  severity: EventSeverity = EventSeverity.Info

  @Expose()
  @Type(() => Date)
  @IsOptional()
  created: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  updated: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  deleted: Date;

}