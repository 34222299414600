import _ from 'lodash'
import React from 'react'
import { AppMenuItem, AppMenuItemProps } from './AppMenuItem'

export interface AppMenuProps {
  items: AppMenuItemProps[]
}
export const AppMenu: React.FC<AppMenuProps> = ({ items }) => {
  return (
    <React.Fragment>
      {_.map(items, (item, index) => (
        <AppMenuItem key={index} {...item} />
      ))}
    </React.Fragment>
  )
}
