import { EventTypeSchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { SchemaEventSeverityPicker } from '../pickers/SchemaEventSeverityPicker'

interface EventTypeFormProps {
  schema: EventTypeSchema
  onChange: (schema: EventTypeSchema) => void
}

export const EventTypeForm: React.FC<EventTypeFormProps> = ({
  schema,
  onChange = () => {}
}) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
      />
      <SchemaTextInput
        label={'Description'}
        placeholder={'Description'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'description'}
      />
      <SchemaEventSeverityPicker
        schema={schema}
        field={'severity'}
        onChange={handleOnFieldChange}
      />
    </>
  )
}
