import {
  EventStatus,
  EventTriggerType,
  EventSchema
} from '@loadsys-cmms/app-sdk'
import { AppDataGrid } from '@loadsys-cmms/ui-components'
import { Typography } from '@mui/material'
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { getEventTypeSelector } from '../../event-types/selectors/selectors'
import { getLocationSelector } from '../../locations/selectors/selectors'
import { EventsContext } from './EventsProvider'

interface EventsDataGridProps {}

export const EventsDataGrid: React.FC<EventsDataGridProps> = () => {
  const getLocation = useAppSelector(getLocationSelector)
  const getEventType = useAppSelector(getEventTypeSelector)
  const eventsContext = useContext(EventsContext)

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'created',
      sort: 'desc' as GridSortDirection
    }
  ])

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'severity',
          headerName: 'Severity',
          width: 100,
          valueGetter: (params) => {
            return getEventType(params.row.eventTypeId)?.severity || '-'
          }
        },
        {
          field: 'title',
          headerName: 'Title',
          width: 300
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 140
        },
        {
          field: 'triggerType',
          headerName: 'Trigger',
          width: 200
        },
        {
          field: 'type',
          headerName: 'Type',
          width: 250,
          valueGetter: (params) => {
            return getEventType(params.row.eventTypeId)?.name || '-'
          }
        },
        {
          field: 'location',
          headerName: 'Location',
          width: 250,
          valueGetter: (params) => {
            return getLocation(params.row.locationId)?.name || '-'
          }
        },
        {
          type: 'dateTime',
          field: 'created',
          headerName: 'Created',
          width: 300
        }
      ] as GridColDef[],
    [getEventType, getLocation]
  )

  if (!eventsContext) {
    return <Typography>Event Context not loaded.</Typography>
  }

  return (
    <AppDataGrid
      loading={eventsContext.loading}
      pagination
      density={'compact'}
      getRowId={(model: EventSchema) => model.id}
      sortModel={sortModel}
      columns={columns}
      rowCount={eventsContext.total}
      rows={eventsContext.events}
      rowsPerPageOptions={[50, 100, 250]}
      page={eventsContext.page}
      pageSize={eventsContext.limit}
      paginationMode='server'
      onPageChange={eventsContext.onPageChange}
      onPageSizeChange={eventsContext.onPageSizeChange}
      //onCellClick={eventsContext.onCellClick}
    />
  )
}
