import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetClassesSelectors } from '../store/store-config'

export interface AssetClassNameProps {
  assetClassId: string
  link?: boolean
}

export const AssetClassName: React.FC<AssetClassNameProps> = ({
  assetClassId,
  link = false
}) => {
  const AssetClass = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, assetClassId)
  )
  return (
    <>
      {link ? (
        <Button
          size={'small'}
          component={Link}
          to={'/asset-management/classes/' + assetClassId}
        >
          {AssetClass?.name || '-'}
        </Button>
      ) : (
        <>{AssetClass?.name || '-'}</>
      )}
    </>
  )
}
