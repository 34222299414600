import { AssetFailureCauseSchema } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import { assetFailureCausesForFailureModeSelector } from '../../selectors/selectors'
import { AssetFailureCausesSelectors } from '../../store/store-config'
import { AddAssetFailureCauseDrawer } from '../AddAssetFailureCauseDrawer'

export interface AssetFailureCausePickerProps
  extends Omit<AutocompleteInputProps, 'options'> {
  assetFailureModeId?: string
}

export const AssetFailureCausePicker: React.FC<
  AssetFailureCausePickerProps
> = ({
  onChange,
  label = 'Failure Cause',
  placeholder = 'Failure Cause',
  assetFailureModeId,
  value = null,
  ...props
}) => {
  //const { checkPermissions } = useAppPermissions()

  const [openAdd, setOpenAdd] = useState(false)
  const [text, setText] = useState<string>('')

  const entities = useAppSelector((state) =>
    assetFailureCausesForFailureModeSelector(state, assetFailureModeId)
  )

  const options: InputOptionProps[] = useMemo(() => {
    return _.sortBy(
      _.map(entities, (c) => c.getPickerInputOption()),
      ['label'],
      ['asc']
    )
  }, [entities])

  useEffect(() => {
    if (!value) {
      return
    }
    const index = _.findIndex(entities, (entity) => entity.id === value)
    if (index < 0) {
      onChange(null)
    }
  }, [entities, value, onChange])

  const handleOnCreate = (name?: string) => {
    setOpenAdd(true)
    setText(name)
  }

  const handleOnCloseAdd = (schema?: AssetFailureCauseSchema) => {
    if (schema) {
      onChange(schema.getIdentifier())
    }
    setOpenAdd(false)
  }

  return (
    <>
      <AutocompleteInput
        {...props}
        value={value}
        label={label}
        placeholder={placeholder}
        options={options}
        onChange={(value) => onChange(value as string)}
        onCreateNew={handleOnCreate}
      />
      <AddAssetFailureCauseDrawer
        open={openAdd}
        onClose={handleOnCloseAdd}
        navigateOnClose={false}
        defaultData={{
          name: text,
          code: _.camelCase(text)
        }}
      />
    </>
  )
}
