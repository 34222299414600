import { SignupSchema } from '@loadsys-cmms/app-sdk'
import { Auth } from 'aws-amplify'
import React from 'react'
import { useAppPubSub } from '../hooks'
import { useSnackbarMessages } from '../hooks/useSnackbarMessages'
import { SignupForm } from './SignupForm'

interface AmplifySignupComponentProps {
  onSignup: () => void
}

export const AmplifySignupComponent: React.FC<AmplifySignupComponentProps> = ({
  onSignup = () => {}
}) => {
  const appPubSub = useAppPubSub()
  const messages = useSnackbarMessages()

  const handleOnSubmit = React.useCallback((signupData: SignupSchema) => {
    if (!signupData) {
      return
    }
    if (signupData?.validate()) {
      console.log('logging in')
      Auth.signUp(signupData.email, signupData.password)
        .then((result) => {
          console.log('result', result)
          /*appPubSub.publish('Auth.state', {
            loggedIn: true,
            user: user
          })*/
          messages.addSuccessMessage('Signup successful.')
          onSignup()
        })
        .catch((e: Error) => {
          console.log('err', e)
          messages.addErrorMessage('Login failed - ' + e)
          appPubSub.publish('Auth.state', {
            loggedIn: false,
            user: null
          })
        })
    } else {
      console.log('Validation errors', signupData.getErrors())
    }
  }, [])

  return <SignupForm onSubmit={handleOnSubmit} />
}
