import { Expose, Type } from "class-transformer";
import { IsOptional, MaxLength, IsEnum, IsNotEmpty, IsAlphanumeric, IsArray } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../BaseSchema";
import { AssetClassPropertyType, AssetPropertyDataType } from "./types";

export class AssetPropertySchema extends BaseSchema {
  @Expose()
  id: string;

  @Expose()
  @IsOptional()
  assetId: string;

  @Expose()
  @IsOptional()
  assetClassPropertyId: string;

  @Expose()
  @IsOptional()
  @MaxLength(512)
  alias: string;

  @Expose()
  @IsNotEmpty()
  @IsEnum(AssetClassPropertyType)
  type: AssetClassPropertyType;

  /**
   * Property Code
   */
  @Expose()
  @IsNotEmpty()
  @MaxLength(256)
  @IsAlphanumeric()
  code: string;

  /**
   * Codes that triggers creation of a new metric or transform.
   * The codes retrieved from a formula.
   */
  @Expose()
  @IsOptional()
  @IsArray()
  @Type(() => Array)
  formulaCodes: string[];

  @Expose()
  @IsNotEmpty()
  @MaxLength(36)
  @IsEnum(AssetPropertyDataType)
  dataType: AssetPropertyDataType;
}