import { AssetModelSchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { useAppSelector } from '../../../../store/useAppSelector'
import { SchemaAssetClassPicker } from '../../../asset-classes/pickers/SchemaAssetModelPicker'
import { getAssetClassSelector } from '../../../asset-classes/selectors/selectors'
import { SchemaAssetModelTypePicker } from '../pickers/SchemaAssetModelTypePicker'

interface AssetModelFormProps {
  schema: AssetModelSchema
  onChange: (schema: AssetModelSchema) => void
}

export const AssetModelForm: React.FC<AssetModelFormProps> = ({
  schema,
  onChange = () => {}
}) => {
  const getAssetClass = useAppSelector(getAssetClassSelector)

  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnLeaveName = () => {
    if (_.isEmpty(schema?.code) && !_.isEmpty(schema?.name)) {
      schema.code = _.camelCase(schema.name)
      onChange(schema)
    }
  }

  const handleOnLeaveClass = () => {
    const assetClass = getAssetClass(schema?.assetClassId)
    if (assetClass && _.isEmpty(schema?.name)) {
      schema.name = assetClass.name
      if (_.isEmpty(schema.code)) {
        schema.code = _.camelCase(schema.name)
      }
      onChange(schema)
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaAssetClassPicker
        label={'Class'}
        placeholder={'Asset Class'}
        schema={schema}
        field={'assetClassId'}
        onChange={handleOnFieldChange}
        onBlur={handleOnLeaveClass}
        disabled={!!schema?.id}
      />
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
        inputProps={{
          onBlur: handleOnLeaveName
        }}
      />
      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
      />
      <SchemaTextInput
        label={'Description'}
        placeholder={'Description'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'description'}
      />
    </>
  )
}
