import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  AppTabProps,
  AppTabs,
  AppTabPanel,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { AssetFailureCausesSelectors } from '../store/store-config'

const tabs: AppTabProps[] = [
  { value: 'details', label: 'Details' },
  { value: 'classes', label: 'Classes' }
]

interface ViewAssetFailureCauseDrawerProps {
  open: boolean
  onClose: () => void
}

export const ViewAssetFailureCauseDrawer: React.FC<
  ViewAssetFailureCauseDrawerProps
> = ({ open, onClose }) => {
  const { entityId } = useEntityParams()

  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()

  const schema = useAppSelector((state) =>
    AssetFailureCausesSelectors.selectById(state, entityId)
  )

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/failure-causes')
    onClose()
  }, [])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          {schema?.name}
        </Typography>
        <Button onClick={() => navigate(`${entityId}/edit`)}>Edit</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <>
            <AppTabs tabs={tabs} selected={'details'} hidden={true}>
              <AppTabPanel value={'details'}>
                <Paper
                  sx={{
                    paddingTop: theme.spacing(1),
                    marginBottom: theme.spacing(1)
                  }}
                >
                  <Container>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Name
                            </Typography>
                          </TableCell>
                          <TableCell>{schema.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Code
                            </Typography>
                          </TableCell>
                          <TableCell>{schema.code}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Description
                            </Typography>
                          </TableCell>
                          <TableCell>{schema.description}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Container>
                </Paper>
              </AppTabPanel>
              <AppTabPanel value={'classes'}></AppTabPanel>
            </AppTabs>
          </>
        )}
      </AppDrawerContent>
    </AppDrawer>
  )
}
