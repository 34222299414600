import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './theme'
import { AppLayout } from './components/layout/AppLayout'
import Config from './Config'
import { Amplify } from 'aws-amplify'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { Provider as StoreProvider } from 'react-redux'
import { AppStore } from './store/AppStore'

//Amplify.Logger.LOG_LEVEL = 'DEBUG'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: Config.cognito.REGION,
    userPoolId: Config.cognito.USER_POOL_ID,
    userPoolWebClientId: Config.cognito.APP_CLIENT_ID,
    identityPoolId: Config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'assetsApi',
        endpoint: Config.api.assetsApi
      },
      {
        name: 'usersApi',
        endpoint: Config.api.usersApi
      },
      {
        name: 'eventsApi',
        endpoint: Config.api.eventsApi
      }
    ]
  }
})

LicenseInfo.setLicenseKey(
  '8404ba279ae08ce05b040dc21f3fdaeeT1JERVI6MzQzMDYsRVhQSVJZPTE2NzEwNTA5NjQwMDAsS0VZVkVSU0lPTj0x'
)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <StoreProvider store={AppStore}>
          <AppLayout />
        </StoreProvider>
      </CssBaseline>
    </ThemeProvider>
  )
}

export default App
