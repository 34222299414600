import { IsString, IsNotEmpty, IsOptional, IsEnum, MaxLength } from 'class-validator';
import { Type, Expose } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import { EventStatus, EventTriggerType } from './types';

export class EventSchema extends BaseSchema {
  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  locationId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  eventTypeId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  title: string;

  @Expose()
  @IsOptional()
  @IsEnum(EventTriggerType)
  @MaxLength(255)
  triggerType: EventTriggerType;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  triggerId: string;

  @Expose()
  @IsNotEmpty()
  @IsEnum(EventStatus)
  status: EventStatus;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  created: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  updated: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  deleted: Date;
}
