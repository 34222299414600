import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  AppTabProps,
  AppTabs,
  AppTabPanel,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { formatLocalDate } from '@loadsys-cmms/app-sdk'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { AssetsSelectors } from '../store/store-config'
import { LocationName } from '../../locations/components/LocationName'
import { AssetClassName } from '../../asset-classes/components/AssetClassName'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetStateUpdater } from './AssetStateUpdater'
import { AssetModelsSelectors } from '../../asset-models/store/store-config'
import { AssetProperties } from './AssetProperties'
import { AssetModelName } from '../../asset-models/components/AssetModelName'

const tabs: AppTabProps[] = [
  { value: 'details', label: 'Details' },
  { value: 'assets', label: 'Assets' }
]

interface ViewAssetDrawerProps {
  open: boolean
  onClose: () => void
}

export const ViewAssetDrawer: React.FC<ViewAssetDrawerProps> = ({
  open,
  onClose
}) => {
  const { entityId } = useEntityParams()

  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()

  const schema = useAppSelector((state) =>
    AssetsSelectors.selectById(state, entityId)
  )

  const assetModel = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, schema?.assetModelId)
  )

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/assets')
    onClose()
  }, [])

  return (
    <AppDrawer
      minWidth={400}
      width={'60vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          {schema?.name}
        </Typography>
        <Button onClick={() => navigate(`${entityId}/edit`)}>Edit</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <>
            <AppTabs tabs={tabs} selected={'details'} hidden={true}>
              <AppTabPanel value={'details'}>
                <ContentContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant={'body2'} fontWeight={'bold'}>
                            State
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <AssetStateUpdater asset={schema} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ContentContainer>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Paper
                      sx={{
                        paddingTop: theme.spacing(1),
                        marginBottom: theme.spacing(1)
                      }}
                    >
                      <Container>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Location
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <LocationName LocationId={schema.locationId} />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Asset Class
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <AssetClassName
                                  assetClassId={assetModel?.assetClassId}
                                  link
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Asset Model
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <AssetModelName
                                  assetModelId={assetModel?.id}
                                  linkToModel
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Name
                                </Typography>
                              </TableCell>
                              <TableCell>{schema.name}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Code
                                </Typography>
                              </TableCell>
                              <TableCell>{schema.code}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Description
                                </Typography>
                              </TableCell>
                              <TableCell>{schema.description}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Make
                                </Typography>
                              </TableCell>
                              <TableCell>{schema.make}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Model
                                </Typography>
                              </TableCell>
                              <TableCell>{schema.model}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Serial #
                                </Typography>
                              </TableCell>
                              <TableCell>{schema.serial}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Installation
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {formatLocalDate(schema.installationDate)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'body2'}
                                  fontWeight={'bold'}
                                >
                                  Warranty Expiration
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {formatLocalDate(schema.warrantyExpirationDate)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Container>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <AssetProperties schema={schema} />
                  </Grid>
                </Grid>
              </AppTabPanel>
              <AppTabPanel value={'assets'}></AppTabPanel>
            </AppTabs>
          </>
        )}
      </AppDrawerContent>
    </AppDrawer>
  )
}
