import { Expose, Transform, Type } from "class-transformer";
import { IsOptional, IsString, IsNotEmpty, IsAlphanumeric, MaxLength, IsDate, IsEnum, ValidateNested, IsArray } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../BaseSchema";
import { AssetPropertySchema } from "./AssetPropertySchema";
import { AWSAssetSchema } from "./AWSAssetSchema";
import { AWSResourceSyncStatus } from "./types";

export enum AssetState {
  Idle = 'IDLE',
  Operating = 'OPERATING',
  Maintenance = 'MAINTENANCE',
  Repair = 'REPAIR',
  Failure = 'FAILURE',
  Decomissioned = 'DECOMISSIONED',
}

export class AssetSchema extends BaseSchema {
  /**
   * Initialize Measurement
   *
   * @param data AssetModelPropertyTypeMeasurementSchema
   */
  constructor(data?: Partial<AssetSchema>) {
    super()

    this.isEnabled = true
    this.state = AssetState.Operating

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  awsAssetId: string;

  @Expose()
  @IsOptional()
  @Transform(params => {
    if (_.isEmpty(params.value)) {
      return undefined
    }
    return params.value
  })
  awsSyncStatus: AWSResourceSyncStatus;

  @Type(() => AWSAssetSchema)
  @Expose()
  @IsOptional()
  awsAssetSchema: AWSAssetSchema;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  parentAssetId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  locationId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  assetModelId: string;

  @Expose()
  @IsNotEmpty()
  @IsEnum(AssetState)
  state: AssetState;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  name: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @IsAlphanumeric()
  @MaxLength(255)
  code: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  description: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  make: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  model: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  @MaxLength(255)
  serial: string;

  @Expose()
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  installationDate: Date;

  @Expose()
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  warrantyExpirationDate: Date;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  isEnabled: boolean = true;

  @Expose()
  @Type(() => AssetPropertySchema)
  @IsOptional()
  @ValidateNested()
  @IsArray()
  properties: AssetPropertySchema[]

  public getAssetPropertyByName (name: string) {
    return _.find(this.awsAssetSchema?.assetProperties, property => property.name === name)
  }
}