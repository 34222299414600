import { GridRowId, GridState } from '@mui/x-data-grid-pro'
import React, { useState } from 'react'

export interface AppDataGridContextProps {
  gridState: GridState
  setGridState: (state: GridState) => void
  rowSelection: GridRowId[]
  visibleRows: GridRowId[]
  setRowSelection: (selection: GridRowId[]) => void
  setVisibleRows: (rows: GridRowId[]) => void
  processing: boolean
  setProcessing: (processing: boolean) => void
}

export const AppDataGridContext = React.createContext<AppDataGridContextProps>({
  gridState: null,
  setGridState: (_state: GridState) => {},
  rowSelection: [],
  visibleRows: [],
  processing: false,
  setRowSelection: (_selection: GridRowId[]) => {},
  setVisibleRows: (_rows: GridRowId[]) => {},
  setProcessing: (_processing: boolean) => {}
})

export const AppDataGridProvider: React.FC = ({ children }) => {
  const [gridState, setGridState] = useState<GridState>()
  const [rowSelection, setRowSelection] = useState<GridRowId[]>()
  const [visibleRows, setVisibleRows] = useState<GridRowId[]>()
  const [processing, setProcessing] = useState<boolean>(false)

  const ctx: AppDataGridContextProps = {
    gridState,
    setGridState,
    rowSelection,
    visibleRows,
    setRowSelection,
    setVisibleRows,
    processing,
    setProcessing
  }

  return (
    <AppDataGridContext.Provider value={ctx}>
      {children}
    </AppDataGridContext.Provider>
  )
}
