import { IsString, IsNotEmpty, IsOptional, IsUUID } from 'class-validator';
import { Type, Expose } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash';

export enum AssetClassFailureModePriority {
  Top = 'TOP',
  High = 'HIGH',
  Normal = 'NORMAL',
  Low = 'LOW',
}

export class AssetClassFailureModeSchema extends BaseSchema {
  constructor(data?: Partial<AssetClassFailureModeSchema>) {
    super()

    this.id = uuidv4()
    //this.priority = AssetClassFailureModePriority.Normal
    if (data) {
      _.assign(this, data)
    }
  }

  @Expose()
  @IsNotEmpty()
  @IsString()
  id: string;

  @Expose()
  @IsString()
  @IsUUID()
  @Type(() => String)
  @IsNotEmpty()
  assetClassId: string;


  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  code: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  assetFailureModeId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  assetFailureCauseId: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  maintenanceActionId: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  description: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  maintenanceTask: string;

  /*
  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  priority: AssetClassFailureModePriority;
*/
}
