import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { memoize } from 'lodash'
import { AppRootState } from '../../../store/AppStore'
import { AssetsSelectors } from '../store/store-config'

export const getAssetSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return AssetsSelectors.selectById(state, id)
    }),
)

export const AssetSelector = createSelector(
  getAssetSelector,
  (getAsset) =>
    memoize((id: string) => {
      return getAsset(id)
    }),
)

export const getAssetsForModelSelector = createSelector(
  AssetsSelectors.selectAll,
  (assets) =>
    memoize((assetModelId: string) => {
      return _.filter(assets, asset => asset.assetModelId === assetModelId)
    }),
)

export const AssetsForModelSelector = createSelector(
  getAssetsForModelSelector,
  (_state: AppRootState, assetModelId: string) => assetModelId,
  (getAssetsForModel, assetModelId) => {
    return getAssetsForModel(assetModelId)
  }
)
