import { Divider } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  DataGridPro,
  GridSortModel,
  GridState,
  gridVisibleSortedRowIdsSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridDensity,
  GridRowIdGetter,
  DataGridProProps
} from '@mui/x-data-grid-pro'
import React, { Fragment, useCallback } from 'react'

import { useAppDataGridContext } from './useAppDataGridContext'

interface AppDataGridToolbarProps {
  exportPrefix: string
  enableExport?: boolean
}

const AppDataGridToolbar: React.FC<AppDataGridToolbarProps> = ({
  exportPrefix = 'export',
  enableExport = false
}) => {
  const now = new Date()

  return (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        {enableExport && (
          <GridToolbarExport
            csvOptions={{
              allColumns: true,
              fileName: exportPrefix + '_' + now.toUTCString()
            }}
          />
        )}
      </GridToolbarContainer>
      <Divider />
    </Fragment>
  )
}

interface AppDataGridProps
  extends Omit<
    DataGridProProps,
    'initialState' | 'components' | 'onStateChange' | 'checkboxSelection'
  > {
  sortModel?: GridSortModel
  //columns: GridColDef[]
  //rows: any[]
  //onCellClick?: (cellParams: GridCellParams) => void
  checkboxSelection?: boolean
  showToolbar?: boolean
  exportPrefix?: string
  //density?: GridDensity
  //disableSelectionOnClick?: boolean
  enabledExport?: boolean
  //getRowId?: GridRowIdGetter<any>
}

export const AppDataGrid: React.FC<AppDataGridProps> = ({
  sortModel,
  //columns,
  //rows,
  //onCellClick = () => {},
  checkboxSelection,
  showToolbar = true,
  exportPrefix,
  //density,
  disableSelectionOnClick = true,
  enabledExport = false,
  ...props
  //getRowId
}) => {
  const { setGridState, setVisibleRows, setRowSelection, processing } =
    useAppDataGridContext()

  const handleOnStateChange = useCallback(
    (state: GridState) => {
      setGridState(state)
      if (!setVisibleRows) {
        return
      }
      const visibileRows = gridVisibleSortedRowIdsSelector(state)
      const selectedRows = state.selection

      setVisibleRows(visibileRows)
      setRowSelection(selectedRows)
    },
    [setGridState, setVisibleRows, setRowSelection]
  )

  return (
    <DataGridPro
      {...props}
      //getRowId={getRowId}
      //density={density}
      disableSelectionOnClick={disableSelectionOnClick}
      components={{
        Toolbar: showToolbar ? AppDataGridToolbar : null
      }}
      componentsProps={{
        toolbar: {
          exportPrefix: exportPrefix,
          enabledExport: enabledExport
        }
      }}
      //columns={columns}
      //rows={rows}
      showColumnRightBorder
      //onCellClick={onCellClick}
      initialState={{
        sorting: {
          sortModel: sortModel
        }
      }}
      checkboxSelection={checkboxSelection && !processing}
      onStateChange={handleOnStateChange}
      loading={processing}
    />
  )
}
