import { AssetClassHierarchySchema } from '@loadsys-cmms/app-sdk'
import { useRefreshComponent } from '@loadsys-cmms/ui-components'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { AssetClassModel } from '../models/AssetClassModel'
import { AssetClassHierarchyForm } from './forms/AssetClassHierarchyForm'

const initializeSchema = () => {
  return new AssetClassHierarchySchema()
}

interface AddAssetClassHierarchyProps {
  schema: AssetClassModel
  onChange: (schema: AssetClassModel) => void
}

export const AddAssetClassHierarchy: React.FC<AddAssetClassHierarchyProps> = ({
  schema,
  onChange = () => {}
}) => {
  const { refreshComponent } = useRefreshComponent()
  const [hierarchy, setHierarchy] = useState<AssetClassHierarchySchema>(
    initializeSchema()
  )
  const handleOnFieldChange = () => {
    onChange(schema)
  }

  const handleOnAdd = () => {
    if (hierarchy.validate()) {
      if (!_.isArray(schema.hierarchies)) {
        schema.hierarchies = []
      }
      schema.hierarchies.push(hierarchy)
      setHierarchy(initializeSchema())
      onChange(schema)
    } else {
      refreshComponent()
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <AssetClassHierarchyForm
        schema={hierarchy}
        onChange={handleOnFieldChange}
      />
      <Box display={'flex'}>
        <Box flex={1}></Box>
        <Button onClick={handleOnAdd}>Add</Button>
      </Box>
    </>
  )
}
