import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetClassesSelectors } from '../../asset-classes/store/store-config'
import { AssetModelsSelectors } from '../store/store-config'

export interface AssetModelNameProps {
  assetModelId: string
  linkToModel?: boolean
}

export const AssetModelTypeValue: React.FC<AssetModelNameProps> = ({
  assetModelId
}) => {
  const assetModel = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, assetModelId)
  )
  const assetClass = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, assetModel?.assetClassId)
  )
  return <>{assetClass?.type}</>
}
