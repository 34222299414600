//import { TumblingWindow } from "@aws-sdk/client-iotsitewise";
import { Expose } from "class-transformer";
import { IsOptional, IsString } from "class-validator";
import { BaseSchema } from "../../BaseSchema";

export class TumblingWindowSchema extends BaseSchema /*implements TumblingWindow */ {
  @Expose()
  @IsOptional()
  @IsString()
  interval: string;

  offset?: string;
}