import { BaseSchema } from "../BaseSchema";
import { Expose } from "class-transformer";
import { IsNotEmpty, IsOptional } from "class-validator";
import { Assets } from "./types";

export interface DetailedError {
  /**
   * <p>The error code. </p>
   */
  code: string

  /**
   * <p>The error message. </p>
   */
  message: string
}

export interface ErrorDetails {
  /**
   * <p>The error code.</p>
   */
  code: string

  /**
   * <p>The error message.</p>
   */
  message: string

  /**
   * <p> A list of detailed errors. </p>
   */
  details?: DetailedError[]
}

export enum SystemAssetState {
  ACTIVE = "ACTIVE",
  CREATING = "CREATING",
  DELETING = "DELETING",
  FAILED = "FAILED",
  UPDATING = "UPDATING",
}

/**
 * <p>Contains information about the current status of an asset. For more information, see
 *         <a href="https://docs.aws.amazon.com/iot-sitewise/latest/userguide/asset-and-model-states.html">Asset and model
 *         states</a> in the <i>IoT SiteWise User Guide</i>.</p>
 */
export class AssetStatus extends BaseSchema {
  /**
   * <p>The current status of the asset.</p>
   */
  state: SystemAssetState | string | undefined;

  /**
   * <p>Contains associated error information, if any.</p>
   */
  error?: ErrorDetails;
}

export enum PropertyNotificationState {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export interface PropertyNotification {
  /**
   * <p>The MQTT topic to which IoT SiteWise publishes property value update notifications.</p>
   */
  topic: string | undefined;

  /**
   * <p>The current notification state.</p>
   */
  state: PropertyNotificationState;
}

export interface AssetProperty {
  /**
   * <p>The ID of the asset property.</p>
   */
  id: string

  /**
   * <p>The name of the property.</p>
   */
  name: string

  /**
   * <p>The alias that identifies the property, such as an OPC-UA server data stream path
   *         (for example, <code>/company/windfarm/3/turbine/7/temperature</code>). For more information, see
   *         <a href="https://docs.aws.amazon.com/iot-sitewise/latest/userguide/connect-data-streams.html">Mapping industrial data streams to asset properties</a> in the
   *         <i>IoT SiteWise User Guide</i>.</p>
   */
  alias?: string;

  /**
   * <p>The asset property's notification topic and state. For more information, see <a href="https://docs.aws.amazon.com/iot-sitewise/latest/APIReference/API_UpdateAssetProperty.html">UpdateAssetProperty</a>.</p>
   */
  notification?: PropertyNotification

  /**
   * <p>The data type of the asset property.</p>
   */
  dataType: Assets.PropertyDataType

  /**
   * <p>The data type of the structure for this property. This parameter exists on properties that
   *       have the <code>STRUCT</code> data type.</p>
   */
  dataTypeSpec?: string

  /**
   * <p>The unit (such as <code>Newtons</code> or <code>RPM</code>) of the asset property.</p>
   */
  unit?: string
}

export interface AssetCompositeModel {
  /**
   * <p>The name of the composite model.</p>
   */
  name: string

  /**
   * <p>The description of the composite model.</p>
   */
  description?: string

  /**
   * <p>The type of the composite model. For alarm composite models, this type is
   *         <code>AWS/ALARM</code>.</p>
   */
  type: string | undefined

  /**
   * <p>The asset properties that this composite model defines.</p>
   */
  properties: AssetProperty[]
}

export interface AssetHierarchy {
  /**
   * <p>The ID of the hierarchy. This ID is a <code>hierarchyId</code>.</p>
   */
  id?: string

  /**
   * <p>The hierarchy name provided in the <a href="https://docs.aws.amazon.com/iot-sitewise/latest/APIReference/API_CreateAssetModel.html">CreateAssetModel</a> or <a href="https://docs.aws.amazon.com/iot-sitewise/latest/APIReference/API_UpdateAssetModel.html">UpdateAssetModel</a>
   *       API operation.</p>
   */
  name: string
}

export class AWSAssetSchema extends BaseSchema {
  @Expose()
  @IsOptional()
  assetId: string

  @Expose()
  @IsNotEmpty()
  assetName: string

  @Expose()
  @IsOptional()
  assetArn: string

  @Expose()
  @IsNotEmpty()
  assetModelId: string

  @Expose()
  assetCreationDate: Date

  @Expose()
  assetLastUpdateDate: Date

  @Expose()
  assetCompositeModels: AssetCompositeModel[]

  @Expose()
  assetHierarchies: AssetHierarchy[]

  @Expose()
  assetProperties: AssetProperty[]

  @Expose()
  assetStatus: AssetStatus

  public getInputOption (): { value: string, label: string } {
    return {
      value: this.assetId,
      label: this.assetName,
    }
  }

  public getSortingValue () {
    return this.assetName
  }
}