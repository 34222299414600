import { BaseSchema } from '@loadsys-cmms/app-sdk'
import {
  AssetPropertyDataTypePicker,
  AssetPropertyDataTypePickerProps
} from './AssetPropertyDataTypePicker'

export interface SchemaAssetPropertyDataTypePickerProps
  extends Omit<AssetPropertyDataTypePickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaAssetPropertyDataTypePicker: React.FC<
  SchemaAssetPropertyDataTypePickerProps
> = ({ schema, field, helperText, onChange, ...props }) => {
  return (
    <AssetPropertyDataTypePicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: string) => onChange(value, field)}
      {...props}
    />
  )
}
