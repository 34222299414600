import { AssetFailureCauseSchema } from '@loadsys-cmms/app-sdk'
import { AppToolbar, MeasuredContainer } from '@loadsys-cmms/ui-components'
import { Box, Button, Paper } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { AssetFailureCausesSelectors } from '../../features/asset-failure-causes/store/store-config'
//import { AddAssetClassDrawer } from '../../features/asset-failure-causes/components/AddAssetClassDrawer'
import { useEntityParams } from '../../hooks/useEntityParams'
import { useAppSelector } from '../../store/useAppSelector'
import { AssetFailureCausesDataGrid } from '../../features/asset-failure-causes/components/AssetFailureCausesDataGrid'
import { ViewAssetFailureCauseDrawer } from '../../features/asset-failure-causes/components/ViewAssetFailureCauseDrawer'
import { EditAssetFailureCauseDrawer } from '../../features/asset-failure-causes/components/EditAssetFailureCauseDrawer'
import { AddAssetFailureCauseDrawer } from '../../features/asset-failure-causes/components/AddAssetFailureCauseDrawer'
//import { AssetClassViewDrawer } from '../../features/asset-failure-causes/components/AssetClassViewDrawer'
//import { EditAssetClassDrawer } from '../../features/asset-failure-causes/components/EditAssetClassDrawer'

export const AssetFailureCausesPage: React.FC = () => {
  const { entityId, addAction, viewAction, editAction, action } =
    useEntityParams()
  const [openAdd, setOpenAdd] = useState(addAction)
  const [openView, setOpenView] = useState(viewAction)
  const [openEdit, setOpenEdit] = useState(editAction)
  const [height, setHeight] = useState(null)

  const failureModes: AssetFailureCauseSchema[] = useAppSelector(
    AssetFailureCausesSelectors.selectAll
  )

  useEffect(() => {
    setOpenAdd(addAction)
  }, [addAction])

  useEffect(() => {
    setOpenView(viewAction)
  }, [viewAction])

  useEffect(() => {
    setOpenEdit(editAction)
  }, [editAction])

  const heightCalc = useMemo(() => {
    if (!height) {
      return null
    }
    return `calc(100vh - ${height + 145}px)`
  }, [height])

  return (
    <React.Fragment>
      <MeasuredContainer onMeasured={setHeight}>
        <AppToolbar>
          <Box flex={1} />
          <Button onClick={() => setOpenAdd(true)}>Add Failure Cause</Button>
          <AddAssetFailureCauseDrawer
            open={openAdd}
            onClose={() => setOpenAdd(false)}
          />
        </AppToolbar>
      </MeasuredContainer>
      <Box height={heightCalc}>
        <Paper sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
          <AssetFailureCausesDataGrid schemas={failureModes} />
        </Paper>
      </Box>
      <ViewAssetFailureCauseDrawer
        open={openView}
        onClose={() => setOpenView(false)}
      />
      <EditAssetFailureCauseDrawer
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      />
    </React.Fragment>
  )
  //return
}
