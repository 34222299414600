import { AssetClassFailureModeSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { Box, Button, Typography } from '@mui/material'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'
import { AssetClassFailureModeForm } from './forms/AssetClassFailureModeForm'
import hash_object from 'hash-object'
import { useAddAssetClassFailureModeQuery } from '../hooks/useAddAssetClassFailureModeQuery'
import { AssetClassesActions } from '../../asset-classes/store/store-config'

const initializeSchema = (data?: Partial<AssetClassFailureModeSchema>) => {
  return new AssetClassFailureModeSchema(data)
}

interface AddAssetClassFailureModeDrawerProps {
  assetClass: AssetClassModel
  onChange: (assetClass: AssetClassModel) => void
  open: boolean
  onClose: () => void
}

export const AddAssetClassFailureModeDrawer: React.FC<
  AddAssetClassFailureModeDrawerProps
> = ({ assetClass, onChange = () => {}, open, onClose }) => {
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { addAssetClassFailureMode, processing } =
    useAddAssetClassFailureModeQuery()
  const { refreshComponent } = useRefreshComponent()
  const [failureMode, setEventTrigger] = useState<AssetClassFailureModeSchema>(
    initializeSchema({
      assetClassId: assetClass.id
    })
  )

  const handleOnFieldChange = () => {
    refreshComponent()
  }

  const handleOnAdd = () => {
    if (!assetClass.isFailureModeCodeUnique(failureMode)) {
      failureMode.setErrors({
        code: 'The code must be unique within the class.'
      })
      refreshComponent()
    } else if (failureMode.validate()) {
      if (!_.isArray(assetClass.failureModes)) {
        assetClass.failureModes = []
      }
      addAssetClassFailureMode(failureMode)
        .then((failureMode) => {
          assetClass.failureModes.push(failureMode)
          setEventTrigger(
            initializeSchema({
              assetClassId: assetClass.id
            })
          )
          AssetClassesActions.updateOne(assetClass)
          onChange(assetClass)
          addSuccessMessage('The Event Trigger added successfully.')
        })
        .catch((err) => {
          addErrorMessage('Failed to create the Event Trigger.')
        })

      refreshComponent()
    } else {
      refreshComponent()
    }
  }

  if (!assetClass || !failureMode) {
    return null
  }

  return (
    <>
      <AppDrawer
        minWidth={400}
        width={'40vw'}
        open={open}
        onClose={() => onClose()}
      >
        <AppDrawerToolbar>
          <Typography
            style={{ flex: 1 }}
            variant={'body1'}
            fontWeight={'bold'}
            color={'inherit'}
          >
            {assetClass?.name} - Add Trigger
          </Typography>
        </AppDrawerToolbar>
        <AppDrawerContent>
          <ContentContainer>
            <AssetClassFailureModeForm
              schema={failureMode}
              onChange={handleOnFieldChange}
            />
            <Box display={'flex'}>
              <Box flex={1}></Box>
              <Button onClick={handleOnAdd} disabled={processing}>
                Add
              </Button>
            </Box>
          </ContentContainer>
        </AppDrawerContent>
      </AppDrawer>
    </>
  )
}
