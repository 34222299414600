import React, { useCallback, useEffect, useState } from 'react'
import { AWSIoTProvider, PubSub } from '@aws-amplify/pubsub'
import Config from '../../../Config'
import { v4 as uuid } from 'uuid'

export interface AmlifyPubsubContextProps {
  clientId: string
  //provider: AWSIoTProvider
}

export const AmlifyPubsubContext =
  React.createContext<AmlifyPubsubContextProps>({
    clientId: null
    //provider: null
  })

export const AmplifyPubsubProvider: React.FC = ({ children }) => {
  const [clientId, setClientId] = useState<string>(null)
  //const [provider, setProvider] = useState<AWSIoTProvider>(null)

  const connect = useCallback(() => {
    const clientId = uuid()
    const provider = new AWSIoTProvider({
      clientId: clientId,
      aws_pubsub_region: Config.mqtt.REGION,
      aws_pubsub_endpoint:
        'wss://' +
        Config.mqtt.ID +
        '.iot.' +
        Config.mqtt.REGION +
        '.amazonaws.com/mqtt'
    })
    PubSub.addPluggable(provider)
      .then((plug: any) => {
        setClientId(plug.clientId)
      })
      .catch((err) => {
        console.error(err)
        setClientId(null)
      })

    setClientId(clientId)
  }, [])

  const disconnect = useCallback(() => {
    PubSub.removePluggable('AWSIoTProvider')
    setClientId(null)
  }, [])

  const reconnect = useCallback(() => {
    disconnect()
    connect()
  }, [])

  useEffect(() => {
    connect()

    return () => {
      disconnect()
    }
  }, [])

  useEffect(() => {}, [])

  const ctx = {
    clientId
    //provider
  }

  return (
    <AmlifyPubsubContext.Provider value={ctx}>
      {children}
    </AmlifyPubsubContext.Provider>
  )
}
