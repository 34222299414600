import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  AppTabProps,
  AppTabs,
  AppTabPanel,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { formatLocalDate } from '@loadsys-cmms/app-sdk'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { AssetModelsSelectors } from '../store/store-config'
import { LocationName } from '../../locations/components/LocationName'
import { AssetClassName } from '../../asset-classes/components/AssetClassName'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetModelHiearchies } from './AssetModelChildren'
import { AssetModelParents } from './AssetModelParents'
import { AssetClassesSelectors } from '../../asset-classes/store/store-config'
import { AssetModelAssets } from './AssetModelAssets'

const tabs: AppTabProps[] = [
  { value: 'details', label: 'Details' },
  { value: 'assets', label: 'Assets' }
]

interface ViewAssetModelDrawerProps {
  open: boolean
  onClose: () => void
}

export const ViewAssetModelDrawer: React.FC<ViewAssetModelDrawerProps> = ({
  open,
  onClose
}) => {
  const { entityId } = useEntityParams()

  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()

  const schema = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, entityId)
  )

  const assetClass = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, schema?.assetClassId)
  )

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/models')
    onClose()
  }, [])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          {schema?.name}
        </Typography>
        <Button onClick={() => navigate(`${entityId}/edit`)}>Edit</Button>
        {/*<Button onClick={() => navigate(`${entityId}/delete`)}>Delete</Button>*/}
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <>
            <AppTabs tabs={tabs} selected={'details'} hidden={true}>
              <AppTabPanel value={'details'}>
                <Paper
                  sx={{
                    paddingTop: theme.spacing(1),
                    marginBottom: theme.spacing(1)
                  }}
                >
                  <Container>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Asset Class
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <AssetClassName
                              assetClassId={schema.assetClassId}
                              link
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Type
                            </Typography>
                          </TableCell>
                          <TableCell>{assetClass?.type}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Name
                            </Typography>
                          </TableCell>
                          <TableCell>{schema.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Code
                            </Typography>
                          </TableCell>
                          <TableCell>{schema.code}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Description
                            </Typography>
                          </TableCell>
                          <TableCell>{schema.description}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'body2'} fontWeight={'bold'}>
                              Sync
                            </Typography>
                          </TableCell>
                          <TableCell>{schema?.awsSyncStatus}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Container>
                </Paper>
                <Typography variant={'subtitle2'}>Parents</Typography>
                <ContentContainer>
                  <AssetModelParents schema={schema} />
                </ContentContainer>
                <Typography variant={'subtitle2'}>
                  Systems/Components
                </Typography>
                <ContentContainer>
                  <AssetModelHiearchies schema={schema} />
                </ContentContainer>
              </AppTabPanel>
              <AppTabPanel value={'assets'}>
                <ContentContainer>
                  <AssetModelAssets assetModelId={schema?.id} offset={200} />
                </ContentContainer>
              </AppTabPanel>
            </AppTabs>
          </>
        )}
      </AppDrawerContent>
    </AppDrawer>
  )
}
