import { AssetModelPropertySchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import { SchemaAWSAssetModelDataTypePicker } from '../pickers/SchemaAWSAssetModelDataTypePicker'

interface AWSAssetModelMeasurementFormProps {
  schema: AssetModelPropertySchema
  onChange: (schema: AssetModelPropertySchema) => void
}

export const AWSAssetModelMeasurementForm: React.FC<
  AWSAssetModelMeasurementFormProps
> = ({ schema, onChange = () => {} }) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaTextInput
        schema={schema}
        field={'name'}
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        required
      />
      <SchemaTextInput
        schema={schema}
        field={'unit'}
        label={'Unit'}
        placeholder={'Unit'}
        onChange={handleOnFieldChange}
        helperText={
          'The scientific unit for the property, such as mm or Celsius.'
        }
      />
      <SchemaAWSAssetModelDataTypePicker
        schema={schema}
        field={'dataType'}
        label={'Type'}
        onChange={handleOnFieldChange}
        required
      />
    </>
  )
}
