import _ from "lodash"
import { useMemo } from "react"
import { useParams } from "react-router-dom"

export const useEntityParams = () => {
  const { entityId, action } = useParams()

  const addAction = useMemo(() => {
    return entityId === 'add' || action === 'add'
  }, [entityId, action])

  const viewAction = useMemo(() => {
    return !_.isEmpty(entityId) && (!action || action === 'view')
  }, [entityId, action])

  const editAction = useMemo(() => {
    return !!entityId && action === 'edit'
  }, [entityId, action])

  const deleteAction = useMemo(() => {
    return !!entityId && action === 'delete'
  }, [entityId, action])

  return {
    entityId,
    addAction,
    editAction,
    viewAction,
    deleteAction,
    action,
  }
}