import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetFailureCausesSelectors } from '../store/store-config'

export interface AssetFailureCauseNameValueProps {
  assetFailureCauseId: string
}

export const AssetFailureCauseNameValue: React.FC<
  AssetFailureCauseNameValueProps
> = ({ assetFailureCauseId }) => {
  const failureCause = useAppSelector((state) =>
    AssetFailureCausesSelectors.selectById(state, assetFailureCauseId)
  )
  return <>{failureCause?.name}</>
}
