import { AssetClassSchema, LocationSchema } from '@loadsys-cmms/app-sdk'
import { AppDataGrid } from '@loadsys-cmms/ui-components'
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 300
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300
  }
]

interface LocationsDataGridProps {
  schemas: LocationSchema[]
}

export const LocationsDataGrid: React.FC<LocationsDataGridProps> = ({
  schemas
}) => {
  const navigate = useNavigate()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection
    }
  ])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    navigate(`/location-management/locations/${params.row.id}`)
  }

  return (
    <AppDataGrid
      density={'compact'}
      getRowId={(model: AssetClassSchema) => model.id}
      sortModel={sortModel}
      columns={columns}
      rows={schemas}
      onCellClick={handleOnCellClick}
    />
  )
}
