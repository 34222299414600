import { BaseSchema } from '@loadsys-cmms/app-sdk'
import {
  AssetModelTypePicker,
  AssetModelTypePickerProps
} from './AssetModelTypePicker'

export interface SchemaAssetModelTypePickerProps
  extends Omit<AssetModelTypePickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaAssetModelTypePicker: React.FC<
  SchemaAssetModelTypePickerProps
> = ({ schema, field, helperText, onChange, ...props }) => {
  return (
    <AssetModelTypePicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value) => onChange(value, field)}
      {...props}
    />
  )
}
