import { AssetSchema } from "@loadsys-cmms/app-sdk"
import createStoreAdapter from "../../../store/createStoreAdapter"

const STORE_NAME = 'assets'

const adapter = createStoreAdapter<AssetSchema>({
  classType: AssetSchema,
  storeName: STORE_NAME,
  apiParams: { name: 'assetsApi', 'baseUrl': '/assets' },
  selectIdFn: (entity: AssetSchema) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const AssetsReducer = adapter.reducer
export const AssetsSelectors = adapter.selectors
export const AssetsThunks = adapter.thunks
export const AssetsActions = adapter.actions

export default adapter
