import { LoginSchema } from '@loadsys-cmms/app-sdk'
import { CognitoUser } from '@aws-amplify/auth'
import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { useAppPubSub } from '../hooks'
import { useSnackbarMessages } from '../hooks/useSnackbarMessages'
import { LoginForm } from './LoginForm'

interface AmplifyLoginComponentProps {
  onLogin?: (user: CognitoUser) => void
}

export const AmplifyLoginComponent: React.FC<AmplifyLoginComponentProps> = ({
  onLogin = () => {}
}) => {
  const appPubSub = useAppPubSub()
  const messages = useSnackbarMessages()

  const handleOnSubmit = React.useCallback((loginData: LoginSchema) => {
    if (!loginData) {
      return
    }
    loginData.challenge = 'test'
    if (loginData?.validate()) {
      console.log('logging in')
      Auth.signIn(loginData.email, loginData.password)
        .then((user) => {
          appPubSub.publish('Auth.state', {
            loggedIn: true,
            user: user
          })
          messages.addSuccessMessage('Login successful.')
          onLogin(user)
        })
        .catch((e: Error) => {
          console.log('err', e)
          messages.addErrorMessage('Login failed - ' + e)
          appPubSub.publish('Auth.state', {
            loggedIn: false,
            user: null
          })
        })
    } else {
      console.log('Validation errors', loginData.getErrors())
    }
  }, [])

  return <LoginForm onSubmit={handleOnSubmit} />
}
