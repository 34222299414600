const config = {
  appName: process.env.REACT_APP_NAME,
  appStage: process.env.REACT_APP_STAGE,
  s3: {},
  api: {
    assetsApi: process.env.REACT_APP_ASSETS_API,
    usersApi: process.env.REACT_APP_USERS_API,
    assetModelsApi: process.env.REACT_APP_ASSET_MODELS_API,
    eventsApi: process.env.REACT_APP_EVENTS_API
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  mqtt: {
    REGION: process.env.REACT_APP_MQTT_REGION,
    ID: process.env.REACT_APP_MQTT_ID,
  }
};

export default config;
