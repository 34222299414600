import { AssetModelSchema } from '@loadsys-cmms/app-sdk'
import { AppToolbar, MeasuredContainer } from '@loadsys-cmms/ui-components'
import { Box, Button, Paper } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { AssetModelsDataGrid } from '../../features/asset-models/components/AssetModelsDataGrid'
import { AddAssetModelDrawer } from '../../features/asset-models/components/AddAssetModelDrawer'
import { AssetModelsSelectors } from '../../features/asset-models/store/store-config'
import { useAppSelector } from '../../store/useAppSelector'
import { useEntityParams } from '../../hooks/useEntityParams'
import { ViewAssetModelDrawer } from '../../features/asset-models/components/ViewAssetModelDrawer'
import { EditAssetModelDrawer } from '../../features/asset-models/components/EditAssetModelDrawer'

export const AssetModelsPage: React.FC = () => {
  const { entityId, addAction, viewAction, editAction } = useEntityParams()
  const [openAdd, setOpenAdd] = useState(addAction)
  const [openView, setOpenView] = useState(viewAction)
  const [openEdit, setOpenEdit] = useState(editAction)
  const [height, setHeight] = useState(null)

  useEffect(() => {
    setOpenAdd(addAction)
  }, [addAction])

  useEffect(() => {
    setOpenView(viewAction)
  }, [viewAction])

  useEffect(() => {
    setOpenEdit(editAction)
  }, [editAction])

  const assetModels: AssetModelSchema[] = useAppSelector(
    AssetModelsSelectors.selectAll
  )

  const heightCalc = useMemo(() => {
    if (!height) {
      return null
    }
    return `calc(100vh - ${height + 145}px)`
  }, [height])

  return (
    <React.Fragment>
      <MeasuredContainer onMeasured={setHeight}>
        <AppToolbar>
          <Box flex={1} />
          <Button onClick={() => setOpenAdd(true)}>Add Model</Button>
          <AddAssetModelDrawer
            open={openAdd}
            onClose={() => setOpenAdd(false)}
          />
        </AppToolbar>
      </MeasuredContainer>
      <Box height={heightCalc}>
        <Paper sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
          <AssetModelsDataGrid schemas={assetModels} />
        </Paper>
      </Box>
      <ViewAssetModelDrawer
        open={openView}
        onClose={() => setOpenView(false)}
      />
      <EditAssetModelDrawer
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      />
    </React.Fragment>
  )
  //return
}
