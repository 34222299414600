import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { AssetClassPicker, AssetClassPickerProps } from './AssetClassPicker'

export interface SchemaAssetClassPickerProps
  extends Omit<AssetClassPickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaAssetClassPicker: React.FC<SchemaAssetClassPickerProps> = ({
  schema,
  field,
  helperText,
  onChange,
  ...props
}) => {
  return (
    <AssetClassPicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: string) => onChange(value, field)}
      {...props}
    />
  )
}
