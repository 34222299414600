import {
  AutocompleteInput,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo } from 'react'

const OPTIONS: InputOptionProps[] = [
  {
    label: 'String',
    value: 'STRING'
  },
  {
    label: 'Integer',
    value: 'INTEGER'
  },
  {
    label: 'Double',
    value: 'DOUBLE'
  },
  {
    label: 'Boolean',
    value: 'BOOLEAN'
  }
]

export interface AWSAssetModelDataTypePickerProps {
  value?: string
  className?: any
  label?: string
  helperText?: string
  onChange: (value: string) => void
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  enableCreate?: boolean
  error?: boolean
  allowedTypes?: string[]
  margin?: string
}

export const AWSAssetModelDataTypePicker: React.FC<
  AWSAssetModelDataTypePickerProps
> = ({
  className,
  value,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  noOptionsText,
  clearOnBlur,
  error,
  allowedTypes,
  margin
}) => {
  const filteredOptions = useMemo(() => {
    if (allowedTypes && _.isArray(allowedTypes)) {
      return _.filter(OPTIONS, (option) =>
        _.includes(allowedTypes, option.value)
      )
    }
    return OPTIONS
  }, [allowedTypes])

  const options: InputOptionProps[] = useMemo(() => {
    return filteredOptions
  }, [filteredOptions])

  return (
    <>
      <AutocompleteInput
        value={value}
        className={className}
        label={label}
        helperText={helperText}
        onChange={(value) => onChange(value as string)}
        disabled={disabled}
        required={required}
        variant={variant}
        placeholder={placeholder}
        freeSolo={freeSolo}
        options={options}
        noOptionsText={noOptionsText}
        clearOnBlur={clearOnBlur}
        error={error}
        margin={margin}
      />
    </>
  )
}
