import {
  AssetClassPropertySchema,
  AssetClassPropertyType
} from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent
} from '@loadsys-cmms/ui-components'
import { Box, Button, Typography } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetClassModel } from '../models/AssetClassModel'
import { AssetClassTransformForm } from './forms/AssetClassTransformForm'

const initializeSchema = () => {
  return new AssetClassPropertySchema({
    type: AssetClassPropertyType.Transform
  })
}

interface AddAssetClassTransformDrawerProps {
  schema: AssetClassModel
  onChange: (schema: AssetClassModel) => void
}

export const AddAssetClassTransformDrawer: React.FC<
  AddAssetClassTransformDrawerProps
> = ({ schema, onChange = () => {} }) => {
  const [open, setOpen] = useState(false)
  const { refreshComponent } = useRefreshComponent()
  const [transform, setTransform] = useState<AssetClassPropertySchema>(
    initializeSchema()
  )
  const handleOnFieldChange = () => {
    refreshComponent()
  }

  const handleOnAdd = () => {
    if (!schema.isPropertyCodeUnique(transform)) {
      transform.setErrors({
        code: 'The code must be unique within the class.'
      })
      refreshComponent()
    } else if (!schema.isPropertyNameUnique(transform)) {
      transform.setErrors({
        code: 'The name must be unique within the class.'
      })
      refreshComponent()
    } else if (transform.validate()) {
      if (!_.isArray(schema.properties)) {
        schema.properties = []
      }
      schema.properties.push(transform)
      setTransform(initializeSchema())
      onChange(schema)
      refreshComponent()
    } else {
      refreshComponent()
    }
  }

  if (!schema || !transform) {
    return null
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>Add</Button>
      <AppDrawer
        minWidth={400}
        width={'40vw'}
        open={open}
        onClose={() => setOpen(false)}
      >
        <AppDrawerToolbar>
          <Typography
            style={{ flex: 1 }}
            variant={'body1'}
            fontWeight={'bold'}
            color={'inherit'}
          >
            {schema?.name}
          </Typography>
        </AppDrawerToolbar>
        <AppDrawerContent>
          <ContentContainer>
            <AssetClassTransformForm
              schema={transform}
              onChange={handleOnFieldChange}
            />
            <Box display={'flex'}>
              <Box flex={1}></Box>
              <Button onClick={handleOnAdd}>Add</Button>
            </Box>
          </ContentContainer>
        </AppDrawerContent>
      </AppDrawer>
    </>
  )
}
