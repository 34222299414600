import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { memoize } from 'lodash'
import { AppRootState } from '../../../store/AppStore'
import { AssetFailureModesSelectors } from '../store/store-config'

export const getAssetFailureModeSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return AssetFailureModesSelectors.selectById(state, id)
    }),
)

export const assetFailureModeSelector = createSelector(
  getAssetFailureModeSelector,
  (state: AppRootState, failureModeId: string) => failureModeId,
  (getFailureMode, failureModeId) =>
    getFailureMode(failureModeId)
)

export const getAssetFailureModeCauseSelector = createSelector(
  getAssetFailureModeSelector,
  (getFailureMode) =>
    (failureModeId: string, failureCauseId: string) => {
      const failureMode = getFailureMode(failureModeId)
      return _.find(failureMode?.failureCauses, cause => cause.assetFailureCauseId === failureCauseId)
    },
)

export const assetFailureModeCauseSelector = createSelector(
  getAssetFailureModeCauseSelector,
  (state: AppRootState, failureModeId: string) => failureModeId,
  (state: AppRootState, failureCauseId: string) => failureCauseId,
  (getAssetFailureModeCause, failureModeId, failureCauseId) =>
    getAssetFailureModeCause(failureModeId, failureCauseId)
)