import { AssetClassPropertySchema, AssetClassEventTriggerSchema, AssetClassFailureModeSchema, AssetClassSchema, AssetPropertyDataType, AssetModelPropertySchema, AssetClassPropertyType } from "@loadsys-cmms/app-sdk";
import _ from "lodash";

export const ReservedAWSPropertyNames = [
  'Status',
  'Idle_State',
  'Failure_State',
  'Maintenance_State',
  'Down_State',
  'Operating_State',
  'Failure_Count',
  'Maintenance_Count',
  'Downtime_Count',
  'Uptime',
  'Downtime',
  'Maintenance_Time',
  'Failure_Time',
]

export class AssetClassModel extends AssetClassSchema {
  /**
   * Validate if the target has a unique code
   * @param target
   * @returns
   */
  public isFailureModeCodeUnique (target: AssetClassFailureModeSchema) {
    const failureModes = _.filter(this.failureModes, mode => mode !== target)
    const codes = _.map(failureModes, mode => mode.code)
    return !_.includes(codes, target.code)
  }

  /**
   * Check if the metric name is unique
   * @param target
   * @returns
   */
  public isPropertyNameUnique (target: AssetClassPropertySchema) {
    const properties = _.filter(this.properties, property => property !== target)
    const names = _.map(properties, property => property.name)
    return !_.includes(names, target.name)
  }

  /**
   * Check if the metric code is unique
   * @param target
   * @returns
   */
  public isPropertyCodeUnique (target: AssetClassPropertySchema) {
    const properties = _.filter(this.properties, property => property !== target)
    const codes = _.map(properties, property => property.code)
    return !_.includes(codes, target.code)
  }

  /**
  * Check if the trigger code is unique
  * @param target
  * @returns
  */
  public isEventCodeUnique (target: AssetClassEventTriggerSchema) {
    const triggers = _.filter(this.eventTriggers, trigger => trigger !== target)
    const codes = _.map(triggers, trigger => trigger.code)
    return !_.includes(codes, target.code)
  }

  /**
  * Check if the trigger name is unique
  * @param target
  * @returns
  */
  public isEventNameUnique (target: AssetClassEventTriggerSchema) {
    const triggers = _.filter(this.eventTriggers, trigger => trigger !== target)
    const names = _.map(triggers, trigger => trigger.name)
    return !_.includes(names, target.name)
  }

  /**
   * Validate if the property name is unique
   * @param target
   * @returns
   */
  public isAWSPropertyNameUnique (target: AssetModelPropertySchema) {
    const properties = _.filter(this.modelSchema?.assetModelProperties, mode => mode !== target)
    const names = _.map(properties, property => property.name)
    return !_.includes(names, target.name)
  }

  public isAWSPropertyNameReserved (target: AssetModelPropertySchema) {
    return !_.includes(ReservedAWSPropertyNames, target.name)
  }

  public getMetricInputOptions (type?: AssetPropertyDataType[]) {
    const metrics = _.filter(this.properties, (metric) => _.isEmpty(type) || _.includes(type, metric.dataType))
    return _.map(metrics, metric => metric.getPickerInputOption())
  }

  public getAttribubes () {
    return _.filter(this.properties, (property) => property.type === AssetClassPropertyType.Attribute)
  }

  public getMetrics () {
    return _.filter(this.properties, (property) => property.type === AssetClassPropertyType.Metric)
  }

  public getTransforms () {
    return _.filter(this.properties, (property) => property.type === AssetClassPropertyType.Transform)
  }
}