declare const window: any

import PubSubJS from 'pubsub-js'

export const useAppPubSub = () => {
  const appName = process.env.REACT_APP_NAME
  const PubSub =
    appName && window[appName] && window[appName].PubSub
      ? window[appName].PubSub
      : PubSubJS

  return PubSub
}
