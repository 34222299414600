import { AssetClassSchema, EventTypeSchema } from '@loadsys-cmms/app-sdk'
import { AppDataGrid } from '@loadsys-cmms/ui-components'
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 150
  }
]

interface EventTypesDataGridProps {
  schemas: EventTypeSchema[]
}

export const EventTypesDataGrid: React.FC<EventTypesDataGridProps> = ({
  schemas
}) => {
  const navigate = useNavigate()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection
    }
  ])

  const handleOnCellClick = (params: GridCellParams) => {
    navigate(params.row.id)
  }

  return (
    <AppDataGrid
      density={'compact'}
      getRowId={(model: EventTypeSchema) => model.id}
      sortModel={sortModel}
      columns={columns}
      rows={schemas}
      onCellClick={handleOnCellClick}
    />
  )
}
