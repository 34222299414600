import { AssetSchema } from '@loadsys-cmms/app-sdk'
import { AppToolbar, MeasuredContainer } from '@loadsys-cmms/ui-components'
import { Box, Button, Paper } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { AddAssetDrawer } from '../../features/assets/components/AddAssetDrawer'
import { AssetsDataGrid } from '../../features/assets/components/AssetsDataGrid'
import { EditAssetDrawer } from '../../features/assets/components/EditAssetDrawer'
import { PutAssetPropertiesDrawer } from '../../features/assets/components/PutAssetPropertiesDrawer'
import { ViewAssetDrawer } from '../../features/assets/components/ViewAssetDrawer'
import { AssetsSelectors } from '../../features/assets/store/store-config'
import { useEntityParams } from '../../hooks/useEntityParams'
import { useAppSelector } from '../../store/useAppSelector'

export const AssetsPage: React.FC = () => {
  const { entityId, addAction, viewAction, editAction, action } =
    useEntityParams()
  const [openAdd, setOpenAdd] = useState(addAction)
  const [openView, setOpenView] = useState(viewAction)
  const [openEdit, setOpenEdit] = useState(editAction)
  const [openPutProperties, setOpenPutProperties] = useState(editAction)
  const [height, setHeight] = useState(null)

  const assets: AssetSchema[] = useAppSelector(AssetsSelectors.selectAll)

  useEffect(() => {
    setOpenAdd(addAction)
  }, [addAction])

  useEffect(() => {
    setOpenView(viewAction)
  }, [viewAction])

  useEffect(() => {
    setOpenEdit(editAction)
  }, [editAction])

  useEffect(() => {
    setOpenPutProperties(action === 'properties')
  }, [action])

  const heightCalc = useMemo(() => {
    if (!height) {
      return null
    }
    return `calc(100vh - ${height + 145}px)`
  }, [height])

  return (
    <React.Fragment>
      <MeasuredContainer onMeasured={setHeight}>
        <AppToolbar>
          <Box flex={1} />
          <Button onClick={() => setOpenAdd(true)}>Add Asset</Button>
          <AddAssetDrawer open={openAdd} onClose={() => setOpenAdd(false)} />
        </AppToolbar>
      </MeasuredContainer>
      <Box height={heightCalc}>
        <Paper sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
          <AssetsDataGrid schemas={assets} />
        </Paper>
      </Box>
      <ViewAssetDrawer open={openView} onClose={() => setOpenView(false)} />
      <EditAssetDrawer open={openEdit} onClose={() => setOpenEdit(false)} />
      <PutAssetPropertiesDrawer
        open={openPutProperties}
        onClose={() => setOpenPutProperties(false)}
      />
    </React.Fragment>
  )
  //return
}
