//import { Attribute } from "@aws-sdk/client-iotsitewise";
import { Expose } from "class-transformer";
import { IsOptional, IsString } from "class-validator";
import { BaseSchema } from "../../BaseSchema";

export class AssetModelPropertyTypeAttributeSchema extends BaseSchema /*implements Attribute*/ {
  @Expose()
  @IsString()
  @IsOptional()
  defaultValue: string;
}