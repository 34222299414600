import {
  AssetModelHierarchySchema,
  AssetModelSchema
} from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import { plainToClass } from 'class-transformer'
import _ from 'lodash'
import hash_object from 'hash-object'

import { useCallback, useEffect, useMemo } from 'react'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetClassName } from '../../asset-classes/components/AssetClassName'
import { getAssetClassSelector } from '../../asset-classes/selectors/selectors'
import { AssetClassesSelectors } from '../../asset-classes/store/store-config'
import { getAssetModelSelector } from '../selectors/selectors'

import { AssetModelName } from './AssetModelName'
import { AssetModelTypeValue } from './AssetModelTypeValue'
import { AssetModelPicker } from './pickers/AssetModelPicker'
import { SchemaAssetModelPicker } from './pickers/SchemaAssetModelPicker'

interface AssetModelAssetModelHiearchiesProps {
  schema: AssetModelSchema
  onChange?: (schema: AssetModelSchema) => void
  enableEdit?: boolean
}

export const AssetModelHiearchies: React.FC<
  AssetModelAssetModelHiearchiesProps
> = ({ schema, onChange = () => {}, enableEdit }) => {
  const getAssetModel = useAppSelector(getAssetModelSelector)
  const getAssetClass = useAppSelector(getAssetClassSelector)

  const assetClass = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, schema?.assetClassId)
  )
  const handleOnFieldChange = useCallback(() => {
    onChange(schema)
  }, [schema, onChange])

  const hierarchies = useMemo(() => {
    if (!assetClass?.hierarchies) {
      return []
    }
    for (const classHierarchy of assetClass.hierarchies) {
      const schemaHierarchy = _.find(schema?.hierarchies, (hierarchy) => {
        return hierarchy.assetClassHierarchyId === classHierarchy.id
      })
      if (!schemaHierarchy) {
        schema.hierarchies.push(
          new AssetModelHierarchySchema({
            name: classHierarchy.name,
            assetClassHierarchyId: classHierarchy.id
          })
        )
      }
    }
    return schema.hierarchies
  }, [assetClass?.id, schema?.id])

  const classHierarchies = useMemo(() => {
    return _.keyBy(assetClass?.hierarchies, (hierarchy) => hierarchy.id)
  }, [assetClass])

  const handleOnDelete = (child: AssetModelHierarchySchema) => {
    if (schema.hierarchies) {
      const index = _.findIndex(schema.hierarchies, (c) => c === child)
      if (index >= 0) {
        schema.hierarchies.splice(index, 1)
        onChange(schema)
      }
    }
  }

  if (!schema || !assetClass) {
    return null
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'20%'}>Class</TableCell>
            <TableCell width={'30%'}>Name</TableCell>
            <TableCell width={'20%'}>Code</TableCell>
            <TableCell width={'30%'}>Model</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(hierarchies, (child, index) => {
            const classHierarchy = classHierarchies[child.assetClassHierarchyId]
            return (
              <TableRow key={child.id ?? index}>
                <TableCell>
                  <AssetClassName
                    assetClassId={classHierarchy?.assetClassId}
                    link={!enableEdit}
                  />
                </TableCell>
                <TableCell>{classHierarchy?.name}</TableCell>
                <TableCell>{classHierarchy?.code}</TableCell>
                <TableCell>
                  {!enableEdit && (
                    <AssetModelName
                      assetModelId={child.childAssetModelId}
                      linkToModel
                    />
                  )}
                  {enableEdit && (
                    <SchemaAssetModelPicker
                      schema={child}
                      field={'childAssetModelId'}
                      assetClassId={classHierarchy?.childAssetClassId}
                      onChange={handleOnFieldChange}
                      variant={'standard'}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
