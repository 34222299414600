import { Type } from 'class-transformer'
import { IsEmail, IsNotEmpty, IsOptional, IsUUID } from 'class-validator'
import _ from 'lodash'

import { BaseSchema } from '../BaseSchema'

export class UserSchema extends BaseSchema {
  /**
   * User ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * First Name
   */
  @IsNotEmpty()
  firstName: string = null

  /**
   * Last Name
   */
  @IsNotEmpty()
  lastName: string = null

  /**
   * Email
   */
  @IsNotEmpty()
  @IsEmail()
  email: string = null

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   * Construct
   * @param data
   */
  constructor(data?: Partial<UserSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Get full name for a user
   * @returns string Full name
   */
  getFullName () {
    return `${this.firstName} ${this.lastName}`
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.lastName}, ${this.firstName}`
  }
}