import { BaseSchema } from '@loadsys-cmms/app-sdk'
import {
  AWSAssetModelDataTypePicker,
  AWSAssetModelDataTypePickerProps
} from './AWSAssetModelDataTypePicker'

export interface SchemaAWSAssetModelDataTypePickerProps
  extends Omit<AWSAssetModelDataTypePickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaAWSAssetModelDataTypePicker: React.FC<
  SchemaAWSAssetModelDataTypePickerProps
> = ({ schema, field, helperText, onChange, ...props }) => {
  return (
    <AWSAssetModelDataTypePicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value) => onChange(value, field)}
      {...props}
    />
  )
}
