import { IsEmail, IsNotEmpty, IsString } from 'class-validator'
import _ from 'lodash'

import { BaseSchema } from '../BaseSchema'

export class EmailConfirmationSchema extends BaseSchema {
  /**
   * Email
   */
  @IsNotEmpty()
  @IsEmail()
  email: string = null

  /**
   * Confirmation
   */
  @IsString()
  @IsNotEmpty()
  confirmationCode: string = null


  /**
   * Construct
   * @param data
   */
  constructor(data?: Partial<EmailConfirmationSchema>) {
    super()
    _.assign(this, data)
  }
}