import { AssetFailureModeSchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'

interface AssetFailureModeFormProps {
  schema: AssetFailureModeSchema
  onChange: (schema: AssetFailureModeSchema) => void
}

export const AssetFailureModeForm: React.FC<AssetFailureModeFormProps> = ({
  schema,
  onChange = () => {}
}) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
      />
      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
      />
      <SchemaTextInput
        label={'Description'}
        placeholder={'Description'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'description'}
      />
    </>
  )
}
