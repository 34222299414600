import { createSelector } from '@reduxjs/toolkit'
import { memoize } from 'lodash'
import { AppRootState } from '../../../store/AppStore'
import { LocationsSelectors } from '../store/store-config'

export const getLocationSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return LocationsSelectors.selectById(state, id)
    }),
)

export const LocationSelector = createSelector(
  getLocationSelector,
  (getLocation) =>
    memoize((id: string) => {
      return getLocation(id)
    }),
)
