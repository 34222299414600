import { Button, Grid, Link, Typography } from '@mui/material'
import { SignupSchema } from '@loadsys-cmms/app-sdk'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Link as RouterLink } from 'react-router-dom'

import React from 'react'
import { useState } from 'react'
import { SchemaTextInput } from '../inputs/schema/SchemaTextInput'
import { Avatar, Box } from '@mui/material'

interface LoginFormProps {
  loginData?: SignupSchema
  onChange?: (loginData: SignupSchema) => void
  onSubmit: (loginData: SignupSchema) => void
}

export const SignupForm: React.FC<LoginFormProps> = ({
  loginData: loginDataParam,
  onChange = () => {},
  onSubmit = () => {}
}) => {
  const [signupData] = useState<SignupSchema>(
    loginDataParam?.clone() || new SignupSchema()
  )
  const [hash, setHash] = useState('')

  const handleOnChange = React.useCallback(
    (value: string, field: string) => {
      signupData.setValue(field, value)
      //trigger change on data change
      setHash(signupData.generateHash())
      onChange(signupData)
    },
    [signupData, onChange]
  )

  const handleOnSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      console.log('submit')
      if (signupData?.validate()) {
        setHash(signupData.generateHash())
        onSubmit(signupData)
      } else {
        console.log('errors', signupData.getErrors())
        setHash(signupData.generateHash())
      }
    },
    []
  )

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Signup in
      </Typography>

      <Box component='form' onSubmit={handleOnSubmit} noValidate sx={{ mt: 1 }}>
        <SchemaTextInput
          schema={signupData}
          field={'firstName'}
          label={'First Name'}
          onChange={handleOnChange}
        />
        <SchemaTextInput
          schema={signupData}
          field={'lastName'}
          label={'Last Name'}
          onChange={handleOnChange}
        />
        <SchemaTextInput
          schema={signupData}
          field={'email'}
          label={'Email'}
          onChange={handleOnChange}
        />
        <SchemaTextInput
          schema={signupData}
          field={'password'}
          label={'Password'}
          onChange={handleOnChange}
          type={'password'}
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Signup In
        </Button>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Link component={RouterLink} to='/login' variant='body2'>
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
