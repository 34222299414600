import { AssetModelType } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import React, { useMemo } from 'react'

const OPTIONS: InputOptionProps[] = [
  {
    label: 'Component',
    value: AssetModelType.Component
  },
  {
    label: 'Asset',
    value: AssetModelType.Asset
  },
  {
    label: 'System',
    value: AssetModelType.System
  }
]

export interface AssetModelTypePickerProps {
  value?: string
  className?: any
  label?: string
  helperText?: string
  onChange: (value: string) => void
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  enableCreate?: boolean
  error?: boolean
}

export const AssetModelTypePicker: React.FC<AssetModelTypePickerProps> = ({
  className,
  value,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  noOptionsText,
  clearOnBlur,
  error
}) => {
  const options: InputOptionProps[] = useMemo(() => {
    return OPTIONS
  }, [])

  return (
    <AutocompleteInput
      value={value}
      className={className}
      label={label}
      helperText={helperText}
      onChange={(value) => onChange(value as string)}
      disabled={disabled}
      required={required}
      variant={variant}
      placeholder={placeholder}
      freeSolo={freeSolo}
      options={options}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      error={error}
    />
  )
}
