import { BaseSchema } from "../../BaseSchema";
import { Expose, Type } from "class-transformer";
import { ArrayMinSize, ArrayMaxSize, IsArray, IsNotEmpty, ValidateIf, ValidateNested } from "class-validator";
import { AWSAssetPropertyValueSchema } from "./AWSAssetPropertyValueSchema";
import _ from "lodash";

export class AssetPropertyValueEntrySchema extends BaseSchema {
  /**
   * <p>The user specified ID for the entry. You can use this ID to identify which entries
   *       failed.</p>
   */
  @Expose()
  @IsNotEmpty()
  entryId: string | undefined;

  /**
   * <p>The ID of the asset to update.</p>
   */
  @Expose()
  @IsNotEmpty()
  @ValidateIf((obj) => _.isEmpty(obj.propertyAlias))
  assetId?: string;

  /**
   * <p>The ID of the asset property for this entry.</p>
   */
  @Expose()
  @IsNotEmpty()
  @ValidateIf((obj) => _.isEmpty(obj.propertyAlias))
  propertyId?: string;

  /**
   * <p>The alias that identifies the property, such as an OPC-UA server data stream path
   *         (for example, <code>/company/windfarm/3/turbine/7/temperature</code>). For more information, see
   *         <a href="https://docs.aws.amazon.com/iot-sitewise/latest/userguide/connect-data-streams.html">Mapping industrial data streams to asset properties</a> in the
   *         <i>IoT SiteWise User Guide</i>.</p>
   */
  @Expose()
  @IsNotEmpty()
  @ValidateIf((obj) => _.isEmpty(obj.propertyId))
  propertyAlias?: string;

  /**
   * <p>The list of property values to upload. You can specify up to 10
   *         <code>propertyValues</code> array elements. </p>
   */
  @Expose()
  @ValidateNested()
  @IsNotEmpty()
  @IsArray()
  @Type(() => AWSAssetPropertyValueSchema)
  @ArrayMinSize(1)
  @ArrayMaxSize(10)
  propertyValues: AWSAssetPropertyValueSchema[];
}