import { IsString, IsNotEmpty, IsOptional, ValidateNested, IsNumber, IsPositive, IsArray } from 'class-validator';
import { Type, Expose, Transform } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';
import { AssetClassFailureModeSchema } from './AssetClassFailureModeSchema';
import _ from 'lodash';
import { AWSAssetModelHierarchySchema } from './AWS/AWSAssetModelHierarchySchema';
import { AssetModelPropertySchema } from './AWS/AssetModelPropertySchema';
import { AssetClassHierarchySchema } from './AssetClassHierarchySchema';
import { AssetModelType } from './types'
import { AssetClassEventTriggerSchema } from './AssetClassEventTriggerSchema';
import { AssetClassPropertySchema } from './AssetClassPropertySchema';

export class AssetClassModelSchema extends BaseSchema {
  @Expose()
  @Type(() => AWSAssetModelHierarchySchema)
  @IsOptional()
  @ValidateNested()
  assetModelHierarchies: AWSAssetModelHierarchySchema[] = []

  @Expose()
  @Type(() => AssetModelPropertySchema)
  @IsOptional()
  @ValidateNested()
  assetModelProperties: AssetModelPropertySchema[] = []
}

export class AssetClassSchema extends BaseSchema {
  /**
   * Initialize Measurement
   *
   * @param data AssetModelPropertyTypeMeasurementSchema
   */
  constructor(data?: Partial<AssetClassSchema>) {
    super()

    this.modelSchema = new AssetClassModelSchema()
    this.isEnabled = true
    this.isTemplate = false
    this.failureModes = []
    this.type = AssetModelType.System

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  version: number = 1;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  code: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  @Transform(params => {
    if (!params.value) {
      return AssetModelType.Component
    }
    return params.value
  }, { toClassOnly: true })
  type: AssetModelType;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  name: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  description: string;

  @Type(() => AssetClassModelSchema)
  @Expose()
  @IsOptional()
  @ValidateNested()
  modelSchema: AssetClassModelSchema;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  isEnabled: boolean = true;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  isTemplate: boolean = false;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  created: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  updated: Date;

  @Expose()
  @Type(() => Date)
  @IsOptional()
  deleted: Date;

  @Expose()
  @Type(() => AssetClassFailureModeSchema)
  @IsOptional()
  @ValidateNested()
  @IsArray()
  failureModes: AssetClassFailureModeSchema[]

  @Expose()
  @Type(() => AssetClassHierarchySchema)
  @IsOptional()
  @ValidateNested()
  @IsArray()
  @Transform(params => {
    if (params.obj && params.obj.type === AssetModelType.Component) {
      return []
    }
    return params.value
  })
  hierarchies: AssetClassHierarchySchema[]

  @Expose()
  @Type(() => AssetClassPropertySchema)
  @IsOptional()
  @ValidateNested()
  @IsArray()
  properties: AssetClassPropertySchema[]

  @Expose()
  @Type(() => AssetClassEventTriggerSchema)
  @IsOptional()
  @ValidateNested()
  @IsArray()
  eventTriggers: AssetClassEventTriggerSchema[]

}
