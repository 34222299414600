import { BaseSchema } from "../../BaseSchema";
// import { AssetModelPropertyDefinition } from '@aws-sdk/client-iotsitewise'
import { IsEnum, IsNotEmpty, IsOptional, IsString, ValidateNested } from "class-validator";
import { Assets } from "../types";
import { AssetModelPropertyTypeSchema } from "./AssetModelPropertyTypeSchema";
import { Expose, Type } from "class-transformer";

export class AssetModelPropertySchema extends BaseSchema /*implements AssetModelPropertyDefinition*/ {
  @Expose()
  @IsOptional()
  @IsString()
  id: string

  @Expose()
  @IsNotEmpty()
  @IsString()
  name: string

  @Expose()
  @IsNotEmpty()
  @IsEnum(Assets.PropertyDataType)
  dataType: Assets.PropertyDataType

  @Expose()
  @IsOptional()
  dataTypeSpec: string

  @Expose()
  @IsOptional()
  unit: string

  @Expose()
  @Type(() => AssetModelPropertyTypeSchema)
  @IsOptional()
  @ValidateNested()
  type: AssetModelPropertyTypeSchema

}