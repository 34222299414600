import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material'
import React, { useCallback } from 'react'
import { useSetAssetStateQuery } from '../hooks/useSetAssetStateQuery'
import { useSnackbarMessages } from '@loadsys-cmms/ui-components'
import { AssetSchema, AssetState } from '@loadsys-cmms/app-sdk'
import { PopoverPicker } from '../../../components/PopoverPicker'

interface AssetStateUpdaterProps {
  asset: AssetSchema
  onUpdated?: (status: AssetState) => void
  disabled?: boolean
}

export const AssetStateUpdater: React.FC<AssetStateUpdaterProps> = ({
  asset,
  onUpdated = () => {},
  disabled
}) => {
  const theme = useTheme()
  const { setAssetState, processing } = useSetAssetStateQuery()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()

  const hanldeUpdateStatus = useCallback(
    (state: AssetState) => {
      setAssetState(asset?.id, state)
        .then(() => {
          onUpdated(state)
        })
        .catch(() => {
          addErrorMessage('Failed to update the status')
        })
    },
    [asset?.id, setAssetState, onUpdated]
  )

  return (
    <PopoverPicker
      placeholder={'Select State'}
      value={asset.state}
      processing={processing}
      disabled={disabled}
    >
      <List dense>
        <ListItem
          button
          onClick={() => hanldeUpdateStatus(AssetState.Operating)}
        >
          <ListItemText
            primary={'Operating'}
            secondary={'The asset is operating normally.'}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => hanldeUpdateStatus(AssetState.Maintenance)}
        >
          <ListItemText
            primary={'Maintenance'}
            secondary={'The asset is offline due to maintenance.'}
          />
        </ListItem>
        <ListItem button onClick={() => hanldeUpdateStatus(AssetState.Repair)}>
          <ListItemText
            primary={'Repair'}
            secondary={'The asset is offline due to repairs.'}
          />
        </ListItem>
        <ListItem button onClick={() => hanldeUpdateStatus(AssetState.Failure)}>
          <ListItemText
            primary={'Failure'}
            secondary={'The asset operation partially or completely failed.'}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => hanldeUpdateStatus(AssetState.Decomissioned)}
        >
          <ListItemText primary={'Idle'} secondary={'The asset is Idle.'} />
        </ListItem>
        <ListItem
          button
          onClick={() => hanldeUpdateStatus(AssetState.Decomissioned)}
        >
          <ListItemText
            primary={'Decomissioned'}
            secondary={'The asset is decomissioned.'}
          />
        </ListItem>
      </List>
    </PopoverPicker>
  )
}
