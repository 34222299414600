import { AssetFailureModeCauseSchema } from '@loadsys-cmms/app-sdk'
import { useRefreshComponent } from '@loadsys-cmms/ui-components'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { AssetFailureModeCauseForm } from './forms/AssetFailureModeCauseForm'

interface AddAssetFailureModeCauseProps {
  failureCauses: AssetFailureModeCauseSchema[]
  onChange: (failureCauses: AssetFailureModeCauseSchema[]) => void
}

export const AddAssetFailureModeCause: React.FC<
  AddAssetFailureModeCauseProps
> = ({ failureCauses, onChange = () => {} }) => {
  const { refreshComponent } = useRefreshComponent()
  const [cause, setCause] = useState<AssetFailureModeCauseSchema>(
    new AssetFailureModeCauseSchema()
  )
  const handleOnFieldChange = () => {
    // onChange(failureCauses)
    refreshComponent()
  }

  const handleOnAdd = () => {
    if (!_.isArray(failureCauses) || !cause) {
      return
    }
    if (cause.validate()) {
      failureCauses.push(cause)
      setCause(new AssetFailureModeCauseSchema())
      onChange(failureCauses)
    } else {
      refreshComponent()
    }
  }

  if (!_.isArray(failureCauses)) {
    return null
  }

  return (
    <>
      <AssetFailureModeCauseForm
        schema={cause}
        onChange={handleOnFieldChange}
      />
      <Box display={'flex'}>
        <Box flex={1}></Box>
        <Button onClick={handleOnAdd}>Add</Button>
      </Box>
    </>
  )
}
