import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit'
import createFetchEntityThunk from './createFetchEntityThunk'
import createUpdateOneReducer from './createUpdateOneRecuder'
import createFetchEntitiesThunk from './createFetchEntitiesThunk'
import createUpdateManyReducer from './createUpdateManyReducer'

import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { Class } from 'type-fest'
import _ from 'lodash'

export interface createStoreAdapterProps<S> {
  classType: Class<S>,
  storeName: string,
  apiParams: { name: string, baseUrl: string },
  selectIdFn?: (entity: S) => string,
  getFetchEntitiesResult?: (result: any) => any,
  getFetchEntityResult?: (result: any) => any,
}

interface EntityStoreState<S> extends EntityState<S> {
  loading: string
  dateLoaded: Date
  invalidated: boolean
}


export default function createStoreAdapter<S extends BaseSchema> (props: createStoreAdapterProps<S>) {
  const fetchEntities = createFetchEntitiesThunk<S>(props.classType, props.storeName, props.apiParams, props.getFetchEntitiesResult)
  const fetchEntity = createFetchEntityThunk<S>(props.storeName, props.apiParams, props.getFetchEntityResult)

  const EntityAdapter = createEntityAdapter<S>({
    selectId: (entity) => props.selectIdFn ? props.selectIdFn(entity) : _.get(entity, 'id'),
    sortComparer: (a, b) =>
      a.getSortingValue()?.localeCompare(b.getSortingValue()),
  })

  const initialState: EntityStoreState<S> = EntityAdapter.getInitialState({
    // additional entity state properties
    loading: 'idle',
    dateLoaded: null,
    invalidated: false,
  });

  const EntitySlice = createSlice({
    name: props.storeName,
    initialState: initialState,
    reducers: {
      invalidate: (state, payload: PayloadAction<boolean>) => {
        state.invalidated = payload.payload
      },
      addOne: (state: any, payload: PayloadAction<S>) => {
        if (!(payload.payload instanceof BaseSchema)) {
          throw Error('Invalid base type.')
        }
        EntityAdapter.addOne(state, payload)
      },
      addMany: EntityAdapter.addMany as any,
      removeOne: EntityAdapter.removeOne as any,
      removeAll: EntityAdapter.removeAll as any,
      updateOne: (state: any, payload: PayloadAction<S>) =>
        createUpdateOneReducer<S>(state, payload, props.selectIdFn),
    },
    extraReducers: (builder) =>
      builder
        .addCase(fetchEntities.pending, (state: any) => {
          state.loading = 'pending'
        })
        .addCase(fetchEntities.fulfilled, (state: any, action) => {
          state.loading = 'fulfilled'
          state.dateLoaded = new Date()
          state.invalidated = false
          createUpdateManyReducer<S>(state, action, props.selectIdFn)
        })
        .addCase(fetchEntities.rejected, (state: any, action) => {
          state.loading = 'rejected'
          state.loading = false
        })
        .addCase(fetchEntity.fulfilled, (state: any, action) => {
          createUpdateOneReducer<S>(state, action, props.selectIdFn)
        }),
  })

  const Actions = EntitySlice.actions
  const Reducer = EntitySlice.reducer
  const Selectors = EntityAdapter.getSelectors(
    (state: any) => state[props.storeName],
    // loosing state type
    // _.get(state, storeName),
  )

  return {
    actions: Actions,
    reducer: Reducer,
    selectors: Selectors,
    thunks: {
      fetchEntities,
      fetchEntity,
    },
  }
}
