import {
  AssetModelPropertySchema,
  AssetModelPropertyTypeSchema,
  AssetModelPropertyTypeMeasurementSchema,
  AWSAssetModelSchema,
  AssetClassModelSchema
} from '@loadsys-cmms/app-sdk'
import { useRefreshComponent } from '@loadsys-cmms/ui-components'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { AWSAssetModelMeasurementForm } from './forms/AWSAssetModelMeasurementForm'

const initializeMeasurement = () => {
  return new AssetModelPropertySchema({
    type: new AssetModelPropertyTypeSchema({
      measurement: new AssetModelPropertyTypeMeasurementSchema()
    })
  })
}

interface AddAWSAssetModelMeasurementProps {
  schema: AWSAssetModelSchema | AssetClassModelSchema
  onChange: (schema: AWSAssetModelSchema | AssetClassModelSchema) => void
}

export const AddAWSAssetModelMeasurement: React.FC<
  AddAWSAssetModelMeasurementProps
> = ({ schema, onChange = () => {} }) => {
  const { refreshComponent } = useRefreshComponent()
  const [property, setProperty] = useState<AssetModelPropertySchema>(
    initializeMeasurement()
  )
  const handleOnFieldChange = () => {
    onChange(schema)
  }

  const handleOnAdd = () => {
    const matchedProperties = _.filter(
      schema?.assetModelProperties,
      (modelProperty) =>
        modelProperty !== property && modelProperty.name === property.name
    )
    if (matchedProperties.length > 0) {
      property.setErrors({
        name: 'The name is not unique. The name must be unique between Attributes, Measurements, Transforms, and Metrics.'
      })
      refreshComponent()
    } else if (property.validate()) {
      if (!schema.assetModelProperties) {
        schema.assetModelProperties = []
      }
      schema.assetModelProperties.push(property)
      setProperty(initializeMeasurement())
      onChange(schema)
    } else {
      console.error('error', property)
      refreshComponent()
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      <AWSAssetModelMeasurementForm
        schema={property}
        onChange={handleOnFieldChange}
      />
      <Box display={'flex'}>
        <Box flex={1}></Box>
        <Button>Cancel</Button>
        <Button onClick={handleOnAdd}>Add</Button>
      </Box>
    </>
  )
}
