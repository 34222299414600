// import { VariableValue } from "@aws-sdk/client-iotsitewise";
import { Expose } from "class-transformer";
import { IsNotEmpty, IsOptional, IsString } from "class-validator";
import { BaseSchema } from "../../BaseSchema";

export class VariableValueSchema extends BaseSchema<VariableValueSchema> /* implements VariableValue */ {
  @Expose()
  @IsNotEmpty()
  @IsString()
  propertyId: string;

  @Expose()
  @IsOptional()
  @IsString()
  hierarchyId?: string;
}