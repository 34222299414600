import { Container } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { EmailConfirmationPage } from '../../pages/auth/EmailConfirmationPage'
import { LoginPage } from '../../pages/auth/LoginPage'
import { SignupPage } from '../../pages/auth/SignupPage'

export const AuthRoutes: React.FC = () => {
  return (
    <Container>
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/signup'} element={<SignupPage />} />
        <Route path={'/confirm-email'} element={<EmailConfirmationPage />} />
        <Route path='*' element={<LoginPage />} />
      </Routes>
    </Container>
  )
}
