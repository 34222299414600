import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { memoize } from 'lodash'
import { AppRootState } from '../../../store/AppStore'
import { AssetClassesSelectors } from '../store/store-config'

export const getAssetClassSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return AssetClassesSelectors.selectById(state, id)
    }),
)

export const AssetClassSelector = createSelector(
  getAssetClassSelector,
  (getAssetClass) =>
    memoize((id: string) => {
      return getAssetClass(id)
    }),
)

export const getClassPropertiesSelector = createSelector(
  getAssetClassSelector,
  (getAssetClass) =>
    memoize((id: string) => {
      const assetClass = getAssetClass(id)
      return assetClass?.properties
    }),
)

export const getClassPropertySelector = createSelector(
  getClassPropertiesSelector,
  (getAssetClassProperties) =>
    memoize((assetClassId: string, propertyId: string) => {
      const properties = getAssetClassProperties(assetClassId)
      return _.find(properties, property => property.id === propertyId)
    }),
)

