import {
  AssetClassSchema,
  MaintenanceActionSchema
} from '@loadsys-cmms/app-sdk'
import { AppDataGrid } from '@loadsys-cmms/ui-components'
import { createStyles, makeStyles } from '@mui/styles'
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const columns: GridColDef[] = [
  {
    field: 'action',
    headerName: 'Action',
    width: 300
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 150
  },
  {
    field: 'word',
    headerName: 'Word',
    width: 150
  },
  {
    field: 'pfIndex',
    headerName: 'P-F Index',
    width: 150,
    type: 'number'
  },
  {
    field: 'baseFrequency',
    headerName: 'Base Frequency',
    width: 150,
    type: 'number'
  }
]

interface MaintenanceActionsDataGridProps {
  schemas: MaintenanceActionSchema[]
}

export const MaintenanceActionsDataGrid: React.FC<
  MaintenanceActionsDataGridProps
> = ({ schemas }) => {
  const navigate = useNavigate()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'action',
      sort: 'asc' as GridSortDirection
    }
  ])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    navigate(`/asset-management/maintenance-actions/${params.row.id}`)
  }

  return (
    <AppDataGrid
      density={'compact'}
      getRowId={(model: AssetClassSchema) => model.id}
      sortModel={sortModel}
      columns={columns}
      rows={schemas}
      onCellClick={handleOnCellClick}
    />
  )
}
