import { MaintenanceActionWord } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo } from 'react'

const OPTIONS: InputOptionProps[] = [
  {
    label: 'Adjust',
    value: MaintenanceActionWord.Adjust
  },
  {
    label: 'Audit',
    value: MaintenanceActionWord.Audit
  },
  {
    label: 'Conduct',
    value: MaintenanceActionWord.Conduct
  },
  {
    label: 'Clean',
    value: MaintenanceActionWord.Clean
  },
  {
    label: 'Lubricate',
    value: MaintenanceActionWord.Lubricate
  },
  {
    label: 'Inspect',
    value: MaintenanceActionWord.Inspect
  },
  {
    label: 'Measure',
    value: MaintenanceActionWord.Measure
  },
  {
    label: 'Perform',
    value: MaintenanceActionWord.Perform
  },
  {
    label: 'Refurbish',
    value: MaintenanceActionWord.Refurbish
  },
  {
    label: 'Replace',
    value: MaintenanceActionWord.Replace
  }
]

export interface MaintenanceActionWordPickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const MaintenanceActionWordPicker: React.FC<
  MaintenanceActionWordPickerProps
> = ({
  label = 'Interval',
  placeholder = 'Interval',
  onChange = () => {},
  ...props
}) => {
  const filteredOptions = useMemo(() => {
    return _.map(OPTIONS)
  }, [OPTIONS])

  const options: InputOptionProps[] = useMemo(() => {
    return OPTIONS
  }, [filteredOptions])

  return (
    <>
      <AutocompleteInput
        options={options}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </>
  )
}
