import { BaseSchema } from '@loadsys-cmms/app-sdk'
import {
  AssetClassEventTriggerFrequencyPicker,
  AssetClassEventTriggerFrequencyPickerProps
} from './AssetClassEventTriggerFrequencyPicker'

export interface SchemaAssetClassEventTriggerFrequencyPickerProps
  extends Omit<AssetClassEventTriggerFrequencyPickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaAssetClassEventTriggerFrequencyPicker: React.FC<
  SchemaAssetClassEventTriggerFrequencyPickerProps
> = ({ schema, field, helperText, onChange, ...props }) => {
  return (
    <AssetClassEventTriggerFrequencyPicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: string) => onChange(value, field)}
      {...props}
    />
  )
}
