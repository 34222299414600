import { configureStore } from '@reduxjs/toolkit'
import { AssetClassesReducer } from '../features/asset-classes/store/store-config'
import { AssetFailureCausesReducer } from '../features/asset-failure-causes/store/store-config'
import { AssetFailureModesReducer } from '../features/asset-failure-modes/store/store-config'
import { LocationsReducer } from '../features/locations/store/store-config'
import { AssetsReducer } from '../features/assets/store/store-config'
import { AssetModelsReducer } from '../features/asset-models/store/store-config'
import { EventTypesStore } from '../features/event-types/store/store-config'
import { MaintenanceActionsReducer } from '../features/maintenance-actions/store/store-config'

export const AppStore = configureStore({
  reducer: {
    assetModels: AssetModelsReducer,
    assets: AssetsReducer,
    assetClasses: AssetClassesReducer,
    assetFailureModes: AssetFailureModesReducer,
    assetFailureCauses: AssetFailureCausesReducer,
    locations: LocationsReducer,
    eventTypes: EventTypesStore.reducer,
    maintenanceActions: MaintenanceActionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppRootState = ReturnType<typeof AppStore.getState>
export type AppDispatch = typeof AppStore.dispatch
