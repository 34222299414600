import { AssetClassPropertySchema } from '@loadsys-cmms/app-sdk'
import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { useCallback } from 'react'
import { SchemaAssetPropertyDataTypePicker } from '../../../asset-metrics/pickers/SchemaAssetPropertyDataTypePicker'

interface AssetClassAttributeFormProps {
  schema: AssetClassPropertySchema
  onChange: (schema: AssetClassPropertySchema) => void
}

export const AssetClassAttributeForm: React.FC<
  AssetClassAttributeFormProps
> = ({ schema, onChange = () => {} }) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnLeaveName = useCallback(() => {
    if (!_.isEmpty(schema.name) && _.isEmpty(schema.code)) {
      schema.code = _.camelCase(schema.name)
      onChange(schema)
    }
  }, [schema, onChange])

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
        inputProps={{
          onBlur: handleOnLeaveName
        }}
        required
      />

      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
        helperText={"* Can't be modified later."}
        required
      />

      <SchemaAssetPropertyDataTypePicker
        label={'Data Type'}
        placeholder={'Data Type'}
        field={'dataType'}
        onChange={handleOnFieldChange}
        schema={schema}
        required
        helperText={"* Can't be modfied later."}
      />

      <SchemaTextInput
        label={'Unit'}
        placeholder={'Unit'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'unit'}
        required
      />
    </>
  )
}
