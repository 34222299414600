// import { ForwardingConfig, ForwardingConfigState } from "@aws-sdk/client-iotsitewise";
import { Expose } from "class-transformer";
import { IsEnum, IsOptional } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../../BaseSchema";
import { Assets } from '../types'

export class ForwardingConfigSchema extends BaseSchema<ForwardingConfigSchema> /* implements ForwardingConfig */ {
  constructor(data?: Partial<ForwardingConfigSchema>) {
    super(data)

    this.state = Assets.ForwardingConfigState.ENABLED

    if (data) {
      _.assign(this, data)
    }
  }

  @Expose()
  @IsEnum(Assets.ForwardingConfigState)
  @IsOptional()
  state: Assets.ForwardingConfigState
}