import { MaintenanceActionSchema } from "@loadsys-cmms/app-sdk"
import createStoreAdapter from "../../../store/createStoreAdapter"

const STORE_NAME = 'maintenanceActions'

const adapter = createStoreAdapter<MaintenanceActionSchema>({
  classType: MaintenanceActionSchema,
  storeName: STORE_NAME,
  apiParams: { name: 'assetsApi', 'baseUrl': '/maintenance-actions' },
  selectIdFn: (entity: MaintenanceActionSchema) => entity.id,
  getFetchEntitiesResult: (result: any) => result.data || [],
  getFetchEntityResult: (result: any) => result.data || {}
})

export const MaintenanceActionsReducer = adapter.reducer
export const MaintenanceActionsSelectors = adapter.selectors
export const MaintenanceActionsThunks = adapter.thunks
export const MaintenanceActionsActions = adapter.actions

export default adapter
