export enum MaintenanceActionType {
  Preventive = 'PM',
  Corrective = 'CM',
  Predictive = 'PdM',
  NonDestructiveTesting = 'NDT',
  Audit = 'AUDIT',
}

export enum MaintenanceActionWord {
  Clean = 'CLEAN',
  Replace = 'REPLACE',
  Inspect = 'INSPECT',
  Perform = 'PERFORM',
  Adjust = 'ADJUST',
  Conduct = 'CONDUCT',
  Lubricate = 'LUBRICATE',
  Measure = 'MEASURE',
  Refurbish = 'REFURBISH',
  Audit = 'AUDIT',
  Repair = 'REPAIR'
}