import React, { useState } from 'react'

interface AppLayoutContextProps {
  drawerOpen: boolean
  barTitle: string
  setBarTitle: (title: string) => void
  setDrawerOpen: (open: boolean) => void
}

const AppLaytoutContext = React.createContext<AppLayoutContextProps>({
  drawerOpen: false,
  barTitle: null,
  setBarTitle: () => {},
  setDrawerOpen: () => {}
})

export const AppLayoutProvider: React.FC = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [barTitle, setBarTitle] = useState<string>(null)

  const ctx: AppLayoutContextProps = {
    drawerOpen,
    barTitle,
    setDrawerOpen,
    setBarTitle
  }

  return (
    <AppLaytoutContext.Provider value={ctx}>
      {children}
    </AppLaytoutContext.Provider>
  )
}

export const useAppLayout = () => {
  const ctx = React.useContext(AppLaytoutContext)
  return ctx
}
