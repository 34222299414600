import { EventSeverity } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import React, { useMemo } from 'react'

const OPTIONS: InputOptionProps[] = [
  {
    label: 'Information',
    value: EventSeverity.Info as string
  },
  {
    label: 'Warning',
    value: EventSeverity.Warning as string
  },
  {
    label: 'Alert',
    value: EventSeverity.Alert as string
  },
  {
    label: 'Critical',
    value: EventSeverity.Critical as string
  }
]

export interface EventSeverityPickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const EventSeverityPicker: React.FC<EventSeverityPickerProps> = ({
  label = 'Event Severity',
  placeholder = 'Event Severity',
  onChange = () => {},
  ...props
}) => {
  const filteredOptions = useMemo(() => {
    return OPTIONS
  }, [])

  const options: InputOptionProps[] = useMemo(() => {
    return filteredOptions
  }, [filteredOptions])

  return (
    <>
      <AutocompleteInput
        options={options}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </>
  )
}
