import { AppTabProps } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { AppTabsContainer } from '../../components/AppTabsContainer'
import { EventTypesStore } from '../../features/event-types/store/store-config'
import { useAppDispatch } from '../../store/useAppDispatch'

const routePrefix = '/event-management'

const tabs: AppTabProps[] = [
  {
    value: 'events',
    route: `${routePrefix}/events`,
    label: 'Events'
  },
  {
    value: 'event-types',
    route: `${routePrefix}/types`,
    label: 'Types'
  }
]

const defaultPath = 'assets'

interface EventManagementProps {}

export const EventManagement: React.FC<EventManagementProps> = ({
  children
}) => {
  const dispatch = useAppDispatch()

  const location = useLocation()

  const selected = useMemo(() => {
    if (!location.pathname) {
      return defaultPath
    }
    const tab = _.find(tabs, (tab) => !!location.pathname.match(tab.route))
    return tab?.value
  }, [location?.pathname])

  useEffect(() => {
    dispatch(
      EventTypesStore.thunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
  }, [])

  return (
    <AppTabsContainer tabs={tabs} selected={selected}>
      {children}
      <Outlet />
    </AppTabsContainer>
  )
}
