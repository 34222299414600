import React from 'react'

import AdapterMoment from '@mui/lab/AdapterMoment'

import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  LocalizationProvider
} from '@mui/lab'

import TimeIcon from '@mui/icons-material/AccessTime'
import CalendarIcon from '@mui/icons-material/CalendarToday'
import TodayIcon from '@mui/icons-material/Today'

import { InputAdornment, TextField } from '@mui/material'
import _ from 'lodash'
import { Moment } from 'moment'

const FieldTypeIconMap = {
  date: TimeIcon,
  time: TodayIcon,
  datetime: CalendarIcon
}

const pickerMap = {
  date: DatePicker,
  time: TimePicker,
  datetime: DateTimePicker
}

interface DateTimeInputProps {
  margin?: 'dense' | 'normal' | 'none'
  type?: 'date' | 'time' | 'datetime'
  className?: any
  label?: String
  error?: boolean
  value?: Date
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  margin = 'dense',
  type = 'datetime',
  className,
  label,
  error,
  value,
  helperText,
  onChange,
  disabled,
  required
}) => {
  const PickerComponent: any = _.get(pickerMap, type)
  const InputIcon = _.get(FieldTypeIconMap, type)

  const handleOnChange = (date: unknown, _keyboardInputValue?: string) => {
    onChange(date ? (date as Moment).toDate() : null)
  }

  if (!PickerComponent) {
    return null
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <PickerComponent
        className={className}
        clearable={true}
        disabled={disabled}
        value={value}
        label={label}
        onChange={handleOnChange}
        renderInput={(params: any) => (
          <TextField
            className={className}
            {...params}
            fullWidth
            margin={margin}
            error={error}
            helperText={helperText}
            required={required}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        /*InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <InputIcon />
            </InputAdornment>
          )
        }}*/
      />
    </LocalizationProvider>
  )
}
