import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import {
  Drawer,
  Box,
  Paper,
  Theme,
  IconButton,
  useTheme,
  Divider,
  Container
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import BackIcon from '@mui/icons-material/ArrowBack'
import clsx from 'clsx'
import _ from 'lodash'
import { AppToolbar } from '../app-toolbar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsContainer: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row'
    },
    topToolbar: {
      borderRadius: 0,
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
      height: 55,
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    botomToolbar: {
      borderRadius: 0,
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
      height: 55,
      alignItems: 'center',
      justifyContent: 'flex-end',
      overflow: 'auto'
    }
  })
)

interface AppDrawerProps {
  anchor?: 'bottom' | 'left' | 'right' | 'top'
  width?: number | string
  minWidth?: number | string
  children: any
  actions?: any
  open?: boolean
  onClose?: Function
  variant?: 'permanent' | 'persistent' | 'temporary'
  BackdropProps?: any
  ModalProps?: any
  PaperProps?: any
  ContainerProps?: any
  closeOnClickOutside?: boolean
}

const DrawerContext = React.createContext({
  onClose: null,
  actionBarVisible: false,
  setActionBarVisible: (_visible: boolean) => {}
})

export const AppDrawer: React.FC<AppDrawerProps> = ({
  anchor = 'right',
  width = '60vw',
  minWidth,
  ContainerProps = {},
  children,
  actions,
  open,
  onClose = () => {},
  variant,
  BackdropProps,
  ModalProps,
  PaperProps,
  closeOnClickOutside
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const [actionBarVisible, setActionBarVisible] = useState(false)

  const contextValue = {
    onClose,
    actionBarVisible,
    setActionBarVisible
  }

  const handleClickOutsideBoundaries = useCallback(() => {
    if (closeOnClickOutside) {
      onClose()
    }
  }, [closeOnClickOutside, onClose])

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={handleClickOutsideBoundaries}
      variant={variant}
      BackdropProps={BackdropProps}
      ModalProps={ModalProps}
      PaperProps={PaperProps}
      sx={{
        zIndex: theme.zIndex.drawer + 2
      }}
    >
      <DrawerContext.Provider value={contextValue}>
        <Box
          display='flex'
          flexDirection={'column'}
          sx={_.merge(
            {
              padding: theme.spacing(1),
              width: width,
              minWidth: minWidth,
              flex: 1,
              backgroundColor: (theme: any) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900]
            },
            ContainerProps || {}
          )}
        >
          {actions && (
            <Paper className={classes.actionsContainer}>
              <Box style={{ flex: 1, alignItems: 'center' }}>{actions}</Box>
              <IconButton onClick={() => onClose()} size={'small'}>
                <CloseIcon />
              </IconButton>
            </Paper>
          )}
          {children}
        </Box>
      </DrawerContext.Provider>
    </Drawer>
  )
}

interface AppDrawerToolbarProps {
  children: ReactNode
  title?: boolean
}

export const AppDrawerToolbar: React.FC<AppDrawerToolbarProps> = ({
  children
}) => {
  const styles = useStyles()
  const context = useContext(DrawerContext)
  const theme = useTheme()

  const handleOnClose = () => {
    if (context.onClose) {
      context.onClose()
    }
  }

  return (
    <Paper
      sx={{
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }}
    >
      <Container
        sx={{ display: 'flex' }}
        // className={clsx([appStyles.content, styles.topToolbar])}
      >
        <IconButton onClick={() => handleOnClose()} size={'small'}>
          <BackIcon />
        </IconButton>
        <Divider orientation={'vertical'} />
        <Box
          display={'flex'}
          style={{ flex: 1, marginLeft: theme.spacing(1) }}
          alignItems={'center'}
        >
          {children}
        </Box>
        <Box>
          <IconButton onClick={() => handleOnClose()} size={'small'}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Container>
    </Paper>
  )
}

interface AppDrawerContentProps {
  children: ReactNode
  actions?: boolean
}

export const AppDrawerContent: React.FC<AppDrawerContentProps> = ({
  children
}) => {
  const context = useContext(DrawerContext)

  const offset = useMemo(() => {
    if (context?.actionBarVisible) {
      return 155
    } else {
      return 85
    }
  }, [context?.actionBarVisible])

  return (
    <Box
      display='flex'
      flexDirection={'column'}
      style={{ height: `calc(100vh - ${offset}px`, overflow: 'auto' }}
    >
      {children}
    </Box>
  )
}

interface AppDrawerActionsProps {
  children: ReactNode
  actions?: boolean
}

export const AppDrawerActions: React.FC<AppDrawerActionsProps> = ({
  children
}) => {
  const styles = useStyles()
  const theme = useTheme()
  //const appStyles = useAppStyles()

  const context = useContext(DrawerContext)

  useEffect(() => {
    context?.setActionBarVisible(true)
    return () => {
      context?.setActionBarVisible(true)
    }
  }, [context])

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        alignItems: 'flex-end'
      }}
      //className={clsx([appStyles.content, styles.botomToolbar])}
    >
      {children}
    </Paper>
  )
}
