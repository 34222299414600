import { Container, Paper, SxProps, useTheme } from '@mui/material'
import _ from 'lodash'

export interface ContentContainerProps {
  sx?: SxProps
}

export const ContentContainer: React.FC<ContentContainerProps> = ({
  sx,
  children
}) => {
  const theme = useTheme()

  return (
    <Paper
      sx={_.merge(
        {
          padding: theme.spacing(1),
          marginBottom: theme.spacing(1)
        },
        sx
      )}
    >
      <Container>{children}</Container>
    </Paper>
  )
}
