import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { EntityState, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

export default function createUpdateManyReducer<T> (
  state: EntityState<T>,
  payload: PayloadAction<T[]>,
  selectFn: (entity: T) => string
) {
  const entities = payload.payload
  _.forEach(entities, (entity) => {
    /*if (!entity || !(entity instanceof BaseSchema)) {
      return
    }*/

    const key = selectFn(entity)

    if (!(key in state.entities)) {
      state.ids.push(key)
    }

    state.entities[key] = entity
  })
}
