import { LinearProgress } from '@mui/material'
import { useEffect } from 'react'
import { AssetClassesThunks } from '../../features/asset-classes/store/store-config'
import { API } from 'aws-amplify'

import { AssetFailureCausesThunks } from '../../features/asset-failure-causes/store/store-config'
import { AssetFailureModesThunks } from '../../features/asset-failure-modes/store/store-config'
import { AssetModelsThunks } from '../../features/asset-models/store/store-config'
import { AssetsThunks } from '../../features/assets/store/store-config'
import { LocationsThunks } from '../../features/locations/store/store-config'
import { useAppDispatch } from '../../store/useAppDispatch'
import { useAppSelector } from '../../store/useAppSelector'
import { EventTypesStore } from '../../features/event-types/store/store-config'
import { MaintenanceActionsThunks } from '../../features/maintenance-actions/store/store-config'

export const AppDataPreloader: React.FC = ({ children }) => {
  const dispatch = useAppDispatch()

  const locationsLoaded = useAppSelector(
    (state) =>
      !!state.locations.dateLoaded &&
      !!state.assetClasses.dateLoaded &&
      !!state.assetFailureCauses.dateLoaded &&
      !!state.assetFailureModes.dateLoaded &&
      !!state.assets.dateLoaded &&
      !!state.assetModels.dateLoaded
  )

  useEffect(() => {
    API.post('usersApi', '/user/initialize', {
      body: {}
    }).catch((err) => {
      console.error(err)
    })
    dispatch(AssetModelsThunks.fetchEntities({}))
    dispatch(
      AssetClassesThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
    dispatch(
      AssetFailureModesThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
    dispatch(
      AssetFailureCausesThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
    dispatch(
      LocationsThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
    dispatch(
      AssetsThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
    dispatch(
      EventTypesStore.thunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
    dispatch(
      MaintenanceActionsThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
  }, [])

  if (!locationsLoaded) {
    return <LinearProgress />
  }
  return <>{children}</>
}
