import { SchemaTextInput } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import { useCallback } from 'react'
import { SchemaAssetModelTypePicker } from '../../../asset-models/components/pickers/SchemaAssetModelTypePicker'
import { AssetClassModel } from '../../models/AssetClassModel'

interface AssetClassFormProps {
  schema: AssetClassModel
  onChange: (schema: AssetClassModel) => void
}

export const AssetClassForm: React.FC<AssetClassFormProps> = ({
  schema,
  onChange = () => {}
}) => {
  const handleOnFieldChange = (value: string, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }

  const handleOnLeaveName = useCallback(() => {
    if (!_.isEmpty(schema.name) && _.isEmpty(schema.code)) {
      schema.code = _.camelCase(schema.name)
      onChange(schema)
    }
  }, [schema, onChange])

  if (!schema) {
    return null
  }

  return (
    <>
      <SchemaAssetModelTypePicker
        label={'Type'}
        placeholder={'Type'}
        schema={schema}
        field={'type'}
        onChange={handleOnFieldChange}
      />
      <SchemaTextInput
        label={'Name'}
        placeholder={'Name'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'name'}
        inputProps={{
          onBlur: handleOnLeaveName
        }}
      />
      <SchemaTextInput
        label={'Code'}
        placeholder={'Code'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'code'}
      />
      <SchemaTextInput
        label={'Description'}
        placeholder={'Description'}
        onChange={handleOnFieldChange}
        schema={schema}
        field={'description'}
      />
    </>
  )
}
