import { AssetMetricAggregationPeriod } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import React, { useMemo } from 'react'

const OPTIONS: InputOptionProps[] = [
  {
    label: '1 Minute',
    value: AssetMetricAggregationPeriod.OneMinute
  },
  {
    label: '5 Minute',
    value: AssetMetricAggregationPeriod.FiveMinutes
  },
  {
    label: '15 Minute',
    value: AssetMetricAggregationPeriod.FifteenMinutes
  },
  {
    label: '1 Hour',
    value: AssetMetricAggregationPeriod.OneHour
  },
  {
    label: '1 Day',
    value: AssetMetricAggregationPeriod.OneDay
  },
  {
    label: '1 Week',
    value: AssetMetricAggregationPeriod.OneWeek
  }
]

export interface AssetMetricAggregationPeriodPickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const AssetMetricAggregationPeriodPicker: React.FC<
  AssetMetricAggregationPeriodPickerProps
> = ({
  label = 'Period',
  placeholder = 'Period',
  onChange = () => {},
  ...props
}) => {
  const filteredOptions = useMemo(() => {
    return OPTIONS
  }, [])

  const options: InputOptionProps[] = useMemo(() => {
    return filteredOptions
  }, [filteredOptions])

  return (
    <>
      <AutocompleteInput
        options={options}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </>
  )
}
