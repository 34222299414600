import { AmplifyLoginComponent, useAuth } from '@loadsys-cmms/ui-components'
import { Container } from '@mui/material'

export const LoginPage: React.FC = () => {
  const auth = useAuth()
  return (
    <Container maxWidth={'xs'}>
      <AmplifyLoginComponent onLogin={(user) => auth.setUser(user)} />
    </Container>
  )
}
