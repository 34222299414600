// import { PropertyType } from "@aws-sdk/client-iotsitewise";
import { Expose, Type } from "class-transformer";
import { IsOptional, ValidateNested } from "class-validator";
import { BaseSchema } from "../../BaseSchema";
import { AssetModelPropertyTypeAttributeSchema } from "./AssetModelPropertyTypeAttributeSchema";
import { AssetModelPropertyTypeMeasurementSchema } from "./AssetModelPropertyTypeMeasurementSchema";
import { AssetModelPropertyTypeMetricSchema } from "./AssetModelPropertyTypeMetricSchema";
import { AssetModelPropertyTypeTransformSchema } from "./AssetModelPropertyTypeTransformSchema";

export class AssetModelPropertyTypeSchema extends BaseSchema<AssetModelPropertyTypeSchema> /* implements PropertyType */ {
  @Expose()
  @Type(() => AssetModelPropertyTypeAttributeSchema)
  @IsOptional()
  @ValidateNested()
  attribute?: AssetModelPropertyTypeAttributeSchema;

  @Expose()
  @Type(() => AssetModelPropertyTypeMeasurementSchema)
  @IsOptional()
  @ValidateNested()
  measurement?: AssetModelPropertyTypeMeasurementSchema;

  @Expose()
  @Type(() => AssetModelPropertyTypeTransformSchema)
  @IsOptional()
  @ValidateNested()
  transform?: AssetModelPropertyTypeTransformSchema;

  @Expose()
  @Type(() => AssetModelPropertyTypeMetricSchema)
  @IsOptional()
  @ValidateNested()
  metric?: AssetModelPropertyTypeMetricSchema;
}