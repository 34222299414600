import { BaseSchema } from "../BaseSchema";
import { Expose, Type } from "class-transformer";
import { IsOptional, ValidateNested } from "class-validator";
import { AssetModelPropertySchema } from "./AWS/AssetModelPropertySchema";
import { AWSAssetModelHierarchySchema } from "./AWS/AWSAssetModelHierarchySchema";

export class AWSAssetModelSchema extends BaseSchema {
  @Expose()
  @IsOptional()
  assetModelId: string

  @Expose()
  @IsOptional()
  assetModelName: string

  @Expose()
  @IsOptional()
  assetModelArn: string

  @Expose()
  @IsOptional()
  assetModelDescription: string

  @Expose()
  @Type(() => AWSAssetModelHierarchySchema)
  @IsOptional()
  @ValidateNested()
  assetModelHierarchies: AWSAssetModelHierarchySchema[] = []

  @Expose()
  @Type(() => AssetModelPropertySchema)
  @IsOptional()
  @ValidateNested()
  assetModelProperties: AssetModelPropertySchema[] = []

  /**
   *
   * @returns
   */
  public getInputOption (): { value: string, label: string } {
    return {
      value: this.assetModelId,
      label: this.assetModelName,
    }
  }

  public getSortingValue () {
    return this.assetModelName
  }
}