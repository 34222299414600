import { AmplifySignupComponent } from '@loadsys-cmms/ui-components'
import { Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const SignupPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Container maxWidth={'xs'}>
      <AmplifySignupComponent onSignup={() => navigate('/confirm-email')} />
    </Container>
  )
}
