import { AssetFailureModeSchema } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import { Grid } from '@mui/material'
import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import {
  AssetFailureCausePicker,
  AssetFailureCausePickerProps
} from '../../../asset-failure-causes/components/pickers/AssetFailureCausePicker'
import { getAssetFailureModeSelector } from '../../selectors/selectors'
import { AssetFailureModesSelectors } from '../../store/store-config'
import {
  AssetFailureModePicker,
  AssetFailureModePickerProps
} from './AssetFailureModePicker'

export interface AssetFailureModeAndCausePickerProps {
  failureModeProps: AssetFailureModePickerProps
  failureCauseProps: AssetFailureCausePickerProps
}

export const AssetFailureModeAndCausePicker: React.FC<
  AssetFailureModeAndCausePickerProps
> = ({ failureModeProps, failureCauseProps }) => {
  const getFailureMode = useAppSelector(getAssetFailureModeSelector)
  /*const [failureModeId, setFailureModeId] = useState(failureModeProps?.value)
  const [failureCauseId, setFailureCauseId] = useState(failureCauseProps?.value)*/

  const handleOnFailureModeChange = useCallback(
    (selectedFailureModeId: string) => {
      //setFailureModeId(selectedFailureModeId)
      if (failureModeProps?.onChange) {
        failureModeProps?.onChange(selectedFailureModeId)
      }
    },
    [failureModeProps?.onChange]
  )

  const handleOnFailureCauseChange = useCallback(
    (selectedFailureCauseId: string) => {
      //setFailureCauseId(selectedFailureCauseId)
      if (failureCauseProps?.onChange) {
        failureCauseProps?.onChange(selectedFailureCauseId)
      }
    },
    [failureCauseProps?.onChange]
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <AssetFailureModePicker
          {...failureModeProps}
          value={failureModeProps?.value}
          onChange={handleOnFailureModeChange}
        />
      </Grid>
      <Grid item xs={6}>
        <AssetFailureCausePicker
          {...failureCauseProps}
          value={failureCauseProps?.value}
          onChange={handleOnFailureCauseChange}
        />
      </Grid>
    </Grid>
  )
}
