import { BaseSchema } from '@loadsys-cmms/app-sdk'
import {
  MaintenanceActionWordPicker,
  MaintenanceActionWordPickerProps
} from './MaintenanceActionWordPicker'

export interface SchemaMaintenanceActionWordPickerProps
  extends Omit<MaintenanceActionWordPickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaMaintenanceActionWordPicker: React.FC<
  SchemaMaintenanceActionWordPickerProps
> = ({ schema, field, helperText, onChange, ...props }) => {
  return (
    <MaintenanceActionWordPicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value: string) => onChange(value, field)}
      {...props}
    />
  )
}
