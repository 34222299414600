import { AssetClassHierarchySchema } from '@loadsys-cmms/app-sdk'
import { useRefreshComponent } from '@loadsys-cmms/ui-components'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import _ from 'lodash'
import { AssetClassModel } from '../models/AssetClassModel'
import { AddAssetClassHierarchy } from './AddAssetClassHierarchy'
import { AssetClassName } from './AssetClassName'

interface AssetClassHierarchiesProps {
  schema: AssetClassModel
  onChange?: (schema: AssetClassModel) => void
  enableEdit?: boolean
}

export const AssetClassHierarchies: React.FC<AssetClassHierarchiesProps> = ({
  schema,
  onChange = () => {},
  enableEdit
}) => {
  const { refreshComponent } = useRefreshComponent()
  const handleOnFieldChange = () => {
    refreshComponent()
    onChange(schema)
  }

  const handleOnDelete = (child: AssetClassHierarchySchema) => {
    if (schema.hierarchies) {
      const index = _.findIndex(schema.hierarchies, (c) => c === child)
      if (index >= 0) {
        schema.hierarchies.splice(index, 1)
        refreshComponent()
        onChange(schema)
      }
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      {enableEdit && (
        <AddAssetClassHierarchy
          schema={schema}
          onChange={handleOnFieldChange}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Class</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            {enableEdit && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(schema?.hierarchies, (hierarchy, index) => (
            <TableRow key={hierarchy.id ?? index}>
              <TableCell>
                <AssetClassName
                  assetClassId={hierarchy.childAssetClassId}
                  link={!enableEdit}
                />
              </TableCell>
              <TableCell>{hierarchy.name}</TableCell>
              <TableCell>{hierarchy.code}</TableCell>

              {enableEdit && (
                <TableCell>
                  <Button onClick={() => handleOnDelete(hierarchy)}>
                    delete
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
