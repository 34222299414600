import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import { MaintenanceActionSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { MaintenanceActionForm } from './forms/MaintenanceActionForm'
import { useNavigate } from 'react-router-dom'
import { useAddMaintenanceActionQuery } from '../hooks/useAddMaintenanceActionQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'

interface AddMaintenanceActionDrawerProps {
  open: boolean
  onClose: (schema?: MaintenanceActionSchema) => void
  defaultData?: Partial<MaintenanceActionSchema>
}

export const AddMaintenanceActionDrawer: React.FC<
  AddMaintenanceActionDrawerProps
> = ({ open, onClose, defaultData }) => {
  const { addMaintenanceAction, processing } = useAddMaintenanceActionQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<MaintenanceActionSchema>(
    new MaintenanceActionSchema()
  )

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addMaintenanceAction(schema)
        .then((result) => {
          setSchema(new MaintenanceActionSchema(defaultData))
          addSuccessMessage('Maintenance Action created.')
          onClose()
          navigate(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to add the Maintenance Action. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/maintenance-actions')
    onClose()
  }, [])

  const handleOnReset = useCallback(() => {
    setSchema(new MaintenanceActionSchema(defaultData))
  }, [defaultData])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Add Maintenance Action
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <ContentContainer>
            <MaintenanceActionForm
              schema={schema}
              onChange={handleOnSchemaChanged}
            />
          </ContentContainer>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
