import { useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'

export const useRefreshComponent = () => {
  const [hash, setHash] = useState<string>(null)

  const refreshComponent = useCallback(() => {
    setHash(uuid())
  }, [])

  return {
    hash,
    refreshComponent,
  }
}
