import { Box } from '@mui/material'
import { useMemo } from 'react'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetsDataGrid } from '../../assets/components/AssetsDataGrid'
import { AssetsForModelSelector } from '../../assets/selectors/selectors'

export interface AssetModelAssetsProps {
  assetModelId: string
  offset?: number
}

export const AssetModelAssets: React.FC<AssetModelAssetsProps> = ({
  assetModelId,
  offset
}) => {
  const assets = useAppSelector((state) =>
    AssetsForModelSelector(state, assetModelId)
  )
  const heightCalc = useMemo(() => {
    return `calc(100vh - ${offset || 0}px)`
  }, [offset])

  return (
    <Box sx={{ height: heightCalc, flexGrow: 1 }}>
      <AssetsDataGrid schemas={assets} />
    </Box>
  )
}
