import {
  AssetMetricAggregationStrategy,
  AssetPropertyDataType
} from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo } from 'react'

const OPTIONS_FOR_NUMBER: InputOptionProps[] = [
  {
    label: 'Average',
    value: AssetMetricAggregationStrategy.Average
  },
  {
    label: 'Min',
    value: AssetMetricAggregationStrategy.Min
  },
  {
    label: 'Max',
    value: AssetMetricAggregationStrategy.Max
  },
  {
    label: 'First',
    value: AssetMetricAggregationStrategy.First
  },
  {
    label: 'Last',
    value: AssetMetricAggregationStrategy.Last
  }
]

const OPTIONS_FOR_STRING: InputOptionProps[] = [
  {
    label: 'First',
    value: AssetMetricAggregationStrategy.First
  },
  {
    label: 'Last',
    value: AssetMetricAggregationStrategy.Last
  }
]

export interface AssetMetricAggregationStrategyPickerProps
  extends Omit<AutocompleteInputProps, 'options'> {
  dataType: AssetPropertyDataType
}

export const AssetMetricAggregationStrategyPicker: React.FC<
  AssetMetricAggregationStrategyPickerProps
> = ({
  dataType,
  label = 'Aggregation Type',
  placeholder = 'Aggregation Type',
  onChange = () => {},
  ...props
}) => {
  const filteredOptions = useMemo(() => {
    switch (dataType) {
      case AssetPropertyDataType.Number:
        return OPTIONS_FOR_NUMBER
      case AssetPropertyDataType.String:
        return OPTIONS_FOR_STRING
      default:
        return []
    }
  }, [dataType])

  const options: InputOptionProps[] = useMemo(() => {
    return filteredOptions
  }, [filteredOptions])

  return (
    <>
      <AutocompleteInput
        options={options}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </>
  )
}
