// import { MeasurementProcessingConfig } from "@aws-sdk/client-iotsitewise";
import { Expose, Type } from "class-transformer";
import { IsObject, IsOptional, ValidateNested } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../../BaseSchema";
import { ForwardingConfigSchema } from "./ForwardingConfigSchema";

export class MeasurementProcessingConfigSchema extends BaseSchema<MeasurementProcessingConfigSchema> /* implements MeasurementProcessingConfig */ {

  /**
   * Initialize
   *
   * @param data
   */
  constructor(data?: MeasurementProcessingConfigSchema) {
    super()

    this.forwardingConfig = new ForwardingConfigSchema()

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @Type(() => ForwardingConfigSchema)
  @IsOptional()
  @IsObject()
  @ValidateNested()
  forwardingConfig: ForwardingConfigSchema
}