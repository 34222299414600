import { Exclude, Expose, Type } from 'class-transformer';
import {
  IsNotEmpty,
  MaxLength,
  IsBoolean,
  IsAlphanumeric,
  IsOptional,
  IsNumber,
  IsEnum,
  IsPositive,
  ValidateIf,
} from 'class-validator';
import _ from 'lodash';
import { BaseSchema } from '../BaseSchema';
import { EventTriggerFrequency } from '../events';

@Exclude()
export class AssetClassEventTriggerSchema extends BaseSchema {
  @Expose()
  id: string;

  @Expose()
  @IsNotEmpty()
  assetClassId: string;

  @Expose()
  @IsNotEmpty()
  eventTypeId: string;

  @Expose()
  @IsNotEmpty()
  @MaxLength(36)
  @IsAlphanumeric()
  code: string;

  @Expose()
  @IsNotEmpty()
  @MaxLength(255)
  name: string;

  @Type(() => Boolean)
  @Expose()
  @IsBoolean()
  timeTriggerEnabled: boolean = false;

  @ValidateIf((obj) => obj.timeTriggerEnabled)
  @Expose()
  @IsNotEmpty()
  @IsNumber()
  @IsPositive()
  @Type(() => Number)
  timeInterval: number;

  @Expose()
  @ValidateIf((obj) => obj.timeTriggerEnabled)
  @IsNotEmpty()
  @IsEnum(EventTriggerFrequency)
  timeFrequency: EventTriggerFrequency;

  @Type(() => Boolean)
  @Expose()
  @IsBoolean()
  propertyBoundariesTriggerEnabled: boolean = false;

  @Expose()
  @ValidateIf((obj) => obj.propertyBoundariesTriggerEnabled)
  @IsNotEmpty()
  propertyBoundariesTriggerAssetClassPropertyId: string;

  @Expose()
  @ValidateIf((obj) => obj.propertyBoundariesTriggerEnabled)
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  propertyMax: number;

  @Expose()
  @ValidateIf((obj) => obj.propertyBoundariesTriggerEnabled)
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  propertyMin: number;

  @Type(() => Boolean)
  @Expose()
  @IsBoolean()
  propertyCounterTriggerEnabled: boolean = false;

  @Expose()
  @ValidateIf((obj) => obj.propertyCounterTriggerEnabled)
  @IsNotEmpty()
  propertyCounterTriggerAssetClassPropertyId: string;

  @Expose()
  @ValidateIf((obj) => obj.propertyCounterTriggerEnabled)
  @IsNotEmpty()
  @IsNumber()
  @Type(() => Number)
  propertyCounter: number;

  @Type(() => Boolean)
  @Expose()
  @IsBoolean()
  isEnabled: boolean = true;
}
