import { EventTypeSchema } from '@loadsys-cmms/app-sdk'
import { AppToolbar, MeasuredContainer } from '@loadsys-cmms/ui-components'
import { Box, Button, Paper } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { EventTypesStore } from '../../features/event-types/store/store-config'
import { useEntityParams } from '../../hooks/useEntityParams'
import { useAppSelector } from '../../store/useAppSelector'
import { EventTypesDataGrid } from '../../features/event-types/components/EventTypesDataGrid'
import { ViewEventTypeDrawer } from '../../features/event-types/components/ViewEventTypeDrawer'
import { EditEventTypeDrawer } from '../../features/event-types/components/EditEventTypeDrawer'
import { AddEventTypeDrawer } from '../../features/event-types/components/AddEventTypeDrawer'

export const EventTypesPage: React.FC = () => {
  const { entityId, addAction, viewAction, editAction, action } =
    useEntityParams()
  const [openAdd, setOpenAdd] = useState(addAction)
  const [openView, setOpenView] = useState(viewAction)
  const [openEdit, setOpenEdit] = useState(editAction)
  const [height, setHeight] = useState(null)

  const eventTypes: EventTypeSchema[] = useAppSelector(
    EventTypesStore.selectors.selectAll
  )

  useEffect(() => {
    setOpenAdd(addAction)
  }, [addAction])

  useEffect(() => {
    setOpenView(viewAction)
  }, [viewAction])

  useEffect(() => {
    setOpenEdit(editAction)
  }, [editAction])

  const heightCalc = useMemo(() => {
    if (!height) {
      return null
    }
    return `calc(100vh - ${height + 145}px)`
  }, [height])

  return (
    <React.Fragment>
      <MeasuredContainer onMeasured={setHeight}>
        <AppToolbar>
          <Box flex={1} />
          <Button onClick={() => setOpenAdd(true)}>Add EventType</Button>
          <AddEventTypeDrawer
            open={openAdd}
            onClose={() => setOpenAdd(false)}
          />
        </AppToolbar>
      </MeasuredContainer>
      <Box height={heightCalc}>
        <Paper sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
          <EventTypesDataGrid schemas={eventTypes} />
        </Paper>
      </Box>
      <ViewEventTypeDrawer open={openView} onClose={() => setOpenView(false)} />
      <EditEventTypeDrawer open={openEdit} onClose={() => setOpenEdit(false)} />
    </React.Fragment>
  )
  //return
}
