import PasswordValidator from 'password-validator'

import { Singleton } from 'typescript-ioc'

import _ from 'lodash'

/**
 * AppPasswordValidator class.
 */
@Singleton
export class AppPasswordValidatorService {
  /**
   * PasswordValidator instance.
   *
   * @var PasswordValidator
   * @private
   * @readonly
   */
  private readonly passwordValidator: PasswordValidator

  /**
   * Initialize the password validator.
   */
  constructor () {
    this.passwordValidator = new PasswordValidator()
    this.passwordValidator
      .is()
      .min(8)
      .is()
      .max(30)
      .has()
      .uppercase()
      .has()
      .lowercase()
      .has()
      .digits()
      .has()
      .not()
      .spaces()
      .is()
      .not()
      .oneOf(['Passw0rd', 'Password123', 'P@ssw0rd'])
  }

  /**
   * Validate a password.
   *
   * @param clearTextPassword Password to validate.
   * @return boolean
   */
  public validatePassword (
    clearTextPassword: string,
  ): boolean {
    if (_.isEmpty(clearTextPassword)) {
      return false
    }

    return (this.passwordValidator.validate(clearTextPassword) === true)
  }
}