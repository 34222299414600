import { IsString, IsNotEmpty, IsOptional } from 'class-validator';
import { Type, Expose } from 'class-transformer';
import { BaseSchema } from '../BaseSchema';

export class AssetFailureCauseSchema extends BaseSchema {
  @IsOptional()
  @IsString()
  id: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  code: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  name: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  description: string;

  @Expose()
  @Type(() => Boolean)
  @IsNotEmpty()
  isEnabled: boolean = true;

  @Type(() => Boolean)
  @IsNotEmpty()
  isTemplate: boolean = false;
}
