import {
  AssetClassPropertySchema,
  AssetClassPropertyType
} from '@loadsys-cmms/app-sdk'
import {
  AppDataGrid,
  MeasuredContainer,
  useRefreshComponent
} from '@loadsys-cmms/ui-components'
import {
  GridColDef,
  GridActionsCellItem,
  GridRowParams
} from '@mui/x-data-grid-pro'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import hashObject from 'hash-object'
import { AssetClassModel } from '../models/AssetClassModel'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { AddAssetClassAttributeDrawer } from './AddAssetClassAttributeDrawer'

interface AssetClassAttributesProps {
  schema: AssetClassModel
  onChange?: (schema: AssetClassModel) => void
  enableEdit?: boolean
  offset?: number
}

export const AssetClassAttributes: React.FC<AssetClassAttributesProps> = ({
  schema,
  onChange = () => {},
  enableEdit,
  offset = 0
}) => {
  const [formHeight, setFormHeight] = useState<number>()
  const { refreshComponent } = useRefreshComponent()

  const handleOnFieldChange = () => {
    onChange(schema)
    refreshComponent()
  }

  const handleOnDelete = useCallback(
    (attribute: AssetClassPropertySchema) => {
      const index = _.findIndex(schema.properties, (a) => a === attribute)
      if (index >= 0 && schema.properties && schema.properties.length > index) {
        schema.properties.splice(index, 1)
        onChange(schema)
      }
    },
    [schema?.properties]
  )

  const attributes = useMemo(() => {
    return _.filter(
      schema?.properties,
      (property) => property.type === AssetClassPropertyType.Attribute
    )
  }, [hashObject(schema?.properties)])

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          width: 150
        },
        {
          field: 'code',
          headerName: 'Code',
          width: 150
        },
        {
          field: 'dataType',
          headerName: 'Type',
          width: 150
        },
        {
          field: 'unit',
          headerName: 'Unit',
          width: 100
        },
        {
          width: 50,
          field: 'actions',
          type: 'actions',
          sortable: false,
          editable: false,
          filterable: false,
          hideable: false,
          hide: !enableEdit,
          getActions: (params: GridRowParams) => [
            <GridActionsCellItem
              key={'delete'}
              icon={<DeleteIcon />}
              label='Delete'
              onClick={() => handleOnDelete(params.row)}
            />
          ]
        }
      ] as GridColDef[],
    [handleOnDelete, enableEdit]
  )

  const heightCalc = useMemo(() => {
    return `calc(100vh - ${(formHeight || 0) + offset}px)`
  }, [formHeight, offset])

  if (!schema) {
    return null
  }

  return (
    <>
      <MeasuredContainer onMeasured={setFormHeight}>
        {enableEdit && (
          <AddAssetClassAttributeDrawer
            schema={schema}
            onChange={handleOnFieldChange}
          />
        )}
      </MeasuredContainer>
      <Box sx={{ height: heightCalc, flexGrow: 1 }}>
        <AppDataGrid
          density={'compact'}
          columns={columns}
          rows={attributes}
          getRowId={(row) => row.code}
          pinnedColumns={{ right: ['actions'] }}
        />
      </Box>
    </>
  )
}
