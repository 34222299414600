//import { Transform } from "@aws-sdk/client-iotsitewise";
import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, IsString, ValidateNested } from "class-validator";
import _ from "lodash";
import { BaseSchema } from "../../BaseSchema";
import { ExpressionVariableSchema } from "./ExpressionVariableSchema";
import { TransformProcessingConfigSchema } from "./TransformProcessingConfigSchema";

export class AssetModelPropertyTypeTransformSchema extends BaseSchema /* implements Transform */ {
  /**
   * Initialize
   *
   * @param data TransformProcessingConfigSchema
   */
  constructor(data?: AssetModelPropertyTypeTransformSchema) {
    super()

    this.processingConfig = new TransformProcessingConfigSchema()
    this.variables = []

    if (data) {
      _.assign(data)
    }
  }

  @Expose()
  @IsNotEmpty()
  @IsString()
  expression: string;

  @Expose()
  @Type(() => ExpressionVariableSchema)
  @IsOptional()
  @ValidateNested()
  variables: ExpressionVariableSchema[];

  @Expose()
  @Type(() => TransformProcessingConfigSchema)
  @IsOptional()
  @ValidateNested()
  processingConfig: TransformProcessingConfigSchema;
}