import {
  AssetClassModelSchema,
  AssetModelPropertySchema,
  AWSAssetModelSchema
} from '@loadsys-cmms/app-sdk'
import { useRefreshComponent } from '@loadsys-cmms/ui-components'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import _ from 'lodash'
import { useMemo } from 'react'
import { AddAWSAssetModelMeasurement } from './AddAWSAssetModelMeasurement'
import hashObject from 'hash-object'

interface AWSAssetModelMeasurementsProps {
  schema: AWSAssetModelSchema | AssetClassModelSchema
  onChange?: (schema: AWSAssetModelSchema | AssetClassModelSchema) => void
  enableEdit?: boolean
}

export const AWSAssetModelMeasurements: React.FC<
  AWSAssetModelMeasurementsProps
> = ({ schema, onChange = () => {}, enableEdit }) => {
  const { refreshComponent } = useRefreshComponent()
  const measurements = useMemo(() => {
    return _.filter(
      schema?.assetModelProperties,
      (property) => !!property.type?.measurement
    )
  }, [hashObject(schema?.assetModelProperties)])

  const handleOnFieldChange = () => {
    onChange(schema)
    refreshComponent()
  }

  const handleOnDelete = (measurement: AssetModelPropertySchema) => {
    const index = _.findIndex(
      schema.assetModelProperties,
      (a) => a === measurement
    )
    if (
      index >= 0 &&
      schema.assetModelProperties &&
      schema.assetModelProperties.length > index
    ) {
      schema.assetModelProperties.splice(index, 1)
      onChange(schema)
    }
  }

  if (!schema) {
    return null
  }

  return (
    <>
      {enableEdit && (
        <AddAWSAssetModelMeasurement
          schema={schema}
          onChange={handleOnFieldChange}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell>Type</TableCell>
            {enableEdit && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(measurements, (measurement, index) => (
            <TableRow key={measurement.id ?? index}>
              <TableCell>{measurement.name}</TableCell>
              <TableCell>{measurement.unit}</TableCell>
              <TableCell>{measurement.dataType}</TableCell>
              {enableEdit && (
                <TableCell>
                  <Button onClick={() => handleOnDelete(measurement)}>
                    delete
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
