import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { memoize } from 'lodash'
import { AppRootState } from '../../../store/AppStore'
import { AssetModelsSelectors } from '../store/store-config'

export const getAssetModelSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return AssetModelsSelectors.selectById(state, id)
    }),
)

export const AssetModelSelector = createSelector(
  getAssetModelSelector,
  (getAssetModel) =>
    memoize((id: string) => {
      return getAssetModel(id)
    }),
)

export const getAssetModelParentsSelector = createSelector(
  AssetModelsSelectors.selectAll,
  (allModels) => {
    return memoize((id: string) => {
      return _.filter(allModels, model => {
        const chidrenIds = _.map(model?.hierarchies, child => child.childAssetModelId)
        return _.includes(chidrenIds, id)
      })
    })
  }
)

export const AssetModelParentsSelector = createSelector(
  getAssetModelParentsSelector,
  (state: AppRootState, id: string) => id,
  (getAssetModelParents, id) => {
    return getAssetModelParents(id)
  }
)
