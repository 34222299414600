import React, { useCallback, useEffect } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { AssetSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { AssetForm } from './forms/AssetForm'
import { useNavigate } from 'react-router-dom'
import { useUpdateAssetQuery } from '../hooks/useUpdateAssetQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetsSelectors } from '../store/store-config'

interface EditAssetDrawerProps {
  open: boolean
  onClose: (schema?: AssetSchema) => void
  defaultData?: Partial<AssetSchema>
}

export const EditAssetDrawer: React.FC<EditAssetDrawerProps> = ({
  open,
  onClose,
  defaultData
}) => {
  const { updateAsset, processing, errorMessage } = useUpdateAssetQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetSchema>()

  const { entityId } = useEntityParams()

  const currentSchema = useAppSelector((state) =>
    AssetsSelectors.selectById(state, entityId)
  )

  useEffect(() => {
    if (open && currentSchema?.id && currentSchema instanceof AssetSchema) {
      setSchema(currentSchema.clone())
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateAsset(schema)
        .then((result) => {
          setSchema(new AssetSchema(defaultData))
          addSuccessMessage('AssetMode created.')
          onClose()
          navigate(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to update the Asset. ' + err?.response?.data?.errorMessage
          )
          schema.setErrors(err?.response?.data?.errors)
          refreshComponent()
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate(entityId)
  }, [entityId])

  const handleOnReset = useCallback(() => {
    setSchema(currentSchema)
  }, [currentSchema])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Edit Asset
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <ContentContainer>
            <AssetForm schema={schema} onChange={handleOnSchemaChanged} />
          </ContentContainer>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
