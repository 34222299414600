import { Exclude, Expose, Type } from 'class-transformer';
import {
  IsNotEmpty,
  MaxLength,
  IsBoolean,
  IsAlphanumeric,
  IsOptional,
  IsNumber,
  Max,
  Min,
  IsEnum,
  ValidateIf,
} from 'class-validator';
import _ from 'lodash';
import { BaseSchema } from '../BaseSchema';
import { AssetClassPropertyType, AssetMetricAggregationPeriod, AssetMetricAggregationStrategy, AssetPropertyDataType } from './types';

@Exclude()
export class AssetClassPropertySchema extends BaseSchema {
  @Expose()
  id: string;

  @Expose()
  @IsNotEmpty()
  @IsEnum(AssetClassPropertyType)
  type: AssetClassPropertyType

  @Expose()
  @IsOptional()
  assetClassId: string;

  @Expose()
  @IsNotEmpty()
  @MaxLength(36)
  @IsAlphanumeric()
  code: string;

  @Expose()
  @IsNotEmpty()
  @MaxLength(255)
  name: string;

  @Expose()
  @IsNotEmpty()
  @MaxLength(36)
  @IsEnum(AssetPropertyDataType)
  dataType: AssetPropertyDataType;

  @Expose()
  @IsOptional()
  @MaxLength(36)
  unit: string;

  @Type(() => Number)
  @Expose()
  @IsNumber()
  @Min(0)
  @Max(6)
  @IsOptional()
  precision: number;

  @Expose()
  @ValidateIf(obj => obj.type === AssetClassPropertyType.Metric)
  @IsNotEmpty()
  @IsEnum(AssetMetricAggregationStrategy)
  aggregationStrategy: AssetMetricAggregationStrategy;

  @Expose()
  @ValidateIf(obj => obj.type === AssetClassPropertyType.Metric)
  @IsNotEmpty()
  @IsEnum(AssetMetricAggregationPeriod)
  aggregationPeriod: AssetMetricAggregationPeriod;

  @Expose()
  @ValidateIf(obj => obj.type === AssetClassPropertyType.Transform)
  @IsNotEmpty()
  @MaxLength(512)
  formula: string;

  @Type(() => Boolean)
  @Expose()
  @IsBoolean()
  isEnabled: boolean = true;

  constructor(data?: Partial<AssetClassPropertySchema>) {
    super()

    if (data) {
      _.assign(this, data)
    }
  }
}
