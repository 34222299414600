import { EventTypeSchema, EventStatus } from '@loadsys-cmms/app-sdk'
import {
  AppToolbar,
  MeasuredContainer,
  useRefreshComponent
} from '@loadsys-cmms/ui-components'
import { Box, Button, Paper } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EventTypesStore } from '../../features/event-types/store/store-config'
import { useEntityParams } from '../../hooks/useEntityParams'
import { useAppSelector } from '../../store/useAppSelector'
import { EventTypesDataGrid } from '../../features/event-types/components/EventTypesDataGrid'
import { EventsDataGrid } from '../../features/events/components/EventsDataGrid'
import { EventsProvider } from '../../features/events/components/EventsProvider'

export const EventsPage: React.FC = () => {
  const { entityId, addAction, viewAction, editAction, action } =
    useEntityParams()
  const [openAdd, setOpenAdd] = useState(addAction)
  const [openView, setOpenView] = useState(viewAction)
  const [openEdit, setOpenEdit] = useState(editAction)
  const [height, setHeight] = useState(null)
  const [eventsContext, setEventsContext] = useState<any>()

  const eventTypes: EventTypeSchema[] = useAppSelector(
    EventTypesStore.selectors.selectAll
  )

  useEffect(() => {
    setOpenAdd(addAction)
  }, [addAction])

  useEffect(() => {
    setOpenView(viewAction)
  }, [viewAction])

  useEffect(() => {
    setOpenEdit(editAction)
  }, [editAction])

  const heightCalc = useMemo(() => {
    if (!height) {
      return null
    }
    return `calc(100vh - ${height + 145}px)`
  }, [height])

  const handleOnClick = useCallback(() => {
    eventsContext?.reload()
  }, [eventsContext])

  return (
    <React.Fragment>
      <MeasuredContainer onMeasured={setHeight}>
        <AppToolbar>
          <Box flex={1} />
          <Button onClick={handleOnClick}>Refresh</Button>
        </AppToolbar>
      </MeasuredContainer>
      <Box height={heightCalc}>
        <Paper sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
          <EventsProvider onInit={setEventsContext}>
            <EventsDataGrid />
          </EventsProvider>
        </Paper>
      </Box>
    </React.Fragment>
  )
  //return
}
