import React, { useCallback } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { AssetModelSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  AppTabPanel,
  AppTabProps,
  AppTabs,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { AssetModelForm } from './forms/AssetModelForm'
import { useNavigate } from 'react-router-dom'
import { useAddAssetModelQuery } from '../hooks/useAddAssetModelQuery'
import { classToPlain } from 'class-transformer'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetModelHiearchies } from './AssetModelChildren'

interface AddAssetModelDrawerProps {
  open: boolean
  onClose: (schema?: AssetModelSchema) => void
  defaultData?: Partial<AssetModelSchema>
}

export const AddAssetModelDrawer: React.FC<AddAssetModelDrawerProps> = ({
  open,
  onClose,
  defaultData
}) => {
  const { addAssetModel, processing } = useAddAssetModelQuery()
  const navigate = useNavigate()
  const theme = useTheme()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetModelSchema>(
    new AssetModelSchema()
  )

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addAssetModel(schema)
        .then((result) => {
          setSchema(new AssetModelSchema(defaultData))
          addSuccessMessage('Asset Model created.')
          onClose()
          navigate(result.id)
        })
        .catch((err) => {
          addErrorMessage(
            'Failed to create the Asset Model. ' +
              err?.response?.data?.errorMessage
          )
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate('/asset-management/models')
    onClose()
  }, [])

  const handleOnReset = useCallback(() => {
    setSchema(new AssetModelSchema(defaultData))
  }, [defaultData])

  return (
    <AppDrawer
      minWidth={400}
      width={'60vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Add Model
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <form autoComplete='off'>
            <ContentContainer>
              <AssetModelForm
                schema={schema}
                onChange={handleOnSchemaChanged}
              />
            </ContentContainer>
            <ContentContainer>
              <AssetModelHiearchies
                schema={schema}
                onChange={handleOnSchemaChanged}
                enableEdit={true}
              />
            </ContentContainer>
          </form>
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
