import { AssetModelSchema } from '@loadsys-cmms/app-sdk'
import {
  AutocompleteInput,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import { AssetModelsSelectors } from '../../store/store-config'

export interface AssetModelPickerProps {
  value?: string
  className?: any
  label?: string
  helperText?: string
  onChange: (value: string) => void
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  enableCreate?: boolean
  error?: boolean
  onBlur?: (text: string) => void
  assetClassId?: string
}

export const AssetModelPicker: React.FC<AssetModelPickerProps> = ({
  className,
  value,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  noOptionsText,
  clearOnBlur,
  enableCreate = true,
  error,
  onBlur,
  assetClassId
}) => {
  //const { checkPermissions } = useAppPermissions()

  const [openAdd, setOpenAdd] = useState(false)
  const [text, setText] = useState<string>('')

  const entities = useAppSelector(AssetModelsSelectors.selectAll)

  const filteredEntities = useMemo(() => {
    if (!_.isEmpty(assetClassId)) {
      return _.filter(
        entities,
        (entity) => entity.assetClassId === assetClassId
      )
    } else {
      return entities
    }
  }, [assetClassId])

  const options: InputOptionProps[] = useMemo(() => {
    return _.map(filteredEntities, (c) => c.getPickerInputOption())
  }, [filteredEntities, assetClassId])

  const handleOnCreate = (name?: string) => {
    setOpenAdd(true)
    setText(name)
  }

  const handleOnCloseAdd = (schema?: AssetModelSchema) => {
    if (schema) {
      onChange(schema.getIdentifier())
    }
    setOpenAdd(false)
  }

  return (
    <>
      <AutocompleteInput
        value={value}
        className={className}
        label={label}
        helperText={helperText}
        onChange={(value) => onChange(value as string)}
        disabled={disabled}
        required={required}
        variant={variant}
        placeholder={placeholder}
        freeSolo={freeSolo}
        options={options}
        noOptionsText={noOptionsText}
        clearOnBlur={clearOnBlur}
        onCreateNew={handleOnCreate}
        //enableCreate={checkPermissions([AppPermission.AssetPickerAdd]) && enableCreate}
        error={error}
        onBlur={onBlur}
      />
      {/*openAdd && <AssetPickerAddDrawer
      open={openAdd}
      onClose={handleOnCloseAdd}
      defaultData={{
        name: text,
      }}
    />*/}
    </>
  )
}
