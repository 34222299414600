import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'

export default function createFetchEntityThunk<S> (
  storeName: string,
  apiParams: { name: string, baseUrl: string },
  getResult?: (result: any) => any,
) {
  return createAsyncThunk(
    `${storeName}/fetchEntity`,
    async (entityId: string, params?: any) => {
      let entity: S = null
      try {
        entity = await API.get(apiParams.name, apiParams.baseUrl + '/' + entityId, params)
        entity = getResult(entity) ?? entity
      } catch (err) {
        console.error(`${storeName}/fetchEntity`, err)
      }
      return entity
    },
  )
}
