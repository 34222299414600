import { BaseSchema } from '@loadsys-cmms/app-sdk'
import { LocationPicker, LocationPickerProps } from './LocationPicker'

export interface SchemaLocationPickerProps
  extends Omit<LocationPickerProps, 'onChange'> {
  schema: BaseSchema
  field: string
  onChange?: (value: string, field: string) => void
}

export const SchemaLocationPicker: React.FC<SchemaLocationPickerProps> = ({
  schema,
  field,
  helperText,
  onChange,
  ...props
}) => {
  return (
    <LocationPicker
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={
        schema.isPropertyInvalid(field)
          ? schema.getPropertyError(field)
          : helperText
      }
      onChange={(value) => onChange(value, field)}
      {...props}
    />
  )
}
