import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetModelsSelectors } from '../store/store-config'

export interface AssetModelNameProps {
  assetModelId: string
  linkToModel?: boolean
}

export const AssetModelName: React.FC<AssetModelNameProps> = ({
  assetModelId,
  linkToModel
}) => {
  const AssetModel = useAppSelector((state) =>
    AssetModelsSelectors.selectById(state, assetModelId)
  )
  return (
    <>
      {linkToModel ? (
        <Button
          size={'small'}
          component={Link}
          to={'/asset-management/models/' + assetModelId}
        >
          {AssetModel?.name}
        </Button>
      ) : (
        <>{AssetModel?.name}</>
      )}
    </>
  )
}
