import { AssetPropertyValueSchema } from '@loadsys-cmms/app-sdk'
import { useSnackbarMessages } from '@loadsys-cmms/ui-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetAssetPropertyValues } from '../hooks/useGetAssetPropertyValuesQuery'

export interface AssetPropertyValuesContextProps {
  loading: boolean
  values: AssetPropertyValueSchema[]
}

export const AssetPropertyValuesContext =
  React.createContext<AssetPropertyValuesContextProps>({
    loading: false,
    values: []
  })

export interface AssetProvertyValuesProviderProps {
  assetId: string
  onInit?: (params: { reload: () => void }) => void
}

export const AssetPropertyValuesProvider: React.FC<
  AssetProvertyValuesProviderProps
> = ({ assetId, children, onInit = () => {} }) => {
  const { addErrorMessage } = useSnackbarMessages()
  const { getAssetPropertyValues, processing: loading } =
    useGetAssetPropertyValues()

  const [values, setValues] = useState<AssetPropertyValueSchema[]>([])

  const loadValues = useCallback(() => {
    getAssetPropertyValues(assetId)
      .then((response) => {
        setValues(response)
      })
      .catch(() => {
        addErrorMessage('Failed to load the Events.')
      })
  }, [assetId])

  useEffect(() => {
    loadValues()
    onInit({ reload: loadValues })
  }, [loadValues])

  const contextValue = useMemo(() => {
    return {
      loading,
      values
    }
  }, [values, loading])

  return (
    <AssetPropertyValuesContext.Provider value={contextValue}>
      {children}
    </AssetPropertyValuesContext.Provider>
  )
}
