import { BaseSchema } from "../../BaseSchema";
import { Expose, Transform, Type } from "class-transformer";
import { IsDate, isDate, IsNotEmpty, ValidateIf, ValidateNested } from "class-validator";
import { Assets } from "../types";
import _ from "lodash";
import { AssetPropertyValueVariantSchema } from "./AssetPropertyValueVariantSchema";

export class AWSAssetPropertyValueSchema extends BaseSchema implements Assets.AssetPropertyValue {
  @Type(() => AssetPropertyValueVariantSchema)
  @ValidateNested()
  @Expose()
  @IsNotEmpty()
  value: AssetPropertyValueVariantSchema;

  @Expose()
  @IsNotEmpty()
  @ValidateIf(obj => !obj.date)
  @Transform(({ value, obj }) => isDate(obj?.date) ? { timeInSeconds: obj.date.getTime() / 1000 } : value, { toPlainOnly: true })
  timestamp: Assets.TimeInNanos;

  @Transform(({ obj }) => obj.timestamp?.timeInSeconds ? new Date(obj.timestamp?.timeInSeconds) : undefined, { toClassOnly: true })
  @IsNotEmpty()
  @ValidateIf(obj => !obj.timestamp)
  @IsDate()
  date?: Date
}