import {
  AssetClassEventTriggerSchema,
  AssetPropertyDataType
} from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { Box, Button, Typography } from '@mui/material'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { ContentContainer } from '../../../components/content/ContentContainer'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'
import { AssetClassEventTriggerForm } from './forms/AssetClassEventTriggerForm'
import hash_object from 'hash-object'
import { useAddAssetClassEventTriggerQuery } from '../hooks/useAddAssetClassEventTriggerQuery'
import { AssetClassesActions } from '../../asset-classes/store/store-config'

const initializeSchema = (data?: Partial<AssetClassEventTriggerSchema>) => {
  return new AssetClassEventTriggerSchema(data)
}

interface AddAssetClassEventTriggerDrawerProps {
  assetClass: AssetClassModel
  onChange: (assetClass: AssetClassModel) => void
  open: boolean
  onClose: () => void
}

export const AddAssetClassEventTriggerDrawer: React.FC<
  AddAssetClassEventTriggerDrawerProps
> = ({ assetClass, onChange = () => {}, open, onClose }) => {
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { addAssetClassEventTrigger, processing } =
    useAddAssetClassEventTriggerQuery()
  const { refreshComponent } = useRefreshComponent()
  const [eventTrigger, setEventTrigger] =
    useState<AssetClassEventTriggerSchema>(
      initializeSchema({
        assetClassId: assetClass.id
      })
    )

  const numericPropertyOptions = useMemo(() => {
    return assetClass?.getMetricInputOptions([AssetPropertyDataType.Number])
  }, [hash_object(assetClass?.properties)])

  const handleOnFieldChange = () => {
    refreshComponent()
  }

  const handleOnAdd = () => {
    if (!assetClass.isEventCodeUnique(eventTrigger)) {
      eventTrigger.setErrors({
        code: 'The code must be unique within the class.'
      })
      refreshComponent()
    } else if (!assetClass.isEventNameUnique(eventTrigger)) {
      eventTrigger.setErrors({
        name: 'The name must be unique within the class.'
      })
      refreshComponent()
    } else if (eventTrigger.validate()) {
      if (!_.isArray(assetClass.eventTriggers)) {
        assetClass.eventTriggers = []
      }
      addAssetClassEventTrigger(eventTrigger)
        .then((eventTrigger) => {
          assetClass.eventTriggers.push(eventTrigger)
          setEventTrigger(
            initializeSchema({
              assetClassId: assetClass.id
            })
          )
          AssetClassesActions.updateOne(assetClass)
          onChange(assetClass)
          addSuccessMessage('The Event Trigger added successfully.')
        })
        .catch((err) => {
          addErrorMessage('Failed to create the Event Trigger.')
        })

      refreshComponent()
    } else {
      refreshComponent()
    }
  }

  if (!assetClass || !eventTrigger) {
    return null
  }

  return (
    <>
      <AppDrawer
        minWidth={400}
        width={'40vw'}
        open={open}
        onClose={() => onClose()}
      >
        <AppDrawerToolbar>
          <Typography
            style={{ flex: 1 }}
            variant={'body1'}
            fontWeight={'bold'}
            color={'inherit'}
          >
            {assetClass?.name} - Add Trigger
          </Typography>
        </AppDrawerToolbar>
        <AppDrawerContent>
          <ContentContainer>
            <AssetClassEventTriggerForm
              schema={eventTrigger}
              onChange={handleOnFieldChange}
              numericPropertyOptions={numericPropertyOptions}
            />
            <Box display={'flex'}>
              <Box flex={1}></Box>
              <Button onClick={handleOnAdd} disabled={processing}>
                Add
              </Button>
            </Box>
          </ContentContainer>
        </AppDrawerContent>
      </AppDrawer>
    </>
  )
}
