import { AssetClassEventTriggerSchema } from '@loadsys-cmms/app-sdk'
import { Button } from '@mui/material'
import { useState } from 'react'
import { AssetClassModel } from '../../asset-classes/models/AssetClassModel'
import { AddAssetClassEventTriggerDrawer } from './AddAssetClassEventTriggerDrawer'

export interface AddAssetClassEventTriggerButtonProps {
  schema: AssetClassModel
  onChange: (schema: AssetClassModel) => void
}

export const AddAssetClassEventTriggerButton: React.FC<
  AddAssetClassEventTriggerButtonProps
> = ({ schema, onChange }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add</Button>
      <AddAssetClassEventTriggerDrawer
        open={open}
        assetClass={schema}
        onClose={() => setOpen(false)}
        onChange={onChange}
      />
    </>
  )
}
