import { BrowserRouter } from 'react-router-dom'
import { useAuth } from '@loadsys-cmms/ui-components'
import { AppRoutes } from './AppRoutes'
import { AuthRoutes } from './AuthRoutes'
import { AmplifyPubsubProvider } from '../../features/pubsub/components/AmplifyPubsubProvider'
import { AmplifyPubsubUpdatesListener } from '../../features/pubsub/components/AmplifyPubsubUpdatesListener'

export const AppRouter: React.FC = () => {
  const auth = useAuth()
  return (
    <BrowserRouter>
      {auth.authenticated ? (
        <AmplifyPubsubProvider>
          <AmplifyPubsubUpdatesListener>
            <AppRoutes />
          </AmplifyPubsubUpdatesListener>
        </AmplifyPubsubProvider>
      ) : (
        <AuthRoutes />
      )}
    </BrowserRouter>
  )
}
