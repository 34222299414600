import { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { BaseSchema } from '@loadsys-cmms/app-sdk'

export default function createUpdateOneReducer<T> (
  state: EntityState<T>,
  payload: PayloadAction<T>,
  selectFn: (entity: T) => string
) {
  const entity = payload.payload
  if (!entity || !(entity instanceof BaseSchema)) {
    return
  }

  const key = selectFn(entity)

  if (!(key in state.entities)) {
    state.ids.push(key)
  }

  state.entities[key] = entity
}
