import { AppTabProps } from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { AppTabsContainer } from '../../components/AppTabsContainer'
import { AssetClassesThunks } from '../../features/asset-classes/store/store-config'
import { AssetFailureCausesThunks } from '../../features/asset-failure-causes/store/store-config'
import { AssetFailureModesThunks } from '../../features/asset-failure-modes/store/store-config'
import { AssetModelsThunks } from '../../features/asset-models/store/store-config'
import { useAppDispatch } from '../../store/useAppDispatch'

const routePrefix = '/asset-management'

const defaultPath = 'assets'

const tabs: AppTabProps[] = [
  { value: 'assets', route: `${routePrefix}/assets`, label: 'Assets' },
  { value: 'models', route: `${routePrefix}/models`, label: 'Models' },
  { value: 'classes', route: `${routePrefix}/classes`, label: 'Classes' },
  {
    value: 'failureModes',
    route: `${routePrefix}/failure-modes`,
    label: 'Failure Modes'
  },
  {
    value: 'failureCauses',
    route: `${routePrefix}/failure-causes`,
    label: 'Failure Causes'
  },
  {
    value: 'maintenanceActions',
    route: `${routePrefix}/maintenance-actions`,
    label: 'Maintenance Actions'
  }
  // { value: 'items', route: `${routePrefix}/items`, label: 'Shipment Items' },
]

interface AssetManagementProps {}

export const AssetManagement: React.FC<AssetManagementProps> = ({
  children
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const selected = useMemo(() => {
    if (!location.pathname) {
      return defaultPath
    }
    const tab = _.find(tabs, (tab) => !!location.pathname.match(tab.route))
    return tab?.value
  }, [location?.pathname])

  /*
  useEffect(() => {
    dispatch(AssetModelsThunks.fetchEntities({}))
    dispatch(AssetClassesThunks.fetchEntities({}))
    dispatch(
      AssetFailureModesThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
    dispatch(
      AssetFailureCausesThunks.fetchEntities({
        queryStringParameters: {
          limit: 1000
        }
      })
    )
  }, []) */

  return (
    <AppTabsContainer tabs={tabs} selected={selected}>
      {children}
      <Outlet />
    </AppTabsContainer>
  )
}
