import {
  AutocompleteInput,
  AutocompleteInputProps,
  InputOptionProps
} from '@loadsys-cmms/ui-components'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useAppSelector } from '../../../../store/useAppSelector'
import { MaintenanceActionsSelectors } from '../../store/store-config'

export interface MaintenanceActionPickerProps
  extends Omit<AutocompleteInputProps, 'options'> {}

export const MaintenanceActionPicker: React.FC<
  MaintenanceActionPickerProps
> = ({
  label = 'Class',
  placeholder = 'Class',
  onChange = () => {},
  ...props
}) => {
  const entities = useAppSelector(MaintenanceActionsSelectors.selectAll)

  const filteredEntities = useMemo(() => {
    return _.map(entities)
  }, [entities])

  const options: InputOptionProps[] = useMemo(() => {
    return _.map(filteredEntities, (c) => c.getPickerInputOption())
  }, [filteredEntities])

  return (
    <>
      <AutocompleteInput
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        {...props}
      />
    </>
  )
}
