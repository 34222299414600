import React, { useCallback, useEffect } from 'react'
import { Button, Container, Paper, Typography } from '@mui/material'
import { AssetModelType, AWSAssetModelSchema } from '@loadsys-cmms/app-sdk'
import {
  AppDrawer,
  AppDrawerActions,
  AppDrawerContent,
  AppDrawerToolbar,
  useRefreshComponent,
  useSnackbarMessages
} from '@loadsys-cmms/ui-components'
import { useNavigate } from 'react-router-dom'
import { useUpdateAssetClassQuery } from '../hooks/useUpdateAssetClassQuery'
import { classToPlain } from 'class-transformer'
import { useEntityParams } from '../../../hooks/useEntityParams'
import { useAppSelector } from '../../../store/useAppSelector'
import { AssetClassesSelectors } from '../store/store-config'
import { AssetClassModel } from '../models/AssetClassModel'
import { EditAssetClass } from './EditAssetClass'

interface EditAssetClassDrawerProps {
  open: boolean
  onClose: (schema?: AssetClassModel) => void
  defaultData?: Partial<AssetClassModel>
}

export const EditAssetClassDrawer: React.FC<EditAssetClassDrawerProps> = ({
  open,
  onClose,
  defaultData
}) => {
  const { updateAssetClass, processing } = useUpdateAssetClassQuery()
  const navigate = useNavigate()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { refreshComponent } = useRefreshComponent()
  const [schema, setSchema] = React.useState<AssetClassModel>()

  const { entityId } = useEntityParams()

  const currentSchema = useAppSelector((state) =>
    AssetClassesSelectors.selectById(state, entityId)
  )

  useEffect(() => {
    if (open && currentSchema?.id && currentSchema instanceof AssetClassModel) {
      const schema = currentSchema.clone()
      if (!schema.modelSchema) {
        schema.modelSchema = new AWSAssetModelSchema()
      }
      setSchema(schema)
    }
  }, [open])

  const handleOnSchemaChanged = useCallback(() => {
    refreshComponent()
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateAssetClass(schema)
        .then((result) => {
          addSuccessMessage('Asset Class updated.')
          onClose()
          navigate(result.id)
        })
        .catch(() => {
          addErrorMessage('Failed to create the Asset Class.')
        })
    } else {
      addErrorMessage(
        'Validation failed. Please correct the errors and try again.'
      )
      refreshComponent()
    }
  }, [schema, onClose])

  const handleOnClose = useCallback(() => {
    navigate(entityId || '/asset-management/classes')
    onClose()
  }, [entityId])

  const handleOnReset = useCallback(() => {
    const schema = currentSchema.clone()
    if (!schema.modelSchema) {
      schema.modelSchema = new AWSAssetModelSchema()
    }
    setSchema(schema)
  }, [defaultData])

  return (
    <AppDrawer
      minWidth={400}
      width={'60vw'}
      open={open}
      onClose={handleOnClose}
    >
      <AppDrawerToolbar>
        <Typography
          style={{ flex: 1 }}
          variant={'body1'}
          fontWeight={'bold'}
          color={'inherit'}
        >
          Edit Asset Class
        </Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        {schema && (
          <EditAssetClass schema={schema} onChange={handleOnSchemaChanged} />
        )}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>
          Save
        </Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
